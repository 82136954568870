import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import AuthTokenConfirm from "../pages/AuthTokenConfirm";
import ConfirmedSubscription from "../pages/ConfirmedSubscription";
import EditProfile from "../pages/EditProfile";
import EditProfile2 from "../pages/EditProfile2";
import EditProfile3 from "../pages/EditProfile3";
import GoogleSigninPage from "../pages/GoogleSignin";

import Home from "../pages/Home";
import Link from "../pages/Link";
import Onboarding from "../pages/Onboarding";
import Pay from "../pages/Pay";
import Payments from "../pages/Payments";
import Profile from "../pages/Profile";
import Profile2 from "../pages/profile2";
import Profile3 from "../pages/Profile3";
import SchedulePage from "../pages/Schedule";
import SchedulePage2 from "../pages/Schedule2";
import ScheduleDetailsPage from "../pages/ScheduleDetails";
import SubscriptionPage from "../pages/SubscriptionPage";
import { TwitterVerifiedPage } from "../pages/TwitterVerified";
import AuthenticatedRoute from "./AuthenticatedRoute";

export default function MainRoutes({ isLoggingIn }) {
  const location = useLocation();
  return (
    <Routes>
      <Route
        path={"/e"}
        element={
          <AuthenticatedRoute>
            <EditProfile3 />
          </AuthenticatedRoute>
        }
      />
      <Route path={"/g"} element={<AuthTokenConfirm />} />
      <Route path={"/tw"} element={<TwitterVerifiedPage />} />
      <Route path={"/bm"} element={<SubscriptionPage />} />
      <Route path={"/c"} element={<ConfirmedSubscription />} />
      {/* <Route path={"/s/events"} element={<ScheduleListPage />} /> */}
      <Route path={"/s/details"} element={<ScheduleDetailsPage />} />
      <Route path={"/s"} element={<SchedulePage2 />} />
      <Route path={"/p/send"} element={<Pay />} />
      <Route path={"/p/payments"} element={<Payments />} />
      <Route path={"/p/googlesignin/:route"} element={<GoogleSigninPage />} />
      <Route path={"/p/link-test"} element={<Link />} /> 
      <Route 
        path={"/p/onboarding"} 
        element={
            <Onboarding />
          } 
        />
      <Route path={"/p2/:username"} element={<Profile2 />} />
      {location.pathname.length > 2 ? ( // min username length + 1
        <Route path={"/:username"} element={<Profile3 />} />
      ) : (
        <Route path={"/"} element={<Home isLoggingIn={isLoggingIn} />} />
      )}
    </Routes>
  );
}
