import React, { useState } from "react";
import { Center } from "./Center";
import AvatarImageCropper from "react-avatar-image-cropper";
import { FlatButton } from "./FlatButton";
import { Col, Modal } from "react-bootstrap";
import { SizedBox } from "./SizedBox";
import { DynamicImage } from "./DynamicImage";
import styled from "styled-components";
import { AiFillPlusCircle } from "react-icons/ai";
import config from "../utils/config";
import { useAppColors } from "../utils/useAppColors";
import { StandardShadow } from "./StandardShadow";

const PlusIconWrapper = styled.div`
  color: ${(props) => props.color};
  position: relative;
  bottom: 60px;
  right: ${props => props.width ? `${props.width - 60}[x]` : "-180px"};
  background-color: white;
  width: 40px;
  height: 40px;
  border-radius: 5000px;
`;

export const UploadImageWidget = ({ selectedImage, setImage, width }) => {
  const [show, setShow] = useState(false);
  const colors = useAppColors();
  const handleClose = () => setShow(false);

  return (
    <div className="d-flex flex-column align-items-center">
      {/* {!selectedImage && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            width: "250px",
            height: "250px",
            backgroundColor: primaryColorLight,
            borderRadius: "32px",
          }}
        >
          <input
            onChange={(event) => {
              setImage(event.target?.files ? event.target?.files[0] : null);
            }}
            type="file"
            accept="image/png, image/jpeg"
            style={{ opacity: "0.0", width: "100%", height: "100%" }}
          />
          <div style={{ position: "absolute" }}>
            <Center>
              <h1 style={{ color: primaryColor, fontWeight: "bold" }}>+</h1>
            </Center>
          </div>
        </div>
      )} */}
      {
        <div>
          <div
            onClick={() => {
              setShow(true);
            }}
            style={{ cursor: "pointer" }}
          >
            <StandardShadow borderRadius={"5000px"}>
              {selectedImage && (
                <DynamicImage
                  imageUrl={
                    typeof selectedImage === "string" &&
                    selectedImage.includes("https://")
                      ? selectedImage
                      : URL.createObjectURL(selectedImage)
                  }
                  borderRadius={"5000px"}
                  height={width ? width : 240}
                  width={width ? width : 240}
                />
              )}
              {!selectedImage && (
                <DynamicImage
                  imageUrl={config.defaultAvatarUrl}
                  borderRadius={"5000px"}
                  height={ width ? width : 240}
                  width={width ? width : 240}
                />
              )}
            </StandardShadow>

            <PlusIconWrapper width={width} color={colors.primaryColor}>
              <AiFillPlusCircle size={"40px"} />
            </PlusIconWrapper>
          </div>

          <Modal
            show={show}
            onHide={() => {
              setShow(false);
            }}
          >
            <Modal.Body>
              <Col className="d-flex flex-column justify-content-center align-items-center">
                <SizedBox height={32} />
                <SizedBox height={16} />
                <div
                  style={{
                    width: "250px",
                    height: "250px",
                    margin: "auto",
                    border: "1px solid black",
                  }}
                >
                  <AvatarImageCropper
                    apply={(f) => {
                      setImage(f);
                      handleClose();
                    }}
                  />
                </div>
                <FlatButton>
                  <div>Save</div>
                </FlatButton>
                <SizedBox height={16} />
              </Col>
            </Modal.Body>
            <Modal.Footer>
              <FlatButton onClick={handleClose}>Close</FlatButton>
            </Modal.Footer>
          </Modal>
          {/* <AvatarEditor
            image={
              typeof selectedImage === "string" &&
              selectedImage.includes("https://")
                ? selectedImage
                : URL.createObjectURL(selectedImage)
            }
            width={250}
            height={250}
            border={0}
            color={[255, 255, 255, 0.6]} // RGBA
            scale={1}
            rotate={0}
            style={{ borderRadius: "32px" }}
          /> */}
          <FlatButton onClick={() => setImage(null)}>Remove Image</FlatButton>
        </div>
      }
    </div>
  );
};
