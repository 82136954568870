import { Col, Row } from "react-bootstrap";
import { AiFillCheckCircle } from "react-icons/ai";
import { BsCheckCircleFill, BsCircle } from "react-icons/bs";
import styled from "styled-components";
import { useAppColors } from "../utils/useAppColors";
import { Center } from "./Center";
import { StandardShadow } from "./StandardShadow";
import { SubtitleText } from "./SubtitleText";

const CheckboxWrapper = styled.div`
    cursor: pointer;
    box-shadow: 0.2rem 0.2rem black;
    background-color: white;
    border-radius: 5000px;
    height: 30px;
    width: 30px;
    padding: 2px;
    border: 2px solid black;
    display:flex;
    align-items:center;
    justify-content:center;
`

export default function CustomCheckbox({ value, setValue, name, fontSize }) {
    const colors = useAppColors()
    return <Row style={{cursor: "pointer"}} onClick={() => { setValue(!value) }}>
        <Col xs={1}>
            <Center>
                <CheckboxWrapper>
                    <Center>
                        {value ? <BsCheckCircleFill size={28} color={colors.primaryColor}  /> : <BsCircle size={28}  />}
                    </Center>
                </CheckboxWrapper>
            </Center>
        </Col>
        <Col className="d-flex align-items-center">
            <SubtitleText color={"black"} fontSize={fontSize ? fontSize : "18px"} >
                {name}
            </SubtitleText>
        </Col>
    </Row>

}