import { useAppColors } from "../utils/useAppColors";
import { DynamicImage } from "../components/DynamicImage";
import { WidthContainer } from "../components/WidthContainer";
import { Col, Row } from "react-bootstrap";
import {useState, useEffect} from "react";
import config from "../utils/config";
import moment from "moment";
import EventCard from "./EventCard.js"
import React from "react";

export default function TodaySnapshot({events, t, jwt, uid, noEvents}) {
    const colors= useAppColors();
    let eventList = [];
    if(noEvents === true)
    {
        eventList[0] = "no events are scheduled today"
    }
    else
    {
    console.log(events);
    events.map(ev => {
        eventList.push(<EventCard
        id={ev._id}
        start={ev.start}
        userIds={ev.userIds}
        jwt={jwt}
        uid={uid}
         />)
        }
    )
    }
    let EventElems = eventList.map((d, i) => {
        return <tr key={i * 131}><td><div style={{margin:"10px"}}>{d}</div></td></tr>;
      });
return(
    <div>
    <Col>
    <Row>
    <Col style={{display: "flex",
    alignItems: "center"}}>
    <Col>
    <span style={{color: colors.primaryColor}}>{moment(t).format("MMMM D, YYYY")}</span>
        <h3>Today's Events</h3>
        </Col>
    </Col>
    <Col><h1><span style={{color: "#f6e8ff", fontWeight: "bold", fontSize: "2em"}}>{moment(t).format("D")}</span></h1></Col>
    </Row>
    <Col>
    <table>
    <tbody>
            {EventElems}
            </tbody>
            </table>
    </Col>
    </Col>

    </div>
)
}