import { useContext, useEffect, useState } from "react";
import { Col, Modal, Dropdown, DropdownButton, Row } from "react-bootstrap";
import styled from "styled-components";
import { Web3Context } from "../contexts/Web3Context";
import config from "../utils/config";
import ethereumUtils from "../utils/ethereum";
import { Center } from "./Center";
import { CustomTextField } from "./CustomTextInput";
import { CustomInputWithSideSelector } from "./CustomInputWithSideSelector";
import InfoCard from "./InfoCard";
import { PrimaryButton } from "./PrimaryButton";
import { SecondaryButton } from "./SecondaryButton";
import SecondaryDropdownButton from "./SecondaryDropdownButton";
import { SizedBox } from "./SizedBox";
import { StandardShadow } from "./StandardShadow";
import { SubtitleText } from "./SubtitleText";
import { TitleText } from "./TitleText";
import uniswap from "@uniswap/default-token-list";
import ERC20ListItem from "./ERC20ListItem";
import utils from "../utils/utils";
import nftList from "../utils/defaultNFTS.json";
import { DescriptionText } from "./DescriptionText";
import BondView from "./BondView";
import { useAppColors } from "../utils/useAppColors";
import { FlatButton } from "./FlatButton";
import { BigNumber } from "ethers";
import { parseUnits } from "ethers/lib/utils";
import specialSymbols from "../utils/specialSymbols";
import { TokenSelectModal } from "./TokenSelectModal";

const testAddy = "0xbb997742326E8Da7bfe63077f7F66aae07C9D676";
const testAddyLength = testAddy.length;

const BondsEditorWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
`;
const RulesPreviewWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: end;
  width: 100%;
  font-weight: bold;
`;

const BondEditWrapper = styled.div`
  width: 100%;
  display: flex;
`;

const RuleEditorWrapper = styled.div`
  width: 45%;
  margin-bottom: 16px;
`;

const AddBondButtonWrapper = styled.div`
  background-color: ${(props) => props.backgroundColor};
  border: 2px solid ${(props) => props.borderColor};
  color: ${(props) => props.color};
  border-radius: 5000px;
  font-size: 24px;
  font-weight: bold;
  width: 40px;
  height: 40px;
  cursor: pointer;
  background-color: white;
`;

const TokenListWrapper = styled.div`
  height: ${(props) => (props.height ? props.height : "50vh")};
  overflow-y: scroll;
  width: 100%;
`;

const NFTPreviewChipWrapper = styled.div`
border-radius: 18px;
padding: 12px;
  background-color ${(props) => props.textFieldColor};
  display:flex;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
`;

const NFTPreviewChips = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-height: 25vh;
  overflow-y: scroll;
  border-radius: 20px;
  border: 2px solid ${(props) => props.borderColor};
  padding: 12px;
`;

const exampleAddy = "0x420697b9d4Bb37A2eBEa7510501a8e4886711338";

const ethToken = {
  address: "0x0000000000000000000000000000000000000000",
  name: "Ether",
  symbol: "ETH",
  image:
    "https://d33wubrfki0l68.cloudfront.net/fcd4ecd90386aeb50a235ddc4f0063cfbb8a7b66/4295e/static/bfc04ac72981166c740b189463e1f74c/40129/eth-diamond-black-white.jpg",
  decimals: 18,
};

export default function BondsEditor({
  addBond,
  saveBond,
  revertBond,
  addRule,
  removeBond,
  removeRule,
  bonds,
  editingBond,
  setRule,
  nfts,
  ownedNftContractData,
  selectBond,
}) {
  const { provider } = useContext(Web3Context);
  const colors = useAppColors();
  return (
    <InfoCard padding={"24px"}>
      <BondsEditorWrapper>
        <Row className="d-flex justify-space-between">
          <Col>
            <TitleText textAlign={"start"}>Add Bonds</TitleText>
          </Col>

          <Col className="d-flex justify-content-end">
            <Row className="d-flex flex-row">
              <Col>
                {editingBond.bondIndex && (
                  <div>
                    <FlatButton onClick={revertBond}>Cancel</FlatButton>
                  </div>
                )}
              </Col>
              <Col>
                <div>
                  <StandardShadow borderRadius={"5000px"}>
                    <AddBondButtonWrapper
                      onClick={editingBond.bondIndex ? removeBond : addBond}
                      borderColor={colors.titleColor}
                      backgroundColor={colors.iconButtonColor}
                      color={"black"}
                    >
                      {editingBond.bondIndex ? "x" : "+"}
                    </AddBondButtonWrapper>
                  </StandardShadow>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        {!editingBond.bondIndex ? (
          <>
            <SizedBox height={16} />
            <SubtitleText
              textAlign={"start"}
              color={colors.textColor}
              fontSize={"20px"}
            >
              Add bonds to filter what people will be able to schedule meetings
              with you.
            </SubtitleText>
          </>
        ) : null}
        {editingBond.bondIndex && (
          <BondEditor
            key={"bond-" + editingBond.bondIndex}
            bond={editingBond}
            bondIndex={editingBond.bondIndex}
            setRule={setRule}
            saveBond={saveBond}
            addRule={addRule}
            addBond={addBond}
            removeBond={removeBond}
            removeRule={removeRule}
            nfts={nfts}
            ownedNftContractData={ownedNftContractData}
          />
        )}
      </BondsEditorWrapper>
      <SizedBox height={32} />
      <Row className="d-flex justify-space-between">
        <Col>
          <TitleText textAlign={"start"}>Existing Bonds</TitleText>
        </Col>
        {/* <Col className="d-flex justify-content-end">
          <div>
            <StandardShadow borderRadius={"5000px"}>
              <AddBondButtonWrapper onClick={addBond}>+</AddBondButtonWrapper>
            </StandardShadow>
          </div>
        </Col> */}
      </Row>
      {(!bonds || bonds.length === 0) && (
        <div>
          <SizedBox height={16} />
          <SubtitleText
            textAlign={"left"}
            color={colors.textColor}
            fontSize={"20px"}
          >
            No existing bonds yet...
          </SubtitleText>
        </div>
      )}
      {bonds.map((b, bondIndex) => (
        <div key={"bond-" + bondIndex + "hi"}>
          <>
            <SizedBox height={12} />
            {bondIndex === 0 ? (
              <></>
            ) : (
              <DescriptionText textAlign={"center"}>{`OR`}</DescriptionText>
            )}
          </>
          <SizedBox height={8} />

          <BondView
            key={bondIndex}
            bond={b.bond}
            match={b.match}
            bondIndex={bondIndex}
            provider={provider}
            selected={false}
            selectBond={editingBond.bondIndex === undefined ? selectBond : null}
          />
        </div>
      ))}
    </InfoCard>
  );
}

function BondEditor({
  bond,
  bondIndex,
  setRule,
  addRule,
  saveBond,
  removeBond,
  removeRule,
  nfts,
  ownedNftContractData,
}) {
  const { showSnackbar } = useContext(Web3Context);
  const [showAddRuleModal, setShowAddRuleModal] = useState(false);
  const [initialRule, setInitialRule] = useState({});

  async function selectExistingRule({ rule, ruleIndex }) {
    setInitialRule(rule);
    setShowAddRuleModal(rule);
    removeRule({ ruleIndex: ruleIndex });
  }

  return (
    <BondEditWrapper>
      <RulesPreviewWrapper>
        {[...bond.bond, { newRule: true }, { saveBond: true }].map(
          (rule, ruleIndex) => (
            <RuleEditorWrapper
              key={
                "bond-" +
                bondIndex +
                "rule-" +
                ruleIndex +
                "-" +
                (rule && rule.type ? rule.type : "type") +
                "-" +
                (rule && rule.tokenAddress
                  ? rule.tokenAddress
                  : "tokenAddress") +
                (rule && rule.quantity ? rule.quantity : "quantity")
              }
            >
              {ruleIndex < bond.bond.length ? (
                <RulePreview
                  type={rule.rule.type}
                  rule={rule.rule}
                  bondIndex={bondIndex}
                  ruleIndex={ruleIndex}
                  setRule={setRule}
                  removeRule={removeRule}
                  onClick={selectExistingRule}
                />
              ) : rule.newRule ? (
                <SecondaryButton
                  text="+ Add New Rule"
                  onClick={() => {
                    setInitialRule({});
                    setShowAddRuleModal(true);
                  }}
                />
              ) : (
                <PrimaryButton text="Save Bond" onClick={saveBond} />
              )}
            </RuleEditorWrapper>
          )
        )}
      </RulesPreviewWrapper>
      <AddRuleModal
        show={showAddRuleModal}
        setShow={setShowAddRuleModal}
        saveRule={({ rule }) => {
          addRule({ bondIndex: bondIndex, rule: rule });
        }}
        cancelRule={() => {
          if (initialRule && initialRule.type) {
            addRule({ bondIndex: bondIndex, rule: initialRule });
          }
          setShowAddRuleModal(false);
        }}
        initialRule={initialRule}
        nfts={nfts}
        ownedNftContractData={ownedNftContractData}
      />
    </BondEditWrapper>
  );
}



function RuleTypeInputs({
  type,
  setShow,
  closeRule,
  saveRule,
  cancelRule,
  nfts,
  ownedNftContractData,
  initialRule,
}) {
  if (!type) {
    return (
      <Row style={{ width: "100%" }}>
        <Col>
          <SecondaryButton
            onClick={() => {
              closeRule();
            }}
            text="Cancel"
          />
        </Col>
      </Row>
    );
  }
  switch (type) {
    case "nft-ownership":
      return (
        <NFTOwnershipRule
          type={type}
          closeRule={closeRule}
          saveRule={saveRule}
          cancelRule={cancelRule}
          nfts={nfts}
          initialRule={initialRule}
          ownedNftContractData={ownedNftContractData}
        />
      );
    case "erc20-ownership":
      return (
        <ERC20OwnershipRule
          type={type}
          closeRule={closeRule}
          saveRule={saveRule}
          cancelRule={cancelRule}
          initialRule={initialRule}
        />
      );
    default:
      return (
        <Row style={{ width: "100%" }}>
          <Col>
            <ETHOwnershipRule
              type={type}
              closeRule={closeRule}
              saveRule={saveRule}
              cancelRule={cancelRule}
              initialRule={initialRule}
            />
          </Col>
        </Row>
      );
  }
}

function AddRuleModal({
  show,
  setShow,
  saveRule,
  cancelRule,
  nfts,
  ownedNftContractData,
  initialRule,
}) {
  const [type, setType] = useState(undefined);
  useEffect(() => {
    if (initialRule && initialRule.type) {
      setType(initialRule.type);
    }
  }, [initialRule]);
  async function closeRule() {
    setShow(false);
    setType(undefined);
  }
  return (
    <Modal
      dialogClassName="modal-60w"
      show={show}
      onHide={() => {
        setShow(false);
      }}
    >
      <Modal.Body>
        <Center>
          <Row style={{ width: "80%" }}>
            <Col
              className="d-flex flex-column justify-content-center align-items-center"
              style={{ width: "100%" }}
            >
              <SizedBox height={32} />
              <TitleText textAlign={"center"}>Add a New Rule</TitleText>
              <SizedBox height={16} />
              <Row style={{ width: "100%" }}>
                <SecondaryDropdownButton
                  name={"Rule Type"}
                  text={type ? config.ruleTypes[type] : "Select Rule Type"}
                  options={config.ruleTypes}
                  onClick={({ type }) => {
                    setType(type);
                  }}
                />
              </Row>
              <SizedBox height={16} />
              <RuleTypeInputs
                type={type}
                closeRule={closeRule}
                saveRule={saveRule}
                cancelRule={cancelRule}
                nfts={nfts}
                initialRule={initialRule}
                ownedNftContractData={ownedNftContractData}
              />
              <SizedBox height={16} />
            </Col>
          </Row>
        </Center>
        <SizedBox height={32} />
      </Modal.Body>
    </Modal>
  );
}

function RulePreview({
  type,
  rule,
  removeRule,
  ruleIndex,
  bondIndex,
  onClick,
}) {
  switch (type) {
    case "nft-ownership":
      return (
        <NFTRulePreview
          text="Own"
          rule={rule}
          ruleIndex={ruleIndex}
          onClick={onClick}
        />
      );
    case "erc20-ownership":
      return (
        <ERC20RulePreview
          text="Own"
          rule={rule}
          ruleIndex={ruleIndex}
          onClick={onClick}
        />
      );
    case "eth-ownership":
      return (
        <ETHRulePreview
          text="Own"
          rule={rule}
          ruleIndex={ruleIndex}
          onClick={onClick}
        />
      );
    default:
      return <div></div>;
  }
}

function NFTRulePreview({ text, rule, ruleIndex, onClick }) {
  const { provider, showSnackbar } = useContext(Web3Context);
  const [tokenData, setTokenData] = useState(undefined);
  async function getInitialData() {
    if (!rule) {
      return;
    }
    if (rule.tokenAddress.length !== testAddyLength) {
      return;
    }
    const tokenDataResp = await ethereumUtils.getNFTContractData({
      nftAddress: rule.tokenAddress,
      provider: provider,
      needImage: true,
    });
    if (tokenDataResp.error) {
      showSnackbar({ message: tokenDataResp.error, severity: "error" });
      return;
    }
    const t = utils.getDefaultNFTTokenData({
      address: rule.tokenAddress,
    });
    if (t) {
      tokenDataResp.image = t.token.logoURI;
      tokenDataResp.logoURI = t.token.logoURI;
    }
    setTokenData(tokenDataResp);
    return;
  }

  useEffect(() => {
    getInitialData();
  }, [rule]);

  return (
    <div onClick={() => onClick({ rule: rule, ruleIndex: ruleIndex })}>
      <SubtitleText textAlign={"start"} color={"black"} fontSize={"18px"}>
        {"Rule " + (ruleIndex + 1) + ": " + config.ruleTypes[rule.type]}
      </SubtitleText>
      <SecondaryButton
        onClick={() => {
          // setShowAddRuleModal(true);
        }}
      >
        {tokenData && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>{rule.quantity}</div>

            <div style={{ display: "flex", alignItems: "center" }}>
              {tokenData && tokenData.image && (
                <img
                  src={utils.formatIPFStoGateway(tokenData.image)}
                  alt={"logo"}
                  style={{
                    borderRadius: "500px",
                    height: "30px",
                    width: "30px",
                  }}
                />
              )}
              <SizedBox width={8} />
              <div>
                {specialSymbols.data[rule.tokenAddress.toLowerCase()] &&
                specialSymbols.data[rule.tokenAddress.toLowerCase()].symbol
                  ? specialSymbols.data[rule.tokenAddress.toLowerCase()].symbol
                  : tokenData.symbol}
              </div>
            </div>
          </div>
        )}
      </SecondaryButton>
    </div>
  );
}

function ERC20RulePreview({ text, rule, ruleIndex, onClick }) {
  const { provider, showSnackbar } = useContext(Web3Context);
  const [tokenData, setTokenData] = useState(undefined);
  async function getInitialData() {
    if (!rule) {
      return;
    }
    if (rule.tokenAddress.length !== testAddyLength) {
      return;
    }
    const tokenDataResp = await ethereumUtils.getERC20ContractData({
      address: rule.tokenAddress,
      provider: provider,
    });
    if (tokenDataResp.error) {
      showSnackbar({ message: tokenDataResp.error, severity: "error" });
      return;
    }
    const t = utils.getUniswapTokenData({
      address: rule.tokenAddress,
    });
    if (t) {
      tokenDataResp.image = t.token.logoURI;
      tokenDataResp.logoURI = t.token.logoURI;
    }
    setTokenData(tokenDataResp);

    return;
  }
  useEffect(() => {
    getInitialData();
  }, [rule]);

  return (
    <div onClick={() => onClick({ rule: rule, ruleIndex: ruleIndex })}>
      <SubtitleText textAlign={"start"} color={"black"} fontSize={"18px"}>
        {"Rule " + (ruleIndex + 1) + ": " + config.ruleTypes[rule.type]}
      </SubtitleText>
      <SizedBox height={8} />
      <SecondaryButton
        onClick={() => {
          // setShowAddRuleModal(true);
        }}
      >
        {tokenData && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>{rule.quantity / 10 ** parseInt(tokenData.decimals)}</div>

            <div style={{ display: "flex", alignItems: "center" }}>
              {tokenData && tokenData.image && (
                <img
                  src={utils.formatIPFStoGateway(tokenData.image)}
                  alt={"logo"}
                  style={{
                    borderRadius: "500px",
                    height: "30px",
                    width: "30px",
                  }}
                />
              )}
              <SizedBox width={8} />
              <div>
                {specialSymbols.data[rule.tokenAddress.toLowerCase()] &&
                specialSymbols.data[rule.tokenAddress.toLowerCase()].symbol
                  ? specialSymbols.data[rule.tokenAddress.toLowerCase()].symbol
                  : tokenData.symbol}
              </div>
            </div>
          </div>
        )}
      </SecondaryButton>
    </div>
  );
}

function ETHRulePreview({ text, rule, ruleIndex, onClick }) {
  const { provider } = useContext(Web3Context);
  const [tokenData, setTokenData] = useState(undefined);
  async function getInitialData() {
    if (!rule) {
      return;
    }
    if (rule.tokenAddress.length !== testAddyLength) {
      return;
    }

    const tokenDataResp = {
      address: rule.address,
      symbol: rule.symbol,
      name: rule.name,
      image: rule.image,
      logoURI: rule.image,
      decimals: rule.decimals,
    };
    setTokenData(tokenDataResp);

    return;
  }
  useEffect(() => {
    getInitialData();
  }, [rule]);

  return (
    <div onClick={() => onClick({ rule: rule, ruleIndex: ruleIndex })}>
      <SubtitleText textAlign={"start"} color={"black"} fontSize={"18px"}>
        {"Rule " + (ruleIndex + 1) + ": " + config.ruleTypes[rule.type]}
      </SubtitleText>
      <SizedBox height={8} />
      <SecondaryButton
        onClick={() => {
          // setShowAddRuleModal(true);
        }}
      >
        {tokenData && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>{rule.quantity / 10 ** parseInt(tokenData.decimals)}</div>

            <div style={{ display: "flex", alignItems: "center" }}>
              {tokenData && tokenData.image && (
                <img
                  src={utils.formatIPFStoGateway(tokenData.image)}
                  alt={"logo"}
                  style={{
                    borderRadius: "500px",
                    height: "30px",
                    width: "30px",
                  }}
                />
              )}
              <SizedBox width={8} />
              <div>{tokenData.symbol}</div>
            </div>
          </div>
        )}
      </SecondaryButton>
    </div>
  );
}

function NFTPreviewChip({ nftContract, onClick }) {
  const colors = useAppColors();
  return (
    <div style={{ marginRight: "12px", marginTop: "12px" }}>
      <StandardShadow borderRadius={"18px"}>
        <NFTPreviewChipWrapper
          onClick={onClick}
          textFieldColor={colors.textFieldColor}
        >
          {nftContract.image && (
            <img
              style={{ height: "30px", width: "30px", borderRadius: "500px" }}
              src={utils.formatIPFStoGateway(nftContract.image)}
              alt="nft"
            />
          )}
          <SizedBox width={4} />
          <div>{nftContract.symbol}</div>
        </NFTPreviewChipWrapper>
      </StandardShadow>
    </div>
  );
}

function NFTOwnershipRule({
  saveRule,
  type,
  closeRule,
  cancelRule,
  nfts,
  ownedNftContractData,
  initialRule,
}) {
  const { provider, showSnackbar } = useContext(Web3Context);
  const colors = useAppColors();
  const [selectedToken, setSelectedToken] = useState(undefined);
  const [showTokenModal, setShowTokenModal] = useState(false);
  const [quantity, setQuantity] = useState("");
  async function setToken({ token }) {
    setSelectedToken(token);
    setShowTokenModal(false);
  }

  async function getInitialTokenData() {
    setQuantity(initialRule.quantity);
    const metadata = await ethereumUtils.getNFTContractData({
      nftAddress: initialRule.tokenAddress,
      provider: provider,
      needImage: true,
    });
    if (metadata.error) {
      showSnackbar({ message: metadata.error, severity: "error" });
    }
    let t = {
      chainId: 1,
      address: initialRule.tokenAddress,
      name: metadata.name,
      symbol: metadata.symbol,
      decimals: metadata.decimals,
      logoURI: metadata.image,
      image: metadata.image,
    };
    setSelectedToken(t);
  }
  useEffect(() => {
    if (initialRule && initialRule.type) {
      getInitialTokenData();
    }
  }, [initialRule]);

  useEffect(() => {
    if (quantity && quantity.length > 0) {
      setQuantity(Math.floor(parseFloat(quantity)).toString());
    }
  }, [quantity]);

  async function setRule() {
    const quantityNum = parseFloat(quantity);
    const r = {
      type: type,
      quantity: quantityNum,
      tokenAddress: selectedToken.address,
      name: selectedToken.name,
      symbol: selectedToken.symbol,
      image: selectedToken.logoURI,
      decimals: selectedToken.decimals,
    };
    saveRule({ rule: r });
    closeRule();
  }

  return (
    <>
      <Row style={{ width: "100%" }}>
        <CustomInputWithSideSelector
          name={"Select NFT"}
          onSelectorClick={() => setShowTokenModal(true)}
          selectorImage={
            selectedToken
              ? utils.formatIPFStoGateway(selectedToken.logoURI)
              : undefined
          }
          selectorText={
            selectedToken
              ? specialSymbols.data[selectedToken.address.toLowerCase()] &&
                specialSymbols.data[selectedToken.address.toLowerCase()].symbol
                ? specialSymbols.data[selectedToken.address.toLowerCase()]
                    .symbol
                : selectedToken.symbol
              : undefined
          }
          type={"number"}
          placeholder={"0"}
          selectorTextPlaceholder={"Select Token"}
          value={quantity}
          setValue={setQuantity}
        />
      </Row>
      <SizedBox height={24} />
      {ownedNftContractData && ownedNftContractData.length > 0 && (
        <NFTPreviewChips borderColor={colors.textFieldColor}>
          {[...ownedNftContractData].map(
            (o) =>
              o &&
              o.symbol && (
                <NFTPreviewChip
                  key={o.address}
                  nftContract={
                    ownedNftContractData && ownedNftContractData.length > 0
                      ? o
                      : undefined
                  }
                  onClick={() => setToken({ token: o })}
                />
              )
          )}
        </NFTPreviewChips>
      )}
      <SizedBox height={16} />
      <Row style={{ width: "100%" }}>
        <NFTSelectModal
          initialRule={initialRule}
          show={showTokenModal}
          setShow={setShowTokenModal}
          onClick={setToken}
          ownedNftContractData={ownedNftContractData}
        />
      </Row>
      <SizedBox height={16} />

      <Row style={{ width: "100%" }}>
        <Col>
          <SecondaryButton
            onClick={() => {
              closeRule();
            }}
            text="Delete"
          />
        </Col>
        <Col>
          <PrimaryButton
            disabled={!selectedToken || !quantity}
            onClick={() => {
              setRule();
            }}
            text="Save Rule"
          />
        </Col>
      </Row>
      <SizedBox height={16} />
      <Row>
        <FlatButton onClick={cancelRule}>Cancel</FlatButton>
      </Row>
    </>
  );
}

function ERC20OwnershipRule({
  saveRule,
  cancelRule,
  type,
  closeRule,
  initialRule,
}) {
  const { provider, showSnackbar } = useContext(Web3Context);
  const [selectedToken, setSelectedToken] = useState(undefined);
  const [showTokenModal, setShowTokenModal] = useState(false);
  const [quantity, setQuantity] = useState("");
  async function setToken({ token }) {
    setSelectedToken(token);
    setShowTokenModal(false);
  }

  useEffect(() => {}, [quantity]);

  async function setRule() {
    const quantityNum = parseFloat(quantity);
    const r = {
      type: type,
      quantity: quantityNum * 10 ** parseInt(selectedToken.decimals),
      tokenAddress: selectedToken.address,
      name: selectedToken.name,
      symbol: selectedToken.symbol,
      image: selectedToken.logoURI,
      decimals: selectedToken.decimals,
    };
    saveRule({ rule: r });
    closeRule();
  }

  async function getInitialTokenData() {
    const metadata = await ethereumUtils.getERC20ContractData({
      address: initialRule.tokenAddress,
      provider: provider,
    });
    if (metadata.error) {
      showSnackbar({ message: metadata.error, severity: "error" });
    }
    let o = utils.getUniswapTokenData({ address: initialRule.tokenAddress });
    let t = {
      chainId: 1,
      address: initialRule.tokenAddress,
      name: metadata.name,
      symbol: metadata.symbol,
      decimals: metadata.decimals,
      logoURI: metadata.image,
      image: metadata.image,
    };
    if (o) {
      t.image = o.token.logoURI;
      t.logoURI = o.token.logoURI;
    }
    // const bn = BigNumber.from(
    //   initialRule.quantity / 10 ** t.decimals
    // ).toString();

    setQuantity(initialRule.quantity / 10 ** t.decimals);
    setSelectedToken(t);
  }
  useEffect(() => {
    if (initialRule && initialRule.type) {
      getInitialTokenData();
    }
  }, [initialRule]);

  return (
    <>
      <Row style={{ width: "100%" }}>
        <CustomInputWithSideSelector
          name={"Select Token"}
          onSelectorClick={() => setShowTokenModal(true)}
          selectorImage={
            selectedToken
              ? utils.formatIPFStoGateway(selectedToken.logoURI)
              : undefined
          }
          selectorText={selectedToken ? selectedToken.symbol : undefined}
          type={"number"}
          placeholder={"0.0"}
          selectorTextPlaceholder={"Select Token"}
          value={quantity}
          setValue={setQuantity}
        />
      </Row>

      <SizedBox height={16} />
      <Row style={{ width: "100%" }}>
        <TokenSelectModal
          show={showTokenModal}
          setShow={setShowTokenModal}
          onClick={setToken}
          initialRule={initialRule}
        />
      </Row>
      <SizedBox height={16} />

      <Row style={{ width: "100%" }}>
        <Col>
          <SecondaryButton
            onClick={() => {
              closeRule();
            }}
            text="Delete"
          />
        </Col>
        <Col>
          <PrimaryButton
            disabled={!selectedToken || !quantity}
            onClick={() => {
              setRule();
            }}
            text="Save Rule"
          />
        </Col>
      </Row>
      <SizedBox height={16} />
      <Row>
        <FlatButton onClick={cancelRule}>Cancel</FlatButton>
      </Row>
    </>
  );
}

function ETHOwnershipRule({
  saveRule,
  cancelRule,
  type,
  closeRule,
  initialRule,
}) {
  const { provider } = useContext(Web3Context);
  const [selectedToken, setSelectedToken] = useState(undefined);
  const [quantity, setQuantity] = useState("");
  async function setToken({ token }) {
    setSelectedToken(token);
  }

  useEffect(() => {}, [quantity]);

  async function setRule() {
    const quantityNum = parseFloat(quantity);
    const r = {
      type: type,
      quantity: quantityNum * 10 ** 18,
      tokenAddress: ethToken.address,
      name: ethToken.name,
      symbol: ethToken.symbol,
      image: ethToken.image,
      logoURI: ethToken.image,
      decimals: ethToken.decimals,
    };
    saveRule({ rule: r });
    closeRule();
  }

  async function getInitialTokenData() {
    let o = utils.getUniswapTokenData({ address: initialRule.tokenAddress });
    let t = {
      chainId: 1,
      tokenAddress: ethToken.address,
      name: ethToken.name,
      symbol: ethToken.symbol,
      image: ethToken.image,
      logoURI: ethToken.image,
      decimals: ethToken.decimals,
    };
    if (o) {
      t.image = o.token.logoURI;
      t.logoURI = o.token.logoURI;
    }
    setQuantity(
      (initialRule && initialRule.quantity ? initialRule.quantity : 0) /
        10 ** t.decimals
    );
    setSelectedToken(t);
  }
  useEffect(() => {
    getInitialTokenData();
  }, [initialRule]);

  return (
    <>
      <Row style={{ width: "100%" }}>
        <CustomInputWithSideSelector
          name={"Select Amount"}
          onSelectorClick={() => {}}
          selectorImage={
            selectedToken
              ? utils.formatIPFStoGateway(selectedToken.logoURI)
              : undefined
          }
          selectorText={selectedToken ? selectedToken.symbol : undefined}
          type={"number"}
          placeholder={"0.0"}
          selectorTextPlaceholder={"Select Token"}
          value={quantity}
          setValue={setQuantity}
        />
      </Row>

      <SizedBox height={16} />
      <Row style={{ width: "100%" }}></Row>
      <SizedBox height={16} />

      <Row style={{ width: "100%" }}>
        <Col>
          <SecondaryButton
            onClick={() => {
              closeRule();
            }}
            text="Delete"
          />
        </Col>
        <Col>
          <PrimaryButton
            disabled={!selectedToken || !quantity}
            onClick={() => {
              setRule();
            }}
            text="Save Rule"
          />
        </Col>
      </Row>
      <SizedBox height={16} />
      <Row>
        <FlatButton onClick={cancelRule}>Cancel</FlatButton>
      </Row>
    </>
  );
}

function NFTSelectModal({
  show,
  setShow,
  onClick,
  searchNfts,
  ownedNftContractData,
  initialRule,
}) {
  const { provider, showSnackbar } = useContext(Web3Context);
  const colors = useAppColors();
  const [filteredTokens, setFilteredTokens] = useState(
    [...nftList.tokens].filter((t) => t.chainId === 1)
  );
  const [search, setSearch] = useState("");

  async function getTokenData() {
    const slc = search.toLowerCase();
    let n = [...nftList.tokens].filter(
      (t) => t.chainId === 1 && t.address.toLowerCase() === slc
    );
    if (n.length > 0) {
      setFilteredTokens([n[0]]);
      return;
    }
    const metadata = await ethereumUtils.getNFTContractData({
      nftAddress: search,
      provider: provider,
      needImage: true,
    });
    if (metadata.error) {
      showSnackbar({ message: metadata.error, severity: "error" });
    }
    let t = {
      chainId: 1,
      address: search,
      name: metadata.name,
      symbol: metadata.symbol,
      decimals: metadata.decimals,
      logoURI: metadata.image,
      image: metadata.image,
    };
    setFilteredTokens([t]);
  }

  async function filterSearch() {
    if (search.length === 0) {
      setFilteredTokens([...nftList.tokens].filter((t) => t.chainId === 1));
    } else if (search.length === exampleAddy.length) {
      getTokenData();
    } else {
      const slc = search.toLowerCase();

      let n = [...nftList.tokens].filter(
        (t) =>
          t.chainId === 1 &&
          (t.name.toLowerCase().includes(slc) ||
            t.symbol.toLowerCase().includes(slc))
      );
      setFilteredTokens(n);
    }
  }

  useEffect(() => {
    if (!show) {
      setSearch("");
    }
  }, [show]);

  useEffect(() => {
    filterSearch();
  }, [search]);

  return (
    <Modal
      dialogClassName="modal-60w"
      show={show}
      onHide={() => {
        setShow(false);
      }}
    >
      <Modal.Body>
        <Center>
          <Row style={{ width: "80%" }}>
            <Col
              className="d-flex flex-column justify-content-center align-items-center"
              style={{ width: "100%" }}
            >
              <SizedBox height={32} />
              <SubtitleText color={"black"} textAlign={"start"}>
                Select an NFT
              </SubtitleText>
              <SizedBox height={16} />
              <Row style={{ width: "100%" }}>
                <CustomTextField
                  value={search}
                  setValue={setSearch}
                  placeholder={"Search name or paste address"}
                />
              </Row>
              <SizedBox height={24} />
              {ownedNftContractData && ownedNftContractData.length > 0 && (
                <NFTPreviewChips borderColor={colors.textFieldColor}>
                  {[...ownedNftContractData].map(
                    (o) =>
                      o &&
                      o.symbol && (
                        <NFTPreviewChip
                          key={o.address}
                          nftContract={
                            ownedNftContractData &&
                            ownedNftContractData.length > 0
                              ? o
                              : undefined
                          }
                          onClick={() => onClick({ token: o })}
                        />
                      )
                  )}
                </NFTPreviewChips>
              )}
              <SizedBox height={16} />

              <Row style={{ width: "100%" }}>
                {/* <CustomInputWithSideSelector
                  onSelectorClick={null}
                  selectorText={
                    selectedToken ? selectedToken.symbol : undefined
                  }
                  type={"number"}
                  placeholder={"0.0"}
                  selectorImage={undefined}
                  selectorTextPlaceholder={"Select Token"}
                /> */}
              </Row>
              <TokenListWrapper height={"24vh"}>
                {filteredTokens.map(
                  (t) =>
                    t && (
                      <ERC20ListItem
                        key={t.address}
                        onClick={() => onClick({ token: t })}
                        token={t}
                      />
                    )
                )}
              </TokenListWrapper>
              <SizedBox height={16} />
              <Row style={{ width: "100%" }}>
                <Col>
                  <SecondaryButton
                    onClick={() => {
                      setShow(false);
                    }}
                    text="Cancel"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Center>
        <SizedBox height={32} />
      </Modal.Body>
    </Modal>
  );
}
