import { useLottie } from "lottie-react";
import { useEffect, useState } from "react";


export default function LottieFrame({ animationData, loop, width, height }) {
    const [options, setOptions] = useState({
        animationData: animationData,
        loop: loop
    })


    useEffect(() => {
        setOptions(
            {
                animationData: animationData,
                loop: loop
            }
        )
    }, [animationData, loop])

    const { View } = useLottie(options);

    return <div style={{width: width, height: height}}>{animationData && View}</div>;
}