import { useContext, useState } from "react";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import OptionListTile from "./OptionListTile";
import { BiWallet } from "react-icons/bi";
import { BiEditAlt } from "react-icons/bi";
import { FiLogOut } from "react-icons/fi";
import { FiUser } from "react-icons/fi";
import { AiFillCalendar } from "react-icons/ai";
import { SizedBox } from "./SizedBox";
import { WidthContainer } from "./WidthContainer";
import { Center } from "./Center";
import { useAppColors } from "../utils/useAppColors";
import { Web3Context } from "../contexts/Web3Context";
import { useNavigate } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";
import { Col, Modal, Row } from "react-bootstrap";
import { TitleText } from "./TitleText";
import { DynamicImage } from "./DynamicImage";
import api from "../utils/api";
import LoadingIndicator from "./LoadingIndicator";
import { FaUserAlt, FaUserAltSlash } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { DescriptionText } from "./DescriptionText";
import IconButton from "./IconButton";

const deactivateText =
  "Deactivating your account will prevent people from scheduling meetings with you.";
const activateText =
  "Activating your account will allow people to schedule meetings with you.";

export default function AccountActivationSheet({ open, setOpen }) {
  const { user, setUser, jwt, logout, account, showSnackbar, log } =
    useContext(Web3Context);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const colors = useAppColors();

  async function changeAccountStatus() {
    try {
      setIsLoading(true);
      let s = "active";
      let successText = "";
      if (user.status === "inactive") {
        s = "active";
        successText = "Successfully activated your account";
      } else {
        s = "inactive";
        successText = "Successfully deactivated your account";
      }

      user.status = s;
      const response = await api.updateUserData2({ user: user, jwt: jwt });
      if (response.error) {
        log({
          eventName: "updateUserData error",
          eventParams: {
            userUid: user ? user._id : undefined,
            error: response.error,
          },
        });
        throw Error(response.error);
      }
      log({
        eventName: "updateUserData",
        eventParams: {
          userUid: user ? user._id : undefined,
        },
      });
      setUser(response.user);
      showSnackbar({ message: successText, severity: "success" });
      setOpen(false);
      setIsLoading(false);
    } catch (e) {
      log({
        eventName: "general error",
        eventParams: {
          userUid: user ? user._id : undefined,
          error: e,
        },
      });
      showSnackbar({ message: e.message, severity: "error" });
      setOpen(false);
      setIsLoading(false);
    }
  }

  return (
    <>
      {user && account && (
        <>
          <BrowserView>
            <Modal
              dialogClassName="modal-30w"
              show={open}
              onHide={() => {
                setOpen(false);
              }}
            >
              <Modal.Body>
                <Center>
                  {isLoading ? (
                    <LoadingIndicator />
                  ) : (
                    <div style={{ width: "90%" }}>
                      <SizedBox height={8} />
                      <Row className="d-flex flex-row justify-content-center">
                        <Col className="d-flex align-items-center">
                          <TitleText fontSize={"26px"} textAlign={"start"}>
                            {user.status === "inactive"
                              ? "Activate your account"
                              : "Deactivate your account"}
                          </TitleText>
                        </Col>
                        <Col xs="2">
                          <IconButton
                            Icon={ImCross}
                            onClick={() => {
                              setOpen(false);
                            }}
                          />
                        </Col>
                      </Row>
                      <SizedBox height={8} />
                      <Row>
                        <Col>
                          <DescriptionText>
                            {user.status === "inactive"
                              ? activateText
                              : deactivateText}
                          </DescriptionText>
                        </Col>
                      </Row>
                      <SizedBox height={20} />
                      <OptionListTile
                        onClick={changeAccountStatus}
                        name={
                          user.status === "inactive" ? "Activate" : "Deactivate"
                        }
                        Icon={
                          user.status === "inactive"
                            ? FaUserAlt
                            : FaUserAltSlash
                        }
                      />
                      <SizedBox height={48} />
                    </div>
                  )}
                </Center>
              </Modal.Body>
            </Modal>
          </BrowserView>
          <MobileView>
            {account ? (
              <>
                <BottomSheet
                  open={open}
                  onDismiss={() => {
                    setOpen(false);
                  }}
                >
                  <Center>
                    <div style={{ width: "90%" }}>
                      <SizedBox height={8} />
                      <Row className="d-flex flex-row justify-content-center">
                        <Col className="d-flex align-items-center">
                          <TitleText textAlign={"start"}>
                            {user.status === "inactive"
                              ? "Activate your account"
                              : "Deactivate your account"}
                          </TitleText>
                        </Col>
                      </Row>
                      <SizedBox height={20} />
                      <OptionListTile
                        onClick={changeAccountStatus}
                        name={
                          user.status === "inactive" ? "Activate" : "Deactivate"
                        }
                        Icon={
                          user.status === "inactive"
                            ? FaUserAlt
                            : FaUserAltSlash
                        }
                      />
                    </div>
                  </Center>
                  <SizedBox height={32} />
                </BottomSheet>
              </>
            ) : null}
          </MobileView>
        </>
      )}
    </>
  );
}
