import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { WidthContainer } from "../components/WidthContainer";
import { Web3Context } from "../contexts/Web3Context";
import Calendar from "../components/calendar";
import TimeZoneMenu from "../components/TimeZoneMenu";
import api from "../utils/api";
import { Col, Row } from "react-bootstrap";
import ProfileCard from "../components/ProfileCard";
import ScheduleSidebar from "../components/ScheduleSidebar";
import { PrimaryButton } from "../components/PrimaryButton";
import { Center } from "../components/Center";
import { CustomTextField } from "../components/CustomTextInput";
import LoadingIndicator from "../components/LoadingIndicator";
import BondView from "../components/BondView";
import { TitleText } from "../components/TitleText";
import { SizedBox } from "../components/SizedBox";
import { SubtitleText } from "../components/SubtitleText";
import { DescriptionText } from "../components/DescriptionText";
import { useAppColors } from "../utils/useAppColors";
import { SecondaryButton } from "../components/SecondaryButton";
import { FlatButton } from "../components/FlatButton";
import * as moment from "moment-timezone";
import { BrowserView, MobileView } from "react-device-detect";
import validator from "email-validator";
import Moment from "react-moment";
import InfoCard from "../components/InfoCard";
import localstorage from "../utils/localstorage";
import IconButton from "../components/IconButton";
import { FaArrowLeft, FaHourglassHalf } from "react-icons/fa";
import { SelectedCircle } from "../components/SelectedCircle";
import {
  BsFillCalendarEventFill,
  BsFillCheckCircleFill,
  BsFillClockFill,
} from "react-icons/bs";
import TimezoneSelect from "react-timezone-select";
import BondView2 from "../components/BondView2";
const timeSegments = [
  { title: "15 Minutes", timeMinutes: 15 },
  { title: "30 Minutes", timeMinutes: 30 },
  { title: "45 Minutes", timeMinutes: 45 },
  { title: "1 Hour", timeMinutes: 60 },
];

export default function SchedulePage2() {
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { jwt, user,  showSnackbar, log } = useContext(Web3Context);
  const colors = useAppColors();
  const [timeZone, setTimeZone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const [selectedDay, setSelectedDay] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [isGettingInitialData, setIsGettingInitialData] = useState(false);
  const [bondId, setBondId] = useState(searchParams.get("b"));
  const [username, setUsername] = useState(searchParams.get("u"));
  const [oneTimeLink, setOneTimeLink] = useState(searchParams.get("otl"));
  const [hasGuests, setHasGuests] = useState(false);
  const [guestEmails, setGuestEmails] = useState("");
  const [meetingNotes, setMeetingNotes] = useState("");
  const [otherUser, setOtherUser] = useState(undefined);
  const [today, setToday] = useState(new Date());
  const [oneMonth, setOneMonth] = useState(today.setDate(today.getDate() + 30));
  const [busy, setBusy] = useState(undefined);
  const [timeZoneOffset, setTimeZoneOffset] = useState(undefined);
  const [otherTimeZone, setOtherTimeZone] = useState(undefined);
  const [bond, setBond] = useState(undefined);
  const [timeSegment, setTimeSegment] = useState(undefined);
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [oneTimeLinkSecret, setOneTimeLinkSecret] = useState(undefined);
  const [availableDays, setAvailableDays] = useState(undefined);
  // const resp = Intl.DateTimeFormat().resolvedOptions();
  // const localTz = resp.timeZone;

  useEffect(() => {
    setIsValidEmail(validator.validate(email));
  }, [email]);

  function getTimeZoneOffset(date, tz) {
    // Abuse the Intl API to get a local ISO 8601 string for a given time zone.

    let iso = date
      .toLocaleString("en-CA", { tz, hour12: false })
      .replace(", ", "T");
    // Include the milliseconds from the original timestamp
    iso += "." + date.getMilliseconds().toString().padStart(3, "0");

    // Lie to the Date object constructor that it's a UTC time.
    const lie = new Date(iso + "Z");

    // Return the difference in timestamps, as minutes
    // Positive values are West of GMT, opposite of ISO 8601
    // this matches the output of `Date.getTimeZoneOffset`
    return -(lie - date) / 60 / 1000;
  }

  useEffect(() => {
    if (timeZone && otherTimeZone) {
      var now = moment().utc();
      // get the zone offsets for this time, in minutes
      let t = typeof timeZone === "string" ? timeZone : timeZone.value;
      var currentTzOffset = moment.tz.zone(t).offset(now);
      var otherTzOffset = moment.tz.zone(otherTimeZone).offset(now);
      // calculate the difference in hours

      setTimeZoneOffset((currentTzOffset - otherTzOffset) / 60);
    }
  }, [timeZone, otherTimeZone]);

  async function getInitialData() {
    try {
      if (user) {
        setEmail(user.email);
        setIsValidEmail(validator.validate(email));
      }
      if (!username && !oneTimeLink) {
        showSnackbar({ message: "Couldn't find user", severity: "error" });
        return;
      }
      setIsGettingInitialData(true);
      setIsLoading(true);

      let otls;
      if (oneTimeLink) {
        otls = localstorage.getLocalStorageOneTimeLinkSecret({
          oneTimeLinkUid: oneTimeLink,
        });
        setOneTimeLinkSecret(otls);
      }

      const response = await api.getScheduleData2({
        username: username,
        bondId: bondId,
        tz: timeZone,
        oneTimeLinkUid: oneTimeLink,
        oneTimeLinkSecret: otls,
        jwt: jwt,
      });

      //("GETSCHEDULE RESPONSE: ", response);
      setIsGettingInitialData(false);
      if (response.error) {
        showSnackbar({ message: response.error, severity: "error" });
        log({
          eventName: "getScheduleData error",
          eventParams: {
            userUid: user ? user._id : undefined,
            error: response.error,
          },
        });
        setIsLoading(false);
        return;
      }
      //("OTHER USER: ", response.user);
      setOtherUser(response.user);
      setBusy(response.busy);
      setTimeZone(response.timeZone);
      setOtherTimeZone(response.otherTimeZone);
      setBond(response.bond);
      setAvailableDays(response.user.availableDays);
      log({
        eventName: "getScheduleData",
        eventParams: {
          userUid: user ? user._id : undefined,
          otherUserUid: response.user._id,
        },
      });
      if (response.otlSecret) {
        setOneTimeLinkSecret(response.otlSecret);
        localstorage.setLocalStorageOneTimeLinkSecret({
          oneTimeLinkUid: oneTimeLink,
          oneTimeLinkSecret: response.otlSecret,
        });
      }

      const currentDate = new Date();

      // get the current time so we know which offset to take (DST is such bullkitten)
      var now = moment().utc();
      // get the zone offsets for this time, in minutes
      const b = Intl.DateTimeFormat().resolvedOptions().timeZone;

      var currentTzOffset = moment.tz.zone(b).offset(now);
      var otherTzOffset = moment.tz.zone(response.otherTimeZone).offset(now);
      // calculate the difference in hours

      setTimeZoneOffset((currentTzOffset - otherTzOffset) / 60);

      setIsLoading(false);
    } catch (e) {
      //(e);
      // window.alert(e);
      showSnackbar({ message: e.message, severity: "error" });
      log({
        eventName: "general error",
        eventParams: {
          userUid: user ? user._id : undefined,
          error: e,
        },
      });
      setIsGettingInitialData(false);
      setIsLoading(false);
      return;
    }
  }

  async function createEvent() {
    try {
      setIsLoading(true);

      const resp = await api.createEvent2({
        username: username,
        bondId: bondId,
        time: selectedTime,
        email: email,
        guestEmails: guestEmails,
        meetingNotes: meetingNotes,
        tz: timeZone,
        oneTimeLinkUid: oneTimeLink,
        oneTimeLinkSecret: oneTimeLinkSecret,
        jwt: jwt,
      });
      if (resp.error) {
        log({
          eventName: "createEvent error",
          eventParams: {
            userUid: user ? user._id : undefined,
            error: resp.error,
          },
        });
        throw Error(resp.error);
      }
      log({
        eventName: "createEvent",
        eventParams: {
          userUid: user ? user._id : undefined,
          eventUid: resp.event._id,
        },
      });
      let nav = `/s/details?e=${resp.event._id}&tz=${timeZone}`;
      if (!user) {
        nav = nav + `&g=${email}`;
      }
      navigate(nav);
    } catch (e) {
      log({
        eventName: "general error",
        eventParams: {
          userUid: user ? user._id : undefined,
          error: e,
        },
      });
      showSnackbar({ message: e.message, severity: "error" });
      setIsLoading(false);
    }
  }

  async function setDay(d) {
    setSelectedDay(d);
    setSelectedTime(undefined);
  }

  async function selectTime(time) {
    setSelectedTime(time);
  }

  useEffect(() => {
    // if (!isGettingInitialData) {
    getInitialData();
    // }
  }, [user]);

  return (
    <>
      <BrowserView>
        <WidthContainer width="90%" topPadding={36}>
          {isLoading ? (
            <Center>
              <LoadingIndicator />
            </Center>
          ) : (
            <>
              {otherUser && otherTimeZone ? (
                <Row>
                  <Col md={4}>
                    <ProfileCard u={otherUser} isHorizontal={true} />
                    {bond && (
                      <>
                        <SizedBox height={16} />
                        <SubtitleText
                          textAlign={"left"}
                          color={colors.titleColor}
                        >
                          Selected Bond
                        </SubtitleText>
                        <SizedBox height={12} />

                        <BondView2
                          bond={bond}
                          match={true}
                          selected={true}
                          selectBond={(_) => {}}
                        />
                      </>
                    )}
                    <SizedBox height={12} />

                    {timeSegment && (
                      <InfoCard padding={"24px"}>
                        <SubtitleText textAlign={"start"} color={"black"}>
                          Meeting Details
                        </SubtitleText>
                        {timeSegment && (
                          <Col>
                            <SizedBox height={"12px"} />
                            <DescriptionText>
                              <FaHourglassHalf
                                style={{ zIndex: 5 }}
                                color={colors.primaryColor}
                                size={20}
                              />
                              {` Meeting Duration: ${timeSegment.title}`}
                            </DescriptionText>
                          </Col>
                        )}
                        {timeSegment && selectedDay && (
                          <Col>
                            <SizedBox height={"12px"} />
                            <DescriptionText>
                              <BsFillCalendarEventFill
                                style={{ zIndex: 5 }}
                                color={colors.primaryColor}
                                size={20}
                              />
                              {` Meeting Day: ${selectedDay.toDateString()}`}
                            </DescriptionText>
                          </Col>
                        )}
                        {timeSegment && selectedDay && selectedTime && (
                          <Col>
                            <SizedBox height={"12px"} />
                            <DescriptionText>
                              <BsFillClockFill
                                style={{ zIndex: 5 }}
                                color={colors.primaryColor}
                                size={20}
                              />{" "}
                              {" Meeting time: "}
                              <Moment format="h:mm a">
                                {selectedTime.start}
                              </Moment>
                            </DescriptionText>
                          </Col>
                        )}
                      </InfoCard>
                    )}
                  </Col>
                  <Col>
                    <Center>
                      {!timeSegment ? (
                        <TimeSegmentSelector
                          timeSegments={timeSegments}
                          setTimeSegment={setTimeSegment}
                        />
                      ) : (
                        <Col>
                          <div style={{ display: "flex" }}>
                            <Center>
                              {(!selectedTime || user) && (
                                <div style={{ width: "80%", display: "flex" }}>
                                  <Col xs={2}>
                                    <IconButton
                                      Icon={FaArrowLeft}
                                      onClick={() => {
                                        if (!selectedTime) {
                                          setTimeSegment(undefined);
                                        }
                                        setSelectedTime(undefined);
                                      }}
                                    />
                                  </Col>
                                  <Col className="d-flex align-items-center">
                                    <SubtitleText
                                      textAlign={"start"}
                                      color={colors.titleColor}
                                    >
                                      {selectedTime
                                        ? "Meeting Details"
                                        : "Select Date and Time"}
                                    </SubtitleText>
                                  </Col>
                                </div>
                              )}
                            </Center>
                          </div>
                          {(!selectedTime || user) && (
                            <SizedBox height={"16px"} />
                          )}
                          <Center>
                            <div
                              style={{
                                width: "80%",
                                // height: "65vh",
                                display: "flex",
                                alignItems:
                                  selectedTime && !user ? "start" : "start",
                                // overflow: "scroll",
                              }}
                            >
                              {selectedTime ? (
                                <>
                                  <Center>
                                    <MeetingDetailsSection
                                      timeSegment={timeSegment}
                                      selectedTime={selectedTime}
                                      selectedDay={selectedDay}
                                      setSelectedTime={setSelectedTime}
                                      user={user}
                                      colors={colors}
                                      guestEmails={guestEmails}
                                      setGuestEmails={setGuestEmails}
                                      email={email}
                                      setEmail={setEmail}
                                      hasGuests={hasGuests}
                                      setHasGuests={setHasGuests}
                                      meetingNotes={meetingNotes}
                                      setMeetingNotes={setMeetingNotes}
                                    />
                                  </Center>
                                </>
                              ) : (
                                <Col>
                                  <Calendar
                                    onDayClick={(e, day) => {
                                      setDay(day);
                                    }}
                                    width="100%"
                                    
                                    allowedDays={availableDays}
                                  />
                                  <TimeZoneMenu
                                    onChangeTimeZone={(t) => {
                                      setTimeZone(t);
                                    }}
                                  />
                                </Col>
                              )}
                            </div>
                          </Center>
                          <SizedBox height={"24px"} />
                        </Col>
                      )}
                    </Center>
                    <PrimaryButton
                      text="Schedule a Meeting"
                      disabled={!(selectedTime && isValidEmail)}
                      onClick={createEvent}
                    />
                    <SizedBox height={"48px"} />
                  </Col>
                  <Col md={3}>
                    {timeSegment && selectedDay && (
                      <ScheduleSidebar
                        busy={busy}
                        timeZone={timeZone}
                        selectTime={selectTime}
                        selectedTime={selectedTime}
                        selectedDay={selectedDay}
                        bond={bond}
                        timeSegment={timeSegment}
                        setTimeSegment={setTimeSegment}
                        timeSegments={timeSegments}
                        timeZoneOffset={timeZoneOffset}
                        otherUserTimeZone={otherTimeZone}
                        otherUserDayStart={otherUser.dayStart}
                        otherUserDayEnd={otherUser.dayEnd}
                      />
                    )}
                  </Col>
                </Row>
              ) : (
                <Row>
                  <TitleText>Schedule Not Available</TitleText>
                </Row>
              )}
            </>
          )}
        </WidthContainer>
      </BrowserView>
      <MobileView>
        <WidthContainer topPadding={36}>
          {isLoading ? (
            <Center>
              <LoadingIndicator />
            </Center>
          ) : (
            <>
              {otherUser && otherTimeZone ? (
                <Row>
                  <Col>
                    <ProfileCard u={otherUser} />
                    {bond && (
                      <>
                        <SizedBox height={16} />
                        <SubtitleText
                          textAlign={"left"}
                          color={colors.titleColor}
                        >
                          Selected Bond
                        </SubtitleText>
                        <SizedBox height={12} />

                        <BondView2
                          bond={bond}
                          match={true}
                          selected={true}
                          selectBond={(_) => {}}
                        />
                      </>
                    )}
                    <SizedBox height={12} />

                    <Center>
                      {!timeSegment ? (
                        <TimeSegmentSelector
                          timeSegments={timeSegments}
                          setTimeSegment={setTimeSegment}
                        />
                      ) : (
                        <Col>
                          {(!selectedTime || user) && (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              {/* <SubtitleText
                                fontSize={"14px"}
                                color={colors.titleColor}
                              >
                                {timeSegment.title}
                              </SubtitleText> */}

                              <FlatButton
                                onClick={() => {
                                  setTimeSegment(undefined);
                                  setSelectedTime(undefined);
                                }}
                              >
                                <div>Change Timeframe</div>
                              </FlatButton>
                            </div>
                          )}
                          <SizedBox height={18} />
                          <Center>
                            {selectedTime ? (
                              <>
                                <Center>
                                  <MeetingDetailsSection
                                    timeSegment={timeSegment}
                                    selectedTime={selectedTime}
                                    selectedDay={selectedDay}
                                    setSelectedTime={setSelectedTime}
                                    user={user}
                                    colors={colors}
                                    guestEmails={guestEmails}
                                    setGuestEmails={setGuestEmails}
                                    email={email}
                                    setEmail={setEmail}
                                    hasGuests={hasGuests}
                                    setHasGuests={setHasGuests}
                                    meetingNotes={meetingNotes}
                                    setMeetingNotes={setMeetingNotes}
                                  />
                                </Center>
                              </>
                            ) : (
                              <Col>
                              <Row>
                                <Calendar
                                  onDayClick={(e, day) => {
                                    setDay(day);
                                  }}
                                  allowedDays={availableDays}
                                  width="100%"
                                  selectedColor="#BB5BFE"
                                  currentColor="#c2c3f6"
                                  baseColor="#F0DCFF"
                                  textColor="#BB5BFE"
                                  hoverColor="#d6b6ee"
                                />
                                </Row>
                                <Row>
                                <TimeZoneMenu
                                  onChangeTimeZone={(t) => {
                                    setTimeZone(t);
                                  }}
                                />
                                </Row>
                              </Col>
                            )}
                          </Center>
                        </Col>
                      )}
                    </Center>
                    <Center>
                      <Col>
                        {timeSegment &&
                          selectedDay &&
                          (!selectedTime || user) && (
                            <ScheduleSidebar
                              busy={busy}
                              timeZone={timeZone}
                              selectTime={selectTime}
                              selectedTime={selectedTime}
                              selectedDay={selectedDay}
                              bond={bond}
                              timeSegment={timeSegment}
                              setTimeSegment={setTimeSegment}
                              timeSegments={timeSegments}
                              timeZoneOffset={timeZoneOffset}
                              otherUserTimeZone={otherTimeZone}
                              otherUserDayStart={otherUser.dayStart}
                              otherUserDayEnd={otherUser.dayEnd}
                            />
                          )}
                      </Col>
                    </Center>
                    <div
                      onClick={() => {
                        if (!(selectedTime && isValidEmail)) {
                          if (!selectedTime) {
                            showSnackbar({
                              message: "Please select time",
                              severity: "error",
                            });
                          }
                          if (!isValidEmail) {
                            showSnackbar({
                              message: "Please enter a valid email",
                              severity: "error",
                            });
                          }
                        }
                      }}
                    >
                      <PrimaryButton
                        text="Schedule a Meeting"
                        disabled={!(selectedTime && isValidEmail)}
                        onClick={createEvent}
                      />
                    </div>
                    <SizedBox height={48} />
                  </Col>
                </Row>
              ) : (
                <Row>
                  <TitleText>Schedule Not Available</TitleText>
                </Row>
              )}
            </>
          )}
        </WidthContainer>
      </MobileView>
    </>
  );
}

function MeetingDetailsSection({
  timeSegment,
  selectedTime,
  selectedDay,
  setSelectedTime,
  user,
  colors,
  guestEmails,
  setGuestEmails,
  email,
  setEmail,
  hasGuests,
  setHasGuests,
  meetingNotes,
  setMeetingNotes,
}) {
  return (
    <Center>
      <div style={{ width: "90%" }}>
        <Col>
          {/* <InfoCard padding="20px">
            <TitleText fontSize="24px">
              {`${timeSegment.title} at `}
              <Moment format="h:mm a">{selectedTime.start}</Moment>
            </TitleText>
            <DescriptionText textAlign={"center"}>
              <Moment format="dddd DD, MMMM yyyy">{selectedDay}</Moment>
            </DescriptionText>
            <FlatButton
              onClick={() => {
                setSelectedTime(undefined);
              }}
            >
              Change Date
            </FlatButton>
          </InfoCard> */}
          {!user && (
            <>
              <Row>
                <Col xs={2}>
                  <IconButton
                    Icon={FaArrowLeft}
                    onClick={() => {
                      setSelectedTime(undefined);
                    }}
                  />
                </Col>
                <Col className="d-flex align-items-center">
                  <SubtitleText textAlign={"start"} color={colors.titleColor}>
                    Meeting Details
                  </SubtitleText>
                </Col>
              </Row>
              <SizedBox height={"16px"} />
              <SubtitleText textAlign={"start"} color={colors.titleColor}>
                Enter your email
              </SubtitleText>
              {/* <DescriptionText>
                Scheduling a meeting as a guest requires you to input an email
              </DescriptionText> */}
              <SizedBox height={"8px"} />
              <CustomTextField
                placeholder="enter email"
                value={email}
                setValue={setEmail}
              />
            </>
          )}
          <SizedBox height={24} />
          <>
            <SubtitleText textAlign={"start"} color={colors.titleColor}>
              Meeting Notes
            </SubtitleText>
            <SizedBox height={8} />
            <CustomTextField
              type="textarea"
              placeholder={"What's the meeting about?"}
              fontSize="14px"
              rows="4"
              value={meetingNotes}
              setValue={setMeetingNotes}
            />
          </>
          <SizedBox height={24} />
          {hasGuests ? (
            <>
              <SubtitleText textAlign={"start"} color={colors.titleColor}>
                Guest Email(s)
              </SubtitleText>
              {/* <DescriptionText>
                Include up to 10 other people in the meeting, separated by a
                comma
              </DescriptionText> */}
              <SizedBox height={8} />
              <CustomTextField
                type="textarea"
                placeholder={"robert@example.com, max@example.com"}
                fontSize="14px"
                rows="3"
                value={guestEmails}
                setValue={setGuestEmails}
              />
              <SizedBox height={8} />
              <FlatButton
                onClick={() => {
                  setHasGuests(false);
                  setGuestEmails("");
                }}
              >
                Cancel Guests
              </FlatButton>
            </>
          ) : (
            <FlatButton
              onClick={() => {
                setHasGuests(true);
              }}
            >
              Add Guests
            </FlatButton>
          )}

          <SizedBox height={32} />
        </Col>
      </div>
    </Center>
  );
}

function TimeSegmentSelector({ timeSegments, setTimeSegment }) {
  const colors = useAppColors();
  return (
    <>
      <Center>
        <Col>
          <SubtitleText textAlign={"left"} color={colors.titleColor}>
            Select Meeting Timeframe
          </SubtitleText>
          <SizedBox height={32} />
          <Row className="d-flex justify-content-between">
            {timeSegments.map((t) => (
              <>
                <Col xs={6}>
                  <SecondaryButton
                    key={t.toString()}
                    onClick={() => {
                      setTimeSegment(t);
                    }}
                    height={"140px"}
                    text={t.title}
                  ></SecondaryButton>
                  <SizedBox height={18} />
                </Col>
              </>
            ))}
          </Row>
        </Col>
      </Center>
    </>
  );
}
