import { useContext, useEffect, useState } from "react";
import { Web3Context } from "../contexts/Web3Context";
import uniswap from "@uniswap/default-token-list";
import ethereumUtils from "../utils/ethereum";
import { Col, Modal, Row } from "react-bootstrap";
import { Center } from "./Center";
import { SizedBox } from "./SizedBox";
import { SubtitleText } from "./SubtitleText";
import { CustomTextField } from "./CustomTextInput";
import ERC20ListItem from "./ERC20ListItem";
import styled from "styled-components";
import { SecondaryButton } from "./SecondaryButton";
import { BigNumber } from "ethers";
import config from "../utils/config";
import { BrowserView, MobileView } from "react-device-detect";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
const testAddy = "0xbb997742326E8Da7bfe63077f7F66aae07C9D676";
const testAddyLength = testAddy.length;
const exampleAddy = "0x420697b9d4Bb37A2eBEa7510501a8e4886711338";

const TokenListWrapper = styled.div`
  height: ${(props) => (props.height ? props.height : "50vh")};
  overflow-y: scroll;
  width: 100%;
`;

const eth = {
  "chainId": 1,
  "address": config.zeroAddress,
  "name": "Ether",
  "symbol": "ETH",
  "decimals": 18,
  "logoURI": config.ethIcon
}

export function TokenSelectModal({ show, setShow, onClick, initialRule, tokenBalances, hasEth }) {
  const { provider, showSnackbar } = useContext(Web3Context);
  const [tokens, setTokens] = useState([...uniswap.tokens].filter((t) => t.chainId === 1))
  const [filteredTokens, setFilteredTokens] = useState([]);
  const [search, setSearch] = useState("");

  async function initWithOwned() {
    let b = {}
    if (!tokenBalances) {
      return
    }

    for (const o of tokenBalances) {
      // b[o.contractAddress] = BigNumber.from(o.tokenBalance).toString()
      b[o.contractAddress.toLowerCase()] = o.tokenBalance
    }

    let utokens = [...uniswap.tokens].filter((t) => t.chainId === 1)
    if (hasEth) {
      utokens = [eth, ...utokens]
    }
    for (const u of utokens) {
      u.balance = (b[u.address.toLowerCase()] ? b[u.address.toLowerCase()] : 0) / (10 ** (u.decimals ? u.decimals : 18))
    }

    let ut = [...utokens].sort((a, b) => b.balance - a.balance);
    setTokens(ut)
  }

  async function getTokenData() {
    const slc = search.toLowerCase();
    let n = [...tokens].filter(
      (t) => t.chainId === 1 && t.address.toLowerCase() === slc
    );
    if (n.length > 0) {
      setFilteredTokens([n[0]]);
      return;
    }
    const metadata = await ethereumUtils.getERC20ContractData({
      address: search,
      provider: provider,
    });
    if (metadata.error) {
      showSnackbar({ message: metadata.error, severity: "error" });
    }
    let t = {
      chainId: 1,
      address: search,
      name: metadata.name,
      symbol: metadata.symbol,
      decimals: metadata.decimals,
      logoURI: undefined,
    };
    setFilteredTokens([t]);
  }

  async function filterSearch() {
    if (search.length === 0) {
      setFilteredTokens([...tokens].filter((t) => t.chainId === 1));
    } else if (search.length === exampleAddy.length) {
      getTokenData();
    } else {
      const slc = search.toLowerCase();

      let n = [...tokens].filter(
        (t) =>
          t.chainId === 1 &&
          (t.name.toLowerCase().includes(slc) ||
            t.symbol.toLowerCase().includes(slc))
      );
      setFilteredTokens(n);
    }
  }

  useEffect(() => {
    initWithOwned()
  }, [tokenBalances])

  useEffect(() => {
    if (!show) {
      setSearch("");
    }
  }, [show, tokens]);

  useEffect(() => {
    filterSearch();
  }, [search, tokens]);

  return (
    <>
      <BrowserView>
        <Modal
          dialogClassName="modal-60w"
          show={show}
          onHide={() => {
            setShow(false);
          }}
        >
          <Modal.Body>
            <Center>
              <Row style={{ width: "80%" }}>
                <Col
                  className="d-flex flex-column justify-content-center align-items-center"
                  style={{ width: "100%" }}
                >
                  <SizedBox height={32} />
                  <SubtitleText color={"black"} textAlign={"start"}>
                    Select a token
                  </SubtitleText>
                  <SizedBox height={16} />
                  <Row style={{ width: "100%" }}>
                    <CustomTextField
                      value={search}
                      setValue={setSearch}
                      placeholder={"Search name or paste address"}
                    />
                  </Row>
                  <SizedBox height={16} />

                  <Row style={{ width: "100%" }}></Row>
                  <TokenListWrapper>
                    {filteredTokens.map(
                      (t) =>
                        t && (
                          <ERC20ListItem
                            key={t.address}
                            onClick={() => onClick({ token: t })}
                            token={t}
                          />
                        )
                    )}
                  </TokenListWrapper>
                  <SizedBox height={16} />
                  <Row style={{ width: "100%" }}>
                    <Col>
                      <SecondaryButton
                        onClick={() => {
                          setShow(false);
                        }}
                        text="Cancel"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Center>
            <SizedBox height={32} />
          </Modal.Body>
        </Modal>
      </BrowserView>
      <MobileView>
        <BottomSheet
          open={show}
          onDismiss={() => {
            setShow(false);
          }}
        >
          <Center>
            <Row style={{ width: "90%" }}>
              <Col
                className="d-flex flex-column justify-content-center align-items-center"
                style={{ width: "100%" }}
              >
                <SizedBox height={16} />
                <SubtitleText color={"black"} textAlign={"start"}>
                  Select a token
                </SubtitleText>
                <SizedBox height={16} />
                <Row style={{ width: "100%" }}>
                  <CustomTextField
                    value={search}
                    setValue={setSearch}
                    placeholder={"Search or paste"}
                  />
                </Row>
                <SizedBox height={16} />

                <Row style={{ width: "100%" }}></Row>
                <TokenListWrapper>
                  {filteredTokens.map(
                    (t) =>
                      t && (
                        <ERC20ListItem
                          key={t.address}
                          onClick={() => onClick({ token: t })}
                          token={t}
                        />
                      )
                  )}
                </TokenListWrapper>
                <SizedBox height={16} />
                <Row style={{ width: "100%" }}>
                  <Col>
                    <SecondaryButton
                      onClick={() => {
                        setShow(false);
                      }}
                      text="Cancel"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Center>
          <SizedBox height={32} />
        </BottomSheet>
      </MobileView>
    </>

  );
}