const config = {
  zeroAddress: "0x0000000000000000000000000000000000000000",
  usdcAddress: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
  apeAddress: "0x4d224452801ACEd8B2F0aebE155379bb5D594381",
  wbtcAddress: "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599",
  ethIcon: "https://d33wubrfki0l68.cloudfront.net/fcd4ecd90386aeb50a235ddc4f0063cfbb8a7b66/4295e/static/bfc04ac72981166c740b189463e1f74c/40129/eth-diamond-black-white.jpg",
  defaultAvatarUrl:
    "https://firebasestorage.googleapis.com/v0/b/scedule-bond.appspot.com/o/images%2Fdefault-avatars%2FDefaultPfp1.png?alt=media&token=554cb2c8-5e22-480d-abc4-970cd8f9279b",
  ruleTypes: {
    "nft-ownership": "NFT Ownership",
    "erc20-ownership": "ERC20 Ownership",
    "eth-ownership": "ETH Ownership",
    // "erc20-payment": "ERC20 Payment",
    // "eth-payment": "ETH Payment",
    "friend-tech-holder": "Friend Tech Keyholder",
  },
  contracts: {
    "1": {
      "SocialMediaRouter": "0xB2A60b1b537ACc9C0F28ecb20e892188931DE238",
      "EventRouter": "0x9C3d13e1C41ec318366B669f4B3B657922141e24",
      "AltCoins": "0x1ffe11b9fb7f6ff1b153ab8608cf403ecaf9d44a"
    },
    "5":
      {
        "SocialMediaRouter": "0xB4C176E213D92edD4959EE4cb56cC5E0432Ec296",
        "EventRouter":  "0x556B2811851948009eD173c1857e0a31b1900c79",
        "AltCoins": "0x1ffe11b9fb7f6ff1b153ab8608cf403ecaf9d44a"
      }
  }
};

export default config;
