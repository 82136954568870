import React, { useContext, useState } from "react";
import { SpinnerDiamond } from "spinners-react";
import styled from "styled-components";
import { Web3Context } from "../contexts/Web3Context";
import { useAppColors } from "../utils/useAppColors";
import { Center } from "./Center";
import { StandardShadow } from "./StandardShadow";

const SecondaryButtonWrapper = styled.button`
  width: 100%;
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "24px"};
  background-color: ${(props) => props.backgroundColor};
  border-left: 1px solid ${(props) => props.borderColor};
  border-top: 1px solid ${(props) => props.borderColor};
  color: ${(props) => props.color};
  font-weight: bold;
  font-size: 20px;
  padding: ${(props) =>
    props.padding ? props.padding : "20px 40px 20px 40px"};
  &:hover {
    background-color: ${(props) => props.hoverBackgroundColor};
  }
`;

const DisabledButtonWrapper = styled.button`
  width: 100%;
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "24px"};
  background-color: ${(props) => props.backgroundColor};
  border-left: 1px solid ${(props) => props.borderColor};
  border-top: 1px solid ${(props) => props.borderColor};
  color: ${"black"};
  font-weight: bold;
  font-size: 20px;
  padding: ${(props) =>
    props.padding ? props.padding : "20px 40px 20px 40px"};
`;

const BannerWrapper = styled.div`
  width: 100%;
  background-color: ${(props) => props.backgroundColor};

  color: ${props => props.color};
  font-weight: bold;
  font-size: 20px;
  text-align:center;
  padding: ${(props) =>
    props.padding ? props.padding : "20px 40px 20px 40px"};
cursor: pointer;
`;

export const Banner = ({
  show,
  text,
  color,
  backgroundColor,
  onClick
}) => {
  const { user, jwt } = useContext(Web3Context);
  const colors = useAppColors();
  //   const [showRequireUser, setShowRequireUser] = useState(false);
  //   const [showRequireAddress, setShowRequireAddress] = useState(false);

 
    return (
        <>
            {show && <BannerWrapper
            onClick={() => {if(onClick){
                onClick()
            }}} 
                color={color ? color : "white"} 
                backgroundColor={backgroundColor ? backgroundColor : colors.primaryColor}>
                    {text}
                </BannerWrapper>}
        </>)
    
  
};
