import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { WidthContainer } from "../components/WidthContainer";
import LoadingIndicator from "../components/LoadingIndicator";
import { Web3Context } from "../contexts/Web3Context";
import api from "../utils/api";
import { PrimaryButton } from "../components/PrimaryButton";
import config from "../utils/config";
import BondsViewer from "../components/BondsViewer";
import { Col, Row } from "react-bootstrap";
import ProfileCard from "../components/ProfileCard";
import UserBanner from "../components/userBanner";
import Calendar from "../components/calendar";
import TodaySnapshot from "../components/TodaySnapshot";
import { SizedBox } from "../components/SizedBox";
import { isMobile } from "react-device-detect";
import moment from "moment";
import Footer from "../components/Footer"
import { colors } from "@material-ui/core";
import { useAppColors } from "../utils/useAppColors";
import MyCalendar from "../components/MyCalendar";
import { SubtitleText } from "../components/SubtitleText";
import LocalizationProvider from "@mui/lab/LocalizationProvider/LocalizationProvider";
import TimePicker from "@mui/lab/TimePicker/TimePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import styled from "styled-components";
import { StandardShadow } from "../components/StandardShadow";
import TextField from "@mui/material/TextField";
import InfoCard from "../components/InfoCard";
import ProfileParts from "../components/ProfileParts";



export default function Profile2() {
  const navigate = useNavigate();
  let { username } = useParams();
  const { user, account, jwt, theme, showSnackbar, log, login } =
    useContext(Web3Context);
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const [otherUser, setOtherUser] = useState(undefined);
  const [hasMatch, setHasMatch] = useState(false);
  const [selectedBond, setSelectedBond] = useState(undefined);
  const [pageContent, setPageContent] = useState(<div>Loading Content</div>)
  const [email, setEmail] = useState("");

  const colors = useAppColors();

  async function selectBond({ bondIndex, match }) {
    if (user && otherUser._id !== user._id) {
      if (match) {
        setSelectedBond(bondIndex);
      }
    } else {
      login();
    }
  }

  async function getUserData() {
    try {
      const response = await api.getUserData({
        username: username,
        jwt: jwt,
      });

      if (response.error) {
        setIsLoading(false);
        showSnackbar({ message: response.error, severity: "error" });
        log({
          eventName: "getUserData error",
          eventParams: {
            userUid: user ? user._id : undefined,
            error: response.error,
          },
        });
        return;
      }
      setHasMatch(false);
      setOtherUser(response.user);
      if (response.user.bonds) {
        for (const m of response.user.bonds) {
          if (m.match) {
            setHasMatch(true);
          }
        }
      }

      log({
        eventName: "getUserData",
        eventParams: {
          userUid: user ? user._id : undefined,
        },
      });

      setIsLoading(false);
    } catch (e) {
      showSnackbar({ message: e.message, severity: "error" });
      log({
        eventName: "general error",
        eventParams: {
          userUid: user ? user._id : undefined,
          error: e,
        },
      });
      setIsLoading(false);
      return;
    }
  }

  async function goToEditProfile() {
    navigate("/e");
  }

  async function goToScheduleProfile() {
    //("SELECTED BOND:", selectedBond);
    let q = {
      u: otherUser.username,
    };

    if (typeof selectedBond !== "undefined") {
      q.b = selectedBond;
    }
    const queryParams = new URLSearchParams(q).toString();

    navigate("/s?" + queryParams);
  }

  function changeContent(tag) {
    let elems = document.getElementsByClassName("profileNav");
    for (let i = 0; i < elems.length; i++) {
      elems[i].style.backgroundColor = "transparent";
      elems[i].style.color = colors.textColor;
      if(elems[i].id === tag)
      {
        elems[i].style.backgroundColor = colors.primaryColor;
      elems[i].style.color = colors.navColor;
      }
    }

    switch(tag)
    {
      case 1:
        setPageContent(
          <ProfileParts type={"calendar"}/>
          )
        break;
      
      case 2:
          setPageContent(
            <ProfileParts type={"availability"}/>
          )
          break;
        
      case 3:
        setPageContent(
          <ProfileParts type={"bonds"}/>
        )
        break;
      
      case 4:
        setPageContent(
          <ProfileParts type={"profile"}/>
        )
        break;
    }
  }

  useEffect(() => {
    getUserData();

    changeContent(1);
    
  }, [jwt, user]);


  return (
    <div
      style={{
        backgroundColor: `${
          theme === "dark" ? "#030303" : "#fef8f7"
        } !important`,
      }}
    >
    {isMobile ? (
        <>
        </>
    ) : (
        <>
          {otherUser && user && otherUser._id === user._id ? (
            <UserBanner user={username}/>
          ) : (
            <>
            </>
          )
          }
        </>
    )}
      <WidthContainer>
      
        {isMobile ? (
          <>
            {otherUser ? (
              <Row>
                <Col>
                  <Row>
                    <Col>
                      <ProfileCard u={otherUser} />
                    </Col>
                  </Row>
                  <SizedBox height={16} />
                  <Row>
                    <Col>
                      <BondsViewer
                        otherUser={otherUser}
                        user={user}
                        bonds={otherUser.bonds ? otherUser.bonds : []}
                        selectedBond={selectedBond}
                        selectBond={selectBond}
                        scheduleMeeting={goToScheduleProfile}
                        editProfile={goToEditProfile}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            ) : (
              <div>
                <LoadingIndicator />
              </div>
            )}
          </>
        ) : (
          <>
          {otherUser && user && otherUser._id === user._id ? (
            <>
            {otherUser ? (
                <div>
                <Row>
                <table>
                  <tbody>
                    <tr>
                      <td><button className="profileNav" id="1" style={{color:colors.navColor, width:"200px", height:"50px", backgroundColor:colors.primaryColor, border:"none"}} onClick={() => changeContent(1)}><h5>My Calendar</h5></button></td>
                      <td><button className="profileNav" id="2" style={{color:colors.textColor, width:"200px", height:"50px", backgroundColor:"transparent", border:"none"}} onClick={() => changeContent(2)}><h5>My Availability</h5></button></td>
                      <td><button className="profileNav" id="3" style={{color:colors.textColor, width:"200px", height:"50px", backgroundColor:"transparent", border:"none"}} onClick={() => changeContent(3)}><h5>My Bonds</h5></button></td>
                      <td><button className="profileNav" id="4" style={{color:colors.textColor, width:"200px", height:"50px", backgroundColor:"transparent", border:"none"}} onClick={() => changeContent(4)}><h5>My Profile</h5></button></td>
                    </tr>
                  </tbody>
                </table>
                </Row>
                  <Row>
                    {pageContent}
                  </Row>
                </div>
            ) : (
              <div>
                <LoadingIndicator />
              </div>
            )}
          </>
        ) : (
          <>
            {otherUser ? (
              <Row>
                <Col>
                  <ProfileCard u={otherUser} />
                </Col>
                <Col>
                  <BondsViewer
                    otherUser={otherUser}
                    user={user}
                    bonds={otherUser.bonds ? otherUser.bonds : []}
                    selectedBond={selectedBond}
                    selectBond={selectBond}
                    scheduleMeeting={goToScheduleProfile}
                    editProfile={goToEditProfile}
                  />
                </Col>
              </Row>
            ) : (
              <div>
                <LoadingIndicator />
              </div>
            )}
          </>
          
        )}
        </>)
        
        }
      </WidthContainer>
      
    </div>
  );
}
