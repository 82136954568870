import { ethers } from "ethers";
import { useEffect, useState } from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import "./App.css";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import { ScheduleNav } from "./components/ScheduleNav";
import { Web3Context } from "./contexts/Web3Context";
import EditProfile from "./pages/EditProfile";
import Profile from "./pages/Profile";
import Home from "./pages/Home";
import api from "./utils/api";
import firebaseUtils from "./utils/firebase";
import NoMatch from "./pages/NoMatch";
import ConfirmedSubscription from "./pages/ConfirmedSubscription";
import { SizedBox } from "./components/SizedBox";
import AuthTokenConfirm from "./pages/AuthTokenConfirm";
import SchedulePage from "./pages/Schedule";
import localstorage from "./utils/localstorage";
import { useAppColors } from "./utils/useAppColors";
import ScheduleDetailsPage from "./pages/ScheduleDetails";
import ScheduleListPage from "./pages/ScheduleList";

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import BondSnackbar from "./components/BondSnackbar";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import SubscriptionPage from "./pages/SubscriptionPage";
import MainRoutes from "./components/MainRoutes";
import Footer from "./components/Footer";
import { Col } from "react-bootstrap";
import config from "./utils/config";
import AltCoins from "./utils/abis/AltCoins.json";
import EventRouter from "./utils/abis/EventRouter.json";
import SocialMediaRouter from "./utils/abis/SocialMediaRouter.json";
import ShareBottomSheet from "./components/ShareBottomSheet";

const whitelistPaths = [
  "/e",
  "/c",
  "/s",
  "/s/details",
  // "/s/events",
  "/privacy-policy.pdf",
  "/d",
  "/d/privacy",
  "/p/pay",
  "tw",
];

function isWhiteListPath(pathname) {
  for (const w of whitelistPaths) {
    if (pathname === w) {
      return true;
    }
  }
  return false;
}

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [provider, setProvider] = useState(null);
  const [account, setAccount] = useState(undefined);
  const [signer, setSigner] = useState(undefined)
  const [user, setUser] = useState(null);
  const [jwt, setJwt] = useState(null);
  const [bonds, setBonds] = useState([])
  const [currentNetwork, setCurrentNetwork] = useState(null);
  const [theme, setTheme] = useState(undefined);
  const [isLoggingIn, setIsLoggingIn] = useState(true);
  const [showGlobalSnackbar, setShowGlobalSnackbar] = useState(false);
  const [globalSnackbarSeverity, setGlobalSnackbarSeverity] =
    useState("success");
  const [globalSnackbarMessage, setGlobalSnackbarMessage] = useState("");
  const [networkId, setNetworkId] = useState(undefined);
  const [error, setError] = useState("");
  const [socialMediaRouter, setSocialMediaRouter] = useState(undefined)
  const [eventRouter, setEventRouter] = useState(undefined)
  const [altCoins, setAltCoins] = useState(undefined)

  const [altCoinsBalanceOf, setAltCoinsBalanceOf] = useState(undefined)
  const [altCoinsMintCount, setAltCoinsMintCount] = useState(undefined)
  const [showSelfShare, setShowSelfShare] = useState(false)
  const [isChangingAccount, setIsChangingAccount] = useState(false)

  useEffect(() => {
    if (error === "Please Login Again") {
      logout();
    }
  }, [error]);

  function showSnackbar({ message, severity }) {
    setGlobalSnackbarMessage(message);
    setGlobalSnackbarSeverity(severity);
    setShowGlobalSnackbar(true);
    if (severity === "error") {
      setError(message);
    }
  }

  useEffect(() => {
    if (user) {
      localstorage.setLocalStorageUser(user);
    }
  }, [user]);

  useEffect(() => {
    if (jwt) {
      localstorage.setLocalStorageJWT(jwt);
    }
  }, [jwt]);

  useEffect(() => {
    if (theme) {
      localstorage.setLocalStorageTheme(theme);
      if (theme === "dark") {
        document.body.classList.add("dark-body");
      } else {
        document.body.classList.remove("dark-body");
      }
    }
  }, [theme]);

  async function getInitialData() {
    try {
      let response;
      const j = localstorage.getLocalStorageJwt();
      const t = localstorage.getLocalStorageTheme();
      if (!t) {
        setTheme("light");

        document.body.classList.remove("dark-body");
      } else {
        setTheme(t);
      }
      if (!j) {
        // response = await api.getSharedInfo();
        // if (response.error) {
        //   window.alert(response.error);
        //   return;
        // }
        setIsLoggingIn(false)
        return;
      }
      setIsLoggingIn(true);
      response = await api.getUserData2({ jwt: j, userId: undefined });
      if (!response || !response.user) {
        throw new Error("Please login again");
      }

      if (response.error) {
        setUser(undefined);
        setJwt(undefined);
        localstorage.setLocalStorageJWT(undefined);
        localstorage.setLocalStorageUser(undefined);
        firebaseUtils.log({
          eventName: "getUserData error",
          eventParams: { error: response.error },
        });

        throw new Error(response.error);
        // response = await api.getSharedInfo();W
        return;
      }

      setUser(response.user);
      setJwt(j);
      setBonds(response.bonds)
      firebaseUtils.log({
        eventName: "getInitialData getUserData",
        eventParams: { uid: response.user._id },
      });
      // response = await api.getSharedInfo();
      setIsLoggingIn(false);
    } catch (e) {
      console.log("InitialData Error: ", e);
      setUser(undefined);
      setJwt(undefined);
      localstorage.setLocalStorageJWT(undefined);
      localstorage.setLocalStorageUser(undefined);
      setIsLoggingIn(false);
      try {
        firebaseUtils.log({
          eventName: "error",
          eventParams: { error: e },
        });
      } catch (e) {}
      showSnackbar({ message: e, severity: "error" });
    }
  }

  useEffect(() => {
    getInitialData();
  }, []);

  async function setBlockchainData(_altCoins, _socialMediaRouter, _eventRouter) {
    setAltCoins(_altCoins);
    setSocialMediaRouter(_socialMediaRouter)
    setEventRouter(_eventRouter)
    console.log("setting blcokchain data")
    if (!_altCoins || !_socialMediaRouter || !_eventRouter) {
      console.log("contracts not found")
      return;
    }

    if (account) {
      const [b, mc] = await Promise.all([
        _altCoins.balanceOf(account),
        _altCoins.mintCount(account),
      ]);

      setAltCoinsBalanceOf(b);
      setAltCoinsMintCount(mc);
    }
  }

  const loadBlockchainData = async () => {
    if (provider) {
      console.log("loadBlockchainData")
      const { chainId } = await provider.getNetwork();
      setNetworkId(chainId);

   
      if(chainId !== 1){
        showSnackbar({
          message: "Please switch to the Ethereum Network",
          severity: "error",
        });
        return
      }

      try {
        const _altCoins = new ethers.Contract(
          config.contracts[chainId]["AltCoins"],
          AltCoins.abi,
          signer ? signer : provider
        );
        const _socialMediaRouter = new ethers.Contract(
          config.contracts[chainId]["SocialMediaRouter"],
          SocialMediaRouter.abi,
          signer ? signer : provider
        )
        const _eventRouter = new ethers.Contract(
          config.contracts[chainId]["EventRouter"],
          EventRouter.abi,
          signer ? signer : provider
        )
        setBlockchainData(_altCoins, _socialMediaRouter, _eventRouter);
      } catch (e) {
        console.log(e)
      }

      // if (chainId !== 1) {
      //   showSnackbar({
      //     message: "Please switch to the Ethereum Network",
      //     severity: "error",
      //   });
      // }
      // const networkId
    }
  };

  const loadWeb3 = async () => {
    if (typeof window.ethereum !== "undefined" && !account) {
      const _provider = new ethers.providers.Web3Provider(window.ethereum);
      setProvider(_provider);
      const { chainId } = await _provider.getNetwork();
      setNetworkId(chainId);

      if (chainId !== 1) {
        showSnackbar({
          message: "Please switch to the Ethereum Network",
          severity: "error",
        });
      }
      const _account = await _provider.getSigner();
      setSigner(_account)
      if (_account && _account.getAddress) {
        const a = await _account.getAddress();
        if (a !== account) {
          setAccount(a);
        }
      } else {
        showSnackbar({
          message: "Please connect with MetaMask",
          severity: "error",
        });
      }

      window.ethereum.on("accountsChanged", function (accounts) {
        if (accounts[0] !== account) {
          setAccount(accounts[0]);
        }
      });

      window.ethereum.on("chainChanged", (chainId) => {
        window.location.reload();
      });
    }
  };

  const web3Handler = async () => {
    if (provider) {
      await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const _account = await provider.getSigner();
      setSigner(_account)
      if (_account) {
        const a = await _account.getAddress();
        if (a !== account) {
          setAccount("web3Handler", a);
        }
      }
    }
  };

  const logout = async () => {
    setUser(undefined);
    setJwt(undefined);
    setAccount(undefined);
    localstorage.setLocalStorageJWT(undefined);
    localstorage.setLocalStorageUser(undefined);
    await api.logout({ logoutAll: false, jwt: jwt });
  };

  function getMessage({ loginUid }) {
    return `
    Welcome to Schedule.bond!

    Schedule.bond is a Milian Digital product, and you agree to Milian Digital's Terms of Service and Privacy Policy by signing this message.
    
    nonce: ${loginUid}
    `;
  }

  const login = async () => {
    if (provider) {
      // await web3Handler();
      if (!window.ethereum) {
        throw new Error("No crypto wallet found. Please install it.");
      }

      let ou = "";
      let editPath = "/e";
      let newPath = "/p/onboarding"

      if (
        location.pathname.length > 1 &&
        !whitelistPaths.includes(location.pathname)
      ) {
        ou = location.pathname.replace("/", "");
        editPath = editPath + "?ou=" + ou;
        newPath = newPath + "?ou=" + ou
      }

      const { chainId } = await provider.getNetwork();
      setNetworkId(chainId);

      if (chainId !== 1 && chainId !== 5) {
        showSnackbar({
          message: "Please switch to the Ethereum Network",
          severity: "error",
        });
        return;
      }
      setIsLoggingIn(true);
      const loginResp = await api.createLogin();
      setIsLoggingIn(false);

      if (loginResp.error) {
        showSnackbar({ message: loginResp.error, severity: "error" });
        firebaseUtils.log({
          eventName: "createLogin error",
          eventParams: { error: loginResp.error },
        });
        return;
      }
      firebaseUtils.log({
        eventName: "createLogin",
        eventParams: { uid: loginResp.login._id },
      });
      await window.ethereum.send("eth_requestAccounts");
      let _provider = new ethers.providers.Web3Provider(window.ethereum);
      const _signer = _provider.getSigner();
      setSigner(signer)
      const message = getMessage({ loginUid: loginResp.login._id });
      const signature = await _signer.signMessage(message);
      setIsLoggingIn(true);

      const resp = await api.login({ message: message, signature: signature });
      if (resp.error) {
        setUser(undefined);
        setJwt(undefined);
        setIsLoading(false);
        setIsLoggingIn(false);
        showSnackbar({ message: resp.error, severity: "error" });
        firebaseUtils.log({
          eventName: "login error",
          eventParams: { error: resp.error },
        });
        return;
      }

      setUser(resp.user);
      setJwt(resp.token);
      setBonds(resp.bonds)
      await firebaseUtils.signinWithFirebaseToken(resp.firebaseToken);
      if (_signer && _signer.getAddress) {
        const a = await _signer.getAddress();
        setAccount(a);
      }
      firebaseUtils.log({
        eventName: "login",
        eventParams: { uid: resp.user._id },
      });

      setIsLoggingIn(false);
      if(resp.isNewUser || (!resp.user.username && !resp.user.email) ){
        navigate(newPath)
        return
      }
      if (
        !resp.user.username ||
        !resp.user.email ||
        !resp.user.calendarPlatformSpecifics
      ) {
        if (
          !resp.user.username ||
          !resp.user.email ||
          !resp.user.calendarPlatformSpecifics
        ) {
          console.log("what")
          showSnackbar({
            message: "Please finish creating your account",
            severity: "success",
          });
        } else {
          showSnackbar({
            message: "Please enable calendar permissions on your synced email",
            severity: "success",
          });
        }
        navigate(editPath);
        return;
      }
      if (location.pathname.length < 2) {
        navigate("/" + resp.user.username);
      }
      return;
    } else {
      setIsLoggingIn(false);
      showSnackbar({
        message: "Please Connect an Ethereum Wallet via Metamask",
        severity: "error",
      });
    }
  };

  useEffect(() => {
    if (user) {
      if (
        location.pathname === "/e" ||
        location.pathname === "/g" ||
        location.pathname.includes("/g?")
        || location.pathname.includes("/onboarding")
      ) {
        return;
      }
      if (!user.username || !user.email || !user.calendarPlatformSpecifics) {
        if (!user.username || !user.email) {
          showSnackbar({
            message: "Please finish creating your account",
            severity: "success",
          });
        } else {
          showSnackbar({
            message: "Please enable calendar permissions on your synced email",
            severity: "success",
          });
        }
        let ou = "";
        let editPath = "/e";
        if (
          location.pathname.length > 1 &&
          !whitelistPaths.includes(location.pathname)
        ) {
          ou = location.pathname.replace("/", "");
          editPath = editPath + "?ou=" + ou;
        }
        // navigate(editPath);
        return;
      }
    }
  }, [location, user]);

  async function handleAccountChange(){
    setIsChangingAccount(true)

    if (account && user && account.toLowerCase() !== user.evmAddress.toLowerCase()) {
      await logout();
    } else {
      console.log("handling account change")
      await loadWeb3();
      await loadBlockchainData();

    }
    setIsChangingAccount(false)
  }

  useEffect(() => {
    if(!isChangingAccount){
      console.log("handle account change: ", account, " ", isChangingAccount)
      handleAccountChange()
    }
  }, [account]);

  return (
    <div className="App">
      <Web3Context.Provider
        value={{
          user,
          setUser,
          jwt,
          setJwt,
          bonds,
          setBonds,
          provider,
          account,
          theme,
          setTheme,
          login,
          logout,
          showSnackbar,
          networkId,
          setNetworkId,
          eventRouter,
          setEventRouter,
          socialMediaRouter,
          setSocialMediaRouter,
          altCoins,
          setAltCoins,
          setBlockchainData,
          signer,
          setSigner,
          isLoggingIn,
          setShowSelfShare,
          log: firebaseUtils.log,
        }}
      >
        {(location.pathname.length > 2 ||
          isWhiteListPath(location.pathname)) && (
          <>
            <ScheduleNav isLoggingIn={isLoggingIn} />
            {/* <SizedBox height={36} /> */}
          </>
        )}
        <Col
          className="d-flex flex-column justify-content-between align-items-between"
          style={{ height: "100%" }}
        >
          <Routes>
            <Route
              path="/*"
              element={<MainRoutes isLoggingIn={isLoggingIn} />}
            />

            <Route path="*" element={<NoMatch />} />
          </Routes>
          {location.pathname !== "/" && <Footer />}
        </Col>
        <BondSnackbar
          open={showGlobalSnackbar}
          setOpen={setShowGlobalSnackbar}
          message={globalSnackbarMessage}
          severity={globalSnackbarSeverity}
        />
        {user && <ShareBottomSheet open={showSelfShare} setOpen={setShowSelfShare} otherUser={user} />}
      </Web3Context.Provider>
    </div>
  );
}

function MyApp() {
  return (
    <div id={"app"} className="app2d">
      <Router>
        <App />
      </Router>
    </div>
  );
}

export default MyApp;
