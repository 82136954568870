import { useAppColors } from "../utils/useAppColors";
import { DynamicImage } from "../components/DynamicImage";
import { WidthContainer } from "../components/WidthContainer";
import { Col, Row } from "react-bootstrap";
import config from "../utils/config";
import styled from "styled-components";

const UserBannerWrapper = styled.div `
    width: 100%;
    height: 150px;
    background-color: ${(props) => props.backgroundColor};
`

export default function UserBanner({ user }) {
    const colors = useAppColors();
return(
    <UserBannerWrapper backgroundColor={colors.cardBackgroundColor}>
            <WidthContainer>
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <Col>
                                    <div style={{padding: "20px",  width:"450px", display: "flex"}}
                                        >
                                        <DynamicImage
                                            imageUrl={
                                            user.avatarUrl ? user.avatarUrl : config.defaultAvatarUrl
                                            }
                                            hasShadow={true}
                                            borderRadius={"5000px"}
                                            width={"100px"}
                                            height={"100px"}
                                        />
                                    
                                    <Col>
                                    <Row style={{paddingTop:"20px", paddingLeft:"40px",textAlign:"left", color:colors.primaryColor}}>
                                       <p>Welcome Back</p>
                                    </Row>
                                    <Row style={{paddingTop:"0px", paddingLeft:"40px",textAlign:"left"}}>
                                        <h2>{user}</h2>
                                    </Row>
                                    </Col>
                                    </div>
                                </Col>
                                    
                            </Col>
                        <Col>
                        <Row>
                            
                        </Row>
                        </Col>
                        </Row>
                    </Col>
                    <Col style={{textAlign:"right", paddingTop:"90px"}}>
                    </Col>
                </Row>
                </WidthContainer>
                </UserBannerWrapper>
)
}