import moment, { tz } from "moment";
import { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { BrowserView, MobileView } from "react-device-detect";
import Moment from "react-moment";
import { useSearchParams } from "react-router-dom";
import { DescriptionText } from "../components/DescriptionText";
import InfoCard from "../components/InfoCard";
import LoadingIndicator from "../components/LoadingIndicator";
import ProfileCard from "../components/ProfileCard";
import { SizedBox } from "../components/SizedBox";
import { TitleText } from "../components/TitleText";
import { WidthContainer } from "../components/WidthContainer";
import { Web3Context } from "../contexts/Web3Context";
import api from "../utils/api";

export default function ScheduleDetailsPage() {
  const { user, jwt, showSnackbar, log } = useContext(Web3Context);
  let [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isGettingInitialData, setIsGettingInitialData] = useState(false);
  const [eventUid, setEventUid] = useState(searchParams.get("e"));
  const [timeZone, setTimeZone] = useState(searchParams.get("tz"));
  const [guestEmail, setGuestEmail] = useState(searchParams.get("g"));
  const [event, setEvent] = useState(undefined);
  const [users, setUsers] = useState([]);
  const [otherUser, setOtherUser] = useState(undefined);

  async function getInitialData() {
    try {
      setIsLoading(true);
      setIsGettingInitialData(true);
      if (!eventUid) {
        showSnackbar({ message: "No Event", severity: "error" });
        setIsLoading(false);
        return;
      }

      const resp = await api.getEventDetails({
        eventUid: eventUid,
        guestEmail: guestEmail,
        jwt: jwt,
      });
      if (resp.error) {
        showSnackbar({ message: resp.error, severity: "error" });
        log({
          eventName: "getEventDetails error",
          eventParams: {
            userUid: user ? user._id : undefined,
            error: resp.error,
          },
        });
        setIsLoading(false);
        setIsGettingInitialData(false);
        return;
      }

      setEvent(resp.event);
      setUsers(resp.users);
      let ou;
      if (user) {
        ou = resp.users.filter((e) => e._id !== user._id)[0];
      } else {
        ou = resp.users[0];
      }
      setOtherUser(ou);
      setIsLoading(false);
      log({
        eventName: "getEventDetails",
        eventParams: {
          userUid: user ? user._id : undefined,
          eventUid: resp.event._id,
        },
      });
      setIsGettingInitialData(false);
    } catch (e) {
      log({
        eventName: "general error",
        eventParams: { userUid: user ? user._id : undefined, error: e },
      });
      showSnackbar({ message: e.message, severity: "error" });
      setIsLoading(false);
      setIsGettingInitialData(false);
    }
  }

  useEffect(() => {
    if ((user || guestEmail) && !isGettingInitialData) {
      getInitialData();
    }
    if (!user && !guestEmail) {
      showSnackbar({ message: "Please login", severity: "error" });
    }
  }, [user]);

  return (
    <>
      <BrowserView>
        <WidthContainer topPadding={36} width="90%">
          {isLoading ? (
            <>
              <LoadingIndicator />
            </>
          ) : (
            <Row>
              <Col>
                <TitleText>Your Meeting</TitleText>
                <SizedBox height={32} />
                <Row>
                  <Col>
                    <ProfileCard u={otherUser} />
                  </Col>
                  <Col>
                    <ScheduleDetailsColumn event={event} />
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </WidthContainer>
      </BrowserView>
      <MobileView>
        <WidthContainer topPadding={36} width="90%">
          {isLoading ? (
            <>
              <LoadingIndicator />
            </>
          ) : (
            <Row>
              <Col>
                <TitleText>Your Meeting</TitleText>
                <SizedBox height={24} />
                <Row>
                  <Col>
                    <ProfileCard u={otherUser} />
                  </Col>
                </Row>
                <SizedBox height={18} />
                <Row>
                  <ScheduleDetailsColumn event={event} timeZone={timeZone} />
                </Row>
              </Col>
            </Row>
          )}
        </WidthContainer>
      </MobileView>
    </>
  );
}

function ScheduleDetailsColumn({ event, timeZone }) {
  return (
    <>
      {event ? (
        <>
          <BrowserView>
            <Col>
              <DescriptionText>Start Time:</DescriptionText>
              <TitleText textAlign={"start"}>
                {
                  <Moment format="h:mm a dddd DD, MMMM yyyy" tz={timeZone}>
                    {timeZone ? moment(event.start).tz(timeZone) : event.start}
                  </Moment>
                }
              </TitleText>
              <SizedBox height={12} />
              <DescriptionText>End Time:</DescriptionText>
              <TitleText textAlign={"start"}>
                {
                  <Moment format="h:mm a dddd DD, MMMM yyyy" tz={timeZone}>
                    {timeZone ? moment(event.end).tz(timeZone) : event.end}
                  </Moment>
                }
              </TitleText>
              {/* <TitleText fontSize="24px">
          <Moment format="h:mm a">{event.start}</Moment>
        </TitleText>
        <DescriptionText textAlign={"center"}>
          <Moment format="dddd DD, MMMM yyyy">{event.start}</Moment>
        </DescriptionText> */}
            </Col>
          </BrowserView>
          <MobileView>
            <Col>
              <DescriptionText textAlign={"center"}>
                Start Time:
              </DescriptionText>
              <TitleText fontSize={"16px"} textAlign={"center"}>
                {
                  <Moment format="h:mm a dddd DD, MMMM yyyy" tz={timeZone}>
                    {timeZone
                      ? moment(event.start).tz(timeZone).toString()
                      : event.start}
                  </Moment>
                }
              </TitleText>
              <SizedBox height={16} />
              <DescriptionText textAlign={"center"}>End Time:</DescriptionText>
              <TitleText fontSize={"16px"} textAlign={"center"}>
                {
                  <Moment format="h:mm a dddd DD, MMMM yyyy" tz={timeZone}>
                    {timeZone
                      ? moment(event.end).tz(timeZone).toString()
                      : event.end}
                  </Moment>
                }
              </TitleText>
              {/* <TitleText fontSize="24px">
          <Moment format="h:mm a">{event.start}</Moment>
        </TitleText>
        <DescriptionText textAlign={"center"}>
          <Moment format="dddd DD, MMMM yyyy">{event.start}</Moment>
        </DescriptionText> */}
            </Col>
          </MobileView>
        </>
      ) : (
        <div>No Event</div>
      )}
    </>
  );
}
