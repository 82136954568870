import React, { useContext, useState } from "react";
import { SpinnerDiamond } from "spinners-react";
import styled from "styled-components";
import { Web3Context } from "../contexts/Web3Context";
import { useAppColors } from "../utils/useAppColors";
import { Center } from "./Center";
import { StandardShadow } from "./StandardShadow";
import { SizedBox } from "./SizedBox";

const PrimaryButtonWrapper = styled.button`
  width: 100%;
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "24px"};
  background-color: ${(props) => props.backgroundColor};
  border: 2px solid black;
  color: ${(props) => (props.color ? props.color : "white")};
  font-weight: bold;
  font-size: 20px;
  padding: ${(props) =>
    props.padding ? props.padding : "20px 40px 20px 40px"};
`;

const DisabledButtonWrapper = styled.button`
  width: 100%;
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "24px"};
  background-color: ${(props) => props.backgroundColor};
  border: 2px solid black;
  color: ${"black"};
  font-weight: bold;
  font-size: 20px;
  padding: ${(props) =>
    props.padding ? props.padding : "20px 40px 20px 40px"};
`;

export const PrimaryButton = ({
  text,
  onClick,
  disabled,
  requireUser,
  requireAddress,
  padding,
  borderRadius,
  isLoading,
  color,
  backgroundColor,
  Icon,
}) => {
  const { user, jwt, login } = useContext(Web3Context);
  const colors = useAppColors();
  //   const [showRequireUser, setShowRequireUser] = useState(false);
  //   const [showRequireAddress, setShowRequireAddress] = useState(false);

  async function safeOnClick() {
    if (requireUser) {
      if (!user || !user.username || !user.displayName || !jwt) {
        login()
        return;
      }
    }

    // if (requireAddress) {
    //   if (!user || !user.shippingAddress) {
    //     setShowRequireAddress(true);
    //     return;
    //   }
    // }
    if (isLoading) {
      return;
    }
    onClick();
  }

  if (disabled) {
    return (
      <div>
        <StandardShadow borderRadius={borderRadius ? borderRadius : "24px"}>
          <DisabledButtonWrapper
            borderRadius={borderRadius}
            disabled={disabled}
            padding={padding}
            backgroundColor={colors.textFieldColor}
          >
            {!isLoading ? (
              <>
                {Icon ? <Icon style={{ marginRight: "12px" }} /> : null}
                {text ? text : "Button"}
              </>
            ) : (
              <>
                <Center>
                  <SpinnerDiamond size={30} color={"#000000"} />
                </Center>
              </>
            )}
          </DisabledButtonWrapper>
        </StandardShadow>
      </div>
    );
  }
  return (
    <div>
      <StandardShadow borderRadius={borderRadius ? borderRadius : "24px"}>
        <PrimaryButtonWrapper
          disabled={disabled}
          onClick={safeOnClick}
          padding={padding}
          borderRadius={borderRadius}
          color={color ? color : "white"}
          backgroundColor={
            backgroundColor ? backgroundColor : colors.primaryColor
          }
        >
          {!isLoading ? (
            <>
              {Icon ? (
                <Icon
                  style={{ marginRight: "12px" }}
                  color={color ? color : "white"}
                />
              ) : null}
              {text ? text : "Button"}
            </>
          ) : (
            <>
              <Center>
                <SpinnerDiamond size={30} color={"#ffffff"} />
              </Center>
            </>
          )}
        </PrimaryButtonWrapper>
      </StandardShadow>
    </div>
  );
};
