import { useContext, useEffect, useState } from "react";
import { WidthContainer } from "../components/WidthContainer";
import { UploadImageWidget } from "../components/UploadImageWidget";
import { Web3Context } from "../contexts/Web3Context";
import { CustomTextField } from "../components/CustomTextInput";
import { PrimaryButton } from "../components/PrimaryButton";
import api from "../utils/api";
import { useNavigate, useSearchParams } from "react-router-dom";
import config from "../utils/config";
import firebaseUtils from "../utils/firebase";
import BondsEditor from "../components/BondsEditor";
import { Col, Row } from "react-bootstrap";
import { DynamicImage } from "../components/DynamicImage";
import { SizedBox } from "../components/SizedBox";
import { SecondaryButton } from "../components/SecondaryButton";
import utils from "../utils/utils";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import { TitleText } from "../components/TitleText";
import { Center } from "../components/Center";
import { FlatButton } from "../components/FlatButton";
import AccountActivationSheet from "../components/AccountActivationSheet";
import LoadingIndicator from "../components/LoadingIndicator";
import IconButton from "../components/IconButton";
import { FaArrowLeft } from "react-icons/fa";
import EmailModal from "../components/EmailModal";
import { SubtitleText } from "../components/SubtitleText";
import { useAppColors } from "../utils/useAppColors";
import * as React from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { StandardShadow } from "../components/StandardShadow";
import styled from "styled-components";
import moment from "moment";
import InfoCard from "../components/InfoCard";
import CustomCheckbox from "../components/CustomCheckbox";
import { DescriptionText } from "../components/DescriptionText";
import { VerifyTwitterButton } from "../components/VerifyTwitterButton";
import { CustomInputWithSideSelector } from "../components/CustomInputWithSideSelector";
import { ethers } from "ethers";
import imageCompression from 'browser-image-compression';
import { Banner } from "../components/Banner";
const { detect } = require("detect-browser");
const { BondMaxOption } = require("./SubscriptionPage");

const CustomTimeSelectorWrapper = styled.div`
  width: 100%;
  border-radius: 24px;
  background-color: white;
  border-left: 2px solid ${(props) => props.borderColor};
  border-top: 2px solid ${(props) => props.borderColor};
  border-right: 2px solid ${(props) => props.borderColor};
  border-bottom: 2px solid ${(props) => props.borderColor};
  color: ${(props) => props.color};
  font-size: 18px;
  font-weight: bold;
  padding: 10px 20px 10px 20px;
  disabled: ${(props) => (props.disabled ? props.disabled : false)};
`;

const TimeInputNameWrapper = styled.div`
  background-color: ${(props) => props.backgroundColor};
  position: relative;
  top: 24px;
  left: 24px;
  border-radius: 8px;
  padding-right: 4px;
  padding-left: 4px;
`;
const TimeInputName = styled.div`
  color: ${(props) => props.color};
  font-size: 14px;
  margin-bottom: 12px;
  font-weight: bold;
  text-align: start;
`;

function SubscriberFeatures() {
  const colors = useAppColors();
  const navigate = useNavigate();

  async function goToSubscriptionPage() {
    navigate("/bm");
  }

  return (
    <>
      <InfoCard padding={"24px"}>
        <SubtitleText textAlign={"start"} color={colors.titleColor}>
          Bond.MAX Features
        </SubtitleText>
        <PrimaryButton
          onClick={goToSubscriptionPage}
          text={"Subscribe to Bond.MAX"}
        />
      </InfoCard>
    </>
  );
}

function TimeInput({ name, value, handleChange }) {
  const colors = useAppColors();
  return (
    <div>
      <div className="d-flex">
        <TimeInputNameWrapper backgroundColor={colors.inputNameBackgroundColor}>
          <TimeInputName color={"black"}>{name}</TimeInputName>
        </TimeInputNameWrapper>
      </div>
      <StandardShadow borderRadius={"24px"}>
        <CustomTimeSelectorWrapper>
          <TimePicker
            value={value}
            onChange={handleChange}
            renderInput={(params) => (
              <TextField
                inputProps={{
                  fontFamily: "Poppins",
                }}
                InputProps={{
                  disableUnderline: true,
                  border: "none",
                  fontFamily: "Poppins",
                }}
                sx={{
                  "& .MuiInputLabel-root": {
                    color: "#bd5bfe",
                    fontFamily: "Poppins",
                  }, //styles the label
                  "& .MuiOutlinedInput-root": {
                    "& > fieldset": {
                      borderColor: "transparent",
                    },
                    "&:hover fieldset": {
                      borderColor: "transparent",
                    },
                    "& textarea": {
                      fontFamily: "Poppins !important",
                    },
                  },
                }}
                style={{
                  border: "none",
                  outline: "none",
                  width: "100%",
                }}
                {...params}
              />
            )}
          />
        </CustomTimeSelectorWrapper>
      </StandardShadow>
    </div>
  );
}


function AlertsSection({
  phoneNumber,
  setPhoneNumber,
  hasTextAlerts,
  setHasTextAlerts,
  hasWebhookAlerts,
  setHasWebookAlerts,
  webhook,
  setWebhook,
  subscriptionStatus,
  goToSubscriptionPage,
}) {
  const colors = useAppColors();

  return (
    <Col>
      <Row>
        <SubtitleText textAlign={"start"} color={colors.titleColor}>
          Bond.MAX Premium Features
        </SubtitleText>
      </Row>
      <SizedBox height={16} />
      {subscriptionStatus === "active" && (
        <>
          <Row>
            <CustomCheckbox
              value={hasTextAlerts}
              setValue={setHasTextAlerts}
              name={"Receive Text Alerts"}
            />
          </Row>
          {hasTextAlerts && (
            <Row>
              <CustomTextField
                name="Phone Number"
                value={phoneNumber}
                setValue={setPhoneNumber}
                placeholder={"ex 12345678"}
                disabled={subscriptionStatus !== "active"}
              />
            </Row>
          )}
          <SizedBox height={24} />
          {/* <Row>
          <CustomCheckbox value={hasWebhookAlerts} setValue={setHasWebookAlerts} name={"Receive Webhook Alerts"} />     
      </Row> */}
          {hasWebhookAlerts && (
            <Row>
              <CustomTextField
                name="Webhook"
                value={webhook}
                setValue={setWebhook}
                placeholder={"https://api.example.com/bond_webhook"}
              />
            </Row>
          )}
          <Row>
            <SizedBox height={16} />
            <SecondaryButton
              text={"Update Subscription"}
              onClick={goToSubscriptionPage}
            />
          </Row>
        </>
      )}
      {subscriptionStatus !== "active" && (
        <>
          <Row>
            <Col xs={4}>
              <DescriptionText>
                Unlock the premium features of Schedule.bond with Bond.MAX! This
                subscription allows you to be alerted via text to meetings,
                amount other awesome features!
              </DescriptionText>
            </Col>
            <Col>
              <BondMaxOption onClick={goToSubscriptionPage} />
            </Col>
          </Row>
          <SizedBox height={24} />

          <PrimaryButton text={"Get Bond.MAX"} onClick={goToSubscriptionPage} />
        </>
      )}

      <SizedBox height={16} />
    </Col>
  );
}

function OfficeHoursSection({ dayStart, setDayStart, dayEnd, setDayEnd }) {
  const colors = useAppColors();
  const [value, setValue] = useState(new Date("2014-08-18T21:11:54"));

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  return (
    <Col>
      <Row>
        <SubtitleText textAlign={"start"} color={colors.titleColor}>
          Office Hours
        </SubtitleText>
      </Row>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Row>
          <Col>
            <TimeInput
              name={"From"}
              value={dayStart}
              handleChange={setDayStart}
            />
          </Col>
          <Col>
            <TimeInput name={"To"} value={dayEnd} handleChange={setDayEnd} />
          </Col>
        </Row>
      </LocalizationProvider>
    </Col>
  );
}

export default function EditProfile() {
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const [otherUsername, setOtherUsername] = useState(searchParams.get("ou"));
  const { user, setUser, jwt, showSnackbar, log, eventRouter, socialMediaRouter, altCoins, setBlockchainData } = useContext(Web3Context);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(undefined);
  const [username, setUsername] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [bio, setBio] = useState("");
  const [email, setEmail] = useState("");
  const [bonds, setBonds] = useState([]);
  const [editingBond, setEditingBond] = useState({});
  const [previousBond, setPreviousBond] = useState({});
  const [status, setStatus] = useState(undefined);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [accountActivationOpen, setAccountActivationOpen] = useState(false);
  const browser = detect();

  const [ownedNfts, setOwnedNfts] = useState([]);
  const [ownedNftContractData, setOwnedNftContractData] = useState([]);
  const [externalPopup, setExternalPopup] = useState(null);
  const [dayStart, setDayStart] = useState(undefined);
  const [dayEnd, setDayEnd] = useState(undefined);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [hasTextAlerts, setHasTextAlerts] = useState(false);
  const [webhook, setWebhook] = useState("");
  const [hasWebhookAlerts, setHasWebhookAlerts] = useState(false);
  const [twitterAddress, setTwitterAddress] = useState("")
  const [pendingAmounts, setPendingAmounts] = useState(undefined)
  const [twitterLinkedStatus, setTwitterLinkedStatus] = useState(false)

  async function getServiceAddress() {
    if (!user) {
      return
    }
    if (!user.twitterUserId) {
      return
    }

    if (!socialMediaRouter) {
      return
    }

    const address = await socialMediaRouter.getAddress("twitter", user.twitterUserId)
    console.log("address: ", address)
    if (address !== config.zeroAddress) {
      setTwitterAddress(address)
      setTwitterLinkedStatus(true)
    }
  }

  async function getPendingAmounts() {
    if (!user) {
      return
    }
    if (!user.twitterUserId) {
      return
    }

    const oaResp = await api.getOwedAmounts({ serviceId: "twitter", userId: user.twitterUserId, jwt: jwt })
    console.log("oaResp: ", oaResp)
    setPendingAmounts(oaResp.owedAmounts)
  }

  useEffect(() => {
    getPendingAmounts()
    getServiceAddress()
  }, [user])

  const exampleBonds = [
    [
      { type: "nft-ownership", tokenAddress: "0x123", quantity: 5 },
      { type: "erc20-ownership", tokenAddress: "0x456", quantity: 50000 },
    ],
    [
      {
        type: "erc20-payment",
        tokenAddress: "0.981",
        quantity: 1000000,
        rates: [
          {
            time: "15 min",
            quantity: 100000,
          },
          { time: "30 min", quantity: 500000 },
        ],
      },
    ],
    [
      {
        type: "eth-payment",
        quantity: 1000000,
        rates: [
          {
            time: "15 min",
            quantity: 100000,
          },
          { time: "30 min", quantity: 500000 },
        ],
      },
    ],
  ];

  async function connectGoogle(e) {
    try {
      setShowEmailModal(false);
      setIsLoading(true);
      const w = 1200;
      const h = 600;
      const left = window.screenX + (window.outerWidth - w) / 2;
      const top = window.screenY + (window.outerHeight - h) / 2.5;
      const title = `Schedule.bond CONNECT TO GOOGLE`;
      const popup = window.open(
        "",
        title,
        `width=${w},height=${h},left=${left}`
      );
      const getUrlResp = await api.getAuthUrl({ jwt: jwt, platform: "google" });
      if (getUrlResp.error) {
        showSnackbar({ message: getUrlResp.error.toString(), severity: "error" });
        log({
          eventName: "getAuthUrl error",
          eventParams: {
            userUid: user ? user._id : undefined,
            error: getUrlResp.error,
          },
        });
        setIsLoading(false);
        return;
      }
      log({
        eventName: "getAuthUrl",
        eventParams: {
          userUid: user ? user._id : undefined,
        },
      });
      const url = getUrlResp.url;

      const urlSearchParams = new URLSearchParams(url);
      const params = Object.fromEntries(urlSearchParams.entries());
      // Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
      let base = url.split("?")[0];
      let client_id = params.client_id; // "some_value"
      let rearragnedParams = "?client_id=" + client_id;
      for (const key of Object.keys(params)) {
        if (key !== "client_id") {
          rearragnedParams = rearragnedParams + `&${key}=${params[key]}`;
        }
      }
      popup.location = url;
      // popup.location = base + rearragnedParams;
      // showSnackbar({ message: url, severity: "success" });
      // const popup = window.open(
      //   url,
      //   title,
      //   `width=${w},height=${h},left=${left}`
      // );
      setExternalPopup(popup);
    } catch (e) {
      log({
        eventName: "general error",
        eventParams: {
          userUid: user ? user._id : undefined,
          error: e,
        },
      });
      showSnackbar({ message: e.message, severity: "error" });
    }
  }
  useEffect(() => {
    if (!externalPopup) {
      return;
    }

    const timer = setInterval(async () => {
      try {
        if (!externalPopup) {
          timer && clearInterval(timer);
          return;
        }
        const currentUrl = externalPopup.location.href;
        if (!currentUrl) {
          return;
        }
        const searchParams = new URL(currentUrl).searchParams;
        const code = searchParams.get("code");
        if (code) {
          externalPopup.close();
          const r = await api.processAuthCode({
            authCode: code,
            platform: "google",
            jwt: jwt,
          });

          if (r.error) {
            showSnackbar({ message: r.error.toString(), severity: "error" });
            log({
              eventName: "processAuthCode error",
              eventParams: {
                userUid: user ? user._id : undefined,
                error: r.error,
              },
            });
            setIsLoading(false);
          } else {
            log({
              eventName: "processAuthCode",
              eventParams: {
                userUid: user ? user._id : undefined,
              },
            });
            showSnackbar({
              message: "Successfully Synced Email!",
              severity: "success",
            });
            setUser(r.user);
            setEmail(r.user.email);
          }
          setExternalPopup(null);
          setIsLoading(false);
          timer && clearInterval(timer);
        }
      } catch (e) { }
    }, 500);
  }, [externalPopup]);

  async function addBond() {
    const nbl = bonds.length;
    setEditingBond({ bond: [], bondIndex: nbl + 1 });
  }

  async function linkTwitterToRouter() {
    try {
      setIsLoading(true)
      if (!eventRouter) {
        throw Error("Connect to Ethereum")
      }
      const isAddress = await ethers.utils.isAddress(twitterAddress)
      if (!isAddress) {
        throw Error("Address is not valid")
      }

      const sigResponse = await api.generateSocialMediaSignature({ platform: "twitter", address: twitterAddress, jwt: jwt })
      if (sigResponse.error) {
        throw Error(sigResponse.error)
      }
      const sig = sigResponse.sig
      const _signature = sig.signature
      const _address = sig.address
      const _nonce = sig.nonce
      const _serviceId = sig.serviceId
      const _userId = sig.serviceUserId
      let tx
      if (pendingAmounts && pendingAmounts.length > 0) {
        const tokenAddresses = pendingAmounts.map(a => a.token.address)
        console.log("token addresses: ", tokenAddresses)
        tx = await eventRouter.addAccountAndWithdrawl(_signature, _address, _serviceId, _userId, _nonce, tokenAddresses);
      } else {
        tx = await socialMediaRouter.addAccount(_signature, _address, _serviceId, _userId, _nonce);
      }

      const receipt = await tx.wait();
      log({
        eventName: "AddAccount",
        eventParams: {
          userUid: user ? user._id : undefined,
          accountAddress: _address,
          serviceId: _serviceId,
          userId: _userId,
          nonce: _nonce,
        },
      });
      showSnackbar({ message: "Successfully added account!", severity: "success" });
      setIsLoading(false)
      getPendingAmounts()
      await setBlockchainData(altCoins, socialMediaRouter, eventRouter);
    }
    catch (e) {
      setIsLoading(false);
      log({
        eventName: "general error",
        eventParams: {
          userUid: user ? user._id : undefined,
          error: e,
        },
      });
      showSnackbar({ message: e.message, severity: "error" });
      return;
    }
  }

  async function saveBond() {
    let nb = [...bonds];
    const nbl = nb.length;
    editingBond.bondIndex = nbl + 1;
    console.log(nb);
    
    if (!editingBond.bond || editingBond.bond.length === 0) {
      setEditingBond({});
    } else {
      nb.push(editingBond);
      setBonds(nb);
      setEditingBond({});
    }
  }

  async function revertBond() {
    let nb = [...bonds];
    const nbl = nb.length;
    editingBond.bondIndex = nbl + 1;
    if (!previousBond.bond || previousBond.bond.length === 0) {
      setEditingBond({});
    } else {
      nb.push(previousBond);
      setBonds(nb);
      setEditingBond({});
    }
  }

  async function addRule({ bondIndex, rule }) {
    editingBond.bond = [...editingBond.bond, { rule: rule }];
    setEditingBond(editingBond);
  }

  async function setRule({ bondIndex, ruleIndex, rule }) {
    let b = [...bonds];
    b[bondIndex].bond[ruleIndex].rule = rule;
    setBonds(b);
  }

  async function removeRule({ ruleIndex }) {
    let r = [...editingBond.bond];
    r.splice(ruleIndex, 1);
    editingBond.bond = r;
    setEditingBond(editingBond);
  }

  async function selectBond({ bondIndex }) {
    let b = [...bonds];
    const bo = { bond: b[bondIndex].bond, bondIndex: b.length };
    setEditingBond(bo);
    setPreviousBond(bo);
    b.splice(bondIndex, 1);
    setBonds(b);
  }

  async function removeBond() {
    setEditingBond({});
  }

  useEffect(() => {
    if (email) {
      if (!dayStart || !dayEnd) {
        try {
          let startDay = moment();
          let endDay = moment();
          let startHour =
            user.dayStart && user.dayStart.hour ? user.dayStart.hour : 9;
          let startMinute =
            user.dayStart && user.dayStart.minute ? user.dayStart.minute : 0;
          let startSecond =
            user.dayStart && user.dayStart.second ? user.dayStart.second : 0;

          let endHour = user.dayEnd && user.dayEnd.hour ? user.dayEnd.hour : 17;
          let endMinute =
            user.dayEnd && user.dayEnd.minute ? user.dayEnd.minute : 0;
          let endSecond =
            user.dayEnd && user.dayEnd.second ? user.dayEnd.second : 0;

          startDay
            .startOf("day")
            .set("hour", startHour)
            .set("minute", startMinute)
            .set("second", startSecond);
          endDay
            .startOf("day")
            .set("hour", endHour)
            .set("minute", endMinute)
            .set("second", endSecond);
          setDayStart(startDay.toDate());
          setDayEnd(endDay.toDate());
        } catch (e) {
          let startDay = moment();
          let endDay = moment();
          let startHour = 9;
          let endHour = 17;

          startDay.startOf("day").set("hour", startHour);
          endDay.startOf("day").set("hour", endHour);
          setDayStart(startDay.toDate());
          setDayEnd(endDay.toDate());
        }
      }
    }
  }, [email]);

  async function setInitialData() {
    if (!user) {
      return;
    }
    setUsername(user.username ? user.username : "");
    setDisplayName(user.displayName ? user.displayName : "");
    setBio(user.bio ? user.bio : "");
    setEmail(user.email ? user.email : "");
    setSelectedImage(user.avatarUrl ? user.avatarUrl : config.defaultAvatarUrl);
    setBonds(user.bonds ? user.bonds : []);
    setPhoneNumber(user.phoneNumber ? user.phoneNumber : "");
    setWebhook(
      user.webhooks && user.webhooks.length > 0 ? user.webhooks[0] : ""
    );
    setHasTextAlerts(user.textAlertStatus === "active" ? true : false);
    setHasWebhookAlerts(user.webhookStatus === "active" ? true : false);

    let s = user.status ? user.status : "active";
    setStatus(s);
    // searchNfts({ q: "" });
    const nftsResp = await api.getUserNfts({ jwt: jwt });
    if (nftsResp.error) {
      log({
        eventName: "getUserNfts error",
        eventParams: {
          userUid: user ? user._id : undefined,
          error: nftsResp.error,
        },
      });
      showSnackbar({ message: nftsResp.error.toString(), severity: "error" });
    }
    log({
      eventName: "getUserNfts",
      eventParams: {
        userUid: user ? user._id : undefined,
      },
    });
    setOwnedNfts(nftsResp.nfts.ownedNfts);
    attachImages(nftsResp.nfts.ownedNfts, nftsResp.nftContracts);
  }

  function attachImages(_nfts, _ownedNftsContracts) {
    if (!_nfts || !_ownedNftsContracts) {
      return;
    }
    for (const o of _ownedNftsContracts) {
      let n = _nfts.filter((n) => n.contract.address === o.address);
      if (n.length > 0) {
        let i = n ? utils.formatIPFStoGateway(n[0].metadata.image) : undefined;
        o.image = i;
        o.logoURI = i;
      }
    }
    setOwnedNftContractData(_ownedNftsContracts);
  }

  async function disconnectAuth() {
    try {
      setShowEmailModal(false);
      setIsLoading(true);
      const response = await api.disconnectAuth({ jwt });
      if (response.error) {
        const e = response.error;
        setIsLoading(false);
        log({
          eventName: "disconnectAuth error",
          eventParams: {
            userUid: user ? user._id : undefined,
            error: e,
          },
        });
        showSnackbar({ message: e.toString(), severity: "error" });
        return;
      }
      setUser(response.user);
      setEmail(undefined);
      showSnackbar({
        message: "Successfully Disconnected Email",
        severity: "success",
      });
      setIsLoading(false);
      return;
    } catch (e) {
      setIsLoading(false);
      log({
        eventName: "general error",
        eventParams: {
          userUid: user ? user._id : undefined,
          error: e,
        },
      });
      showSnackbar({ message: e.message, severity: "error" });
      return;
    }
  }

  async function updateUser() {
    try {
      // if (!user.email) {
      //   showSnackbar({
      //     message: "Please Sync Email",
      //     severity: "error",
      //   });
      //   return;
      // }
      // if (!user.calendarPlatformSpecifics) {
      //   showSnackbar({
      //     message:
      //       "Please resync email and allow Schedule.bond access to your calendar",
      //     severity: "error",
      //   });
      //   return;
      // }
      setIsLoading(true);
      let newUser = { ...user };
      newUser.username = username;
      newUser.displayName = displayName;
      newUser.bio = bio;
      newUser.email = email;
      newUser.bonds = bonds;
      newUser.phoneNumber = phoneNumber;
      newUser.webhook = webhook;
      newUser.hasTextAlerts = hasTextAlerts;
      newUser.hasWebhookAlerts = hasWebhookAlerts;

      if (dayStart) {
        const startHours = dayStart.getHours();
        const startMinutes = dayStart.getMinutes();
        const startSeconds = dayStart.getSeconds();
        let ds = {
          hour: startHours,
          minute: startMinutes,
          second: startSeconds,
        };
        newUser.dayStart = ds;
      }

      if (dayEnd) {
        const endHours = dayEnd.getHours();
        const endMinutes = dayEnd.getMinutes();
        const endSeconds = dayEnd.getSeconds();
        let de = {
          hour: endHours,
          minute: endMinutes,
          second: endSeconds,
        };
        newUser.dayEnd = de;
      }

      let avatarUrl;
      let compressedFile
      if (selectedImage !== user.avatarUrl) {
        if (
          selectedImage &&
          (typeof selectedImage !== "string" ||
            !selectedImage.includes("https://"))
        ) {
          const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true
          }
          compressedFile = await imageCompression(selectedImage, options);
          const avatarUploadResponse = await firebaseUtils.updateAvatarImage(
            compressedFile,
            user.evmAddress
          );
          if (avatarUploadResponse.error) {
            showSnackbar({
              message: avatarUploadResponse.error,
              severity: "error",
            });
            setIsLoading(false);
            return;
          }
          avatarUrl = avatarUploadResponse.downloadUrl;
        } else {
          
          avatarUrl = compressedFile;
        }
        try {
          const fbResp = await api.createFbToken({ jwt: jwt });

          await firebaseUtils.signinWithFirebaseToken(fbResp.firebaseToken);
        } catch (e) {
          console.log(e);
        }
        const avResponse = await api.updateAvatar({
          url: avatarUrl,
          platform: "web",
          isDelete: selectedImage === undefined,
          jwt: jwt,
        });
        if (avResponse.error) {
          log({
            eventName: "updateAvatar error",
            eventParams: {
              userUid: user ? user._id : undefined,
              error: avResponse.error,
            },
          });
          showSnackbar({ message: avResponse.error, severity: "error" });
          setIsLoading(false);
          return;
        }
        log({
          eventName: "updateAvatar",
          eventParams: {
            userUid: user ? user._id : undefined,
          },
        });
        setUser(avResponse.user);
      }

      const response = await api.updateUserData({ user: newUser, jwt: jwt });
      if (response.error) {
        showSnackbar({ message: response.error.toString(), severity: "error" });
        log({
          eventName: "updateUserData error",
          eventParams: {
            userUid: user ? user._id : undefined,
            error: response.error,
          },
        });
        setIsLoading(false);
        return;
      }
      setUser(response.user);
      showSnackbar({
        message: "Successfully updated profile!",
        severity: "success",
      });
      log({
        eventName: "updateUserData",
        eventParams: {
          userUid: user ? user._id : undefined,
        },
      });
      navigate("/" + (otherUsername ? otherUsername : response.user.username));
    } catch (e) {
      log({
        eventName: "general error",
        eventParams: {
          userUid: user ? user._id : undefined,
          error: e.message,
        },
      });
      showSnackbar({ message: e.message, severity: "error" });
      setIsLoading(false);
    }
  }

  async function linkTwitter() {
    try {
      setIsLoading(true);
      const response = await api.createTwitterAuthUrl({ jwt: jwt });
      if (response.error) {
        throw Error(response.error);
      }
      window.location.href = response.authUrl;

      console.log("response: ", response);
    } catch (e) {
      setIsLoading(false);
      log({
        eventName: "general error",
        eventParams: {
          userUid: user ? user._id : undefined,
          error: e,
        },
      });
      showSnackbar({ message: e.message, severity: "error" });
      return;
    }
  }

  async function goToProfile() {
    navigate("/" + user.username);
  }

  async function goToSubscriptionPage() {
    navigate("/bm");
  }

  async function subscribeToBondMax() {
    try {
      setIsLoading(true);
      const paymentLinkResp = await api.createPaymentLink({ jwt });
      if (paymentLinkResp.error) {
        setIsLoading(false);
        showSnackbar({ message: paymentLinkResp.error.toString(), severity: "error" });
        log({
          eventName: "createPaymentLink error",
          eventParams: {
            userUid: user ? user._id : undefined,
            error: paymentLinkResp.error,
          },
        });
        return;
      }
      log({
        eventName: "createPaymentLink",
        eventParams: {
          userUid: user ? user._id : undefined,
        },
      });
      setIsLoading(false);
      window.location.href = paymentLinkResp.paymentLink;
    } catch (e) {
      setIsLoading(false);
      log({
        eventName: "general error",
        eventParams: {
          userUid: user ? user._id : undefined,
          error: e,
        },
      });
      showSnackbar({ message: e.message, severity: "error" });
      return;
    }
  }

  useEffect(() => {
    if (browser && browser.name) {
      if (browser.name === "firefox") {
        showSnackbar({
          message: "Please use Google Chrome to sync email",
          severity: "error",
        });
      }
    }
    setInitialData();
  }, []);

  return (
    <>
      <>
        {user ? (
          <>
            <Banner text={"You need to sync an email for your account to appear to others"} show={!user.email || !user.calendarPlatformSpecifics} />
            {isMobile ? (
              <WidthContainer topPadding={36}>
                <Row>
                  <Col>
                  {user &&
                      user.username &&
                      user.email &&
                      user.calendarPlatformSpecifics && (
                        <Row>
                          <IconButton
                            Icon={FaArrowLeft}
                            onClick={() => {
                              navigate("/" + user.username);
                            }}
                          />
                        </Row>
                      )}
                      <SizedBox height={8}/>
                    <TitleText>Please Sync Email on a Desktop</TitleText>
                    <UploadImageWidget
                      selectedImage={selectedImage}
                      setImage={setSelectedImage}
                    />
                    <CustomTextField
                      value={username}
                      setValue={setUsername}
                      name="Username"
                      placeholder="Enter username"
                    />
                    <CustomTextField
                      value={displayName}
                      setValue={setDisplayName}
                      name="Display name"
                      placeholder="Enter display name"
                    />
                    <CustomTextField
                      type="textarea"
                      fontSize="14px"
                      name="Bio"
                      placeholder={"Enter a bio"}
                      rows="4"
                      value={bio}
                      setValue={setBio}
                    />
                    <SizedBox height={32} />

                    <VerifyTwitterButton
                      text={
                        user.twitterPlatformSpecifics
                          ? user.twitterPlatformSpecifics.data.username
                          : "Link your Twitter"
                      }
                      onClick={linkTwitter}
                      isLoading={isLoading}
                    />
                    <SizedBox height={32} />
                    <PrimaryButton
                      text="Save"
                      onClick={updateUser}
                      isLoading={isLoading}
                    />
                    <SizedBox height={24} />
                    <FlatButton
                      onClick={() => {
                        setAccountActivationOpen(true);
                      }}
                    >
                      {user.status === "inactive"
                        ? "Activate account"
                        : "Disable account"}
                    </FlatButton>
                  </Col>
                </Row>

              </WidthContainer>
            ) : (
              <WidthContainer width={"90%"} topPadding={36}>
                <SizedBox height={24} />

                <Row>
                  <Col md={4}>
                    {user &&
                      user.username &&
                      user.email &&
                      user.calendarPlatformSpecifics && (
                        <Row>
                          <IconButton
                            Icon={FaArrowLeft}
                            onClick={() => {
                              navigate("/" + user.username);
                            }}
                          />
                        </Row>
                      )}
                    <UploadImageWidget
                      selectedImage={selectedImage}
                      setImage={setSelectedImage}
                    />
                    {/* <CustomTextField
              value={email ? email : "No Email Synced Yet"}
              setValue={setEmail}
              name="Email"
              placeholder="Enter email"
              disabled={true}
            /> */}
                    <SecondaryButton
                      text={email ? email : "+ Sync Email"}
                      onClick={() => {
                        setShowEmailModal(true);
                      }}
                      disabled={isLoading}
                      isLoading={isLoading}
                    />
                    <CustomTextField
                      value={username}
                      setValue={setUsername}
                      name="Username"
                      placeholder="Enter username"
                    />
                    <CustomTextField
                      value={displayName}
                      setValue={setDisplayName}
                      name="Display name"
                      placeholder="Enter display name"
                    />
                    <CustomTextField
                      type="textarea"
                      fontSize="14px"
                      name="Bio"
                      placeholder={"Enter a bio"}
                      rows="4"
                      value={bio}
                      setValue={setBio}
                    />
                    <SizedBox height={32} />

                    <VerifyTwitterButton
                      text={
                        user.twitterPlatformSpecifics
                          ? user.twitterPlatformSpecifics.data.username
                          : "Link your Twitter"
                      }
                      onClick={linkTwitter}
                      isLoading={isLoading}
                    />
                    <SizedBox height={32} />
                    <PrimaryButton
                      text="Save"
                      onClick={updateUser}
                      isLoading={isLoading}
                    />
                    <SizedBox height={24} />
                    {/* <FlatButton
                      onClick={() => {
                        navigate("/u/payments")
                      }}
                    >
                      Go To Payments
                    </FlatButton>
                    <SizedBox height={24} /> */}
                    <FlatButton
                      onClick={() => {
                        setAccountActivationOpen(true);
                      }}
                    >
                      {user.status === "inactive"
                        ? "Activate account"
                        : "Disable account"}
                    </FlatButton>
                  </Col>
                  <Col>
                    <BondsEditor
                      addBond={addBond}
                      removeBond={removeBond}
                      saveBond={saveBond}
                      revertBond={revertBond}
                      addRule={addRule}
                      bonds={bonds}
                      editingBond={editingBond}
                      setRule={setRule}
                      selectBond={selectBond}
                      removeRule={removeRule}
                      nfts={ownedNfts}
                      ownedNftContractData={ownedNftContractData}
                    />
                    <SizedBox height={24} />
                    {user.email && (
                      <InfoCard padding={"24px"}>
                        <OfficeHoursSection
                          dayStart={dayStart}
                          dayEnd={dayEnd}
                          setDayStart={setDayStart}
                          setDayEnd={setDayEnd}
                        />
                      </InfoCard>
                    )}
                    <SizedBox height={24} />
                    <PrimaryButton
                      text="Save"
                      onClick={updateUser}
                      isLoading={isLoading}
                    />
                    <SizedBox height={36} />

                    {/* <InfoCard padding={"24px"}>
                      <SocialMediaRouterSection twitterAddress={twitterAddress} setTwitterAddress={setTwitterAddress} linkTwitterToRouter={linkTwitterToRouter} pendingAmounts={pendingAmounts} twitterLinkedStatus={twitterLinkedStatus} />
                    </InfoCard>
                    <SizedBox height={24} /> */}

                    <InfoCard padding={"24px"}>
                      <AlertsSection
                        phoneNumber={phoneNumber}
                        setPhoneNumber={setPhoneNumber}
                        hasTextAlerts={hasTextAlerts}
                        setHasTextAlerts={setHasTextAlerts}
                        webhook={webhook}
                        setWebhook={setWebhook}
                        hasWebhookAlerts={hasWebhookAlerts}
                        setHasWebookAlerts={setHasWebhookAlerts}
                        subscriptionStatus={
                          user.subscription
                            ? user.subscription.status
                            : "inactive"
                        }
                        goToSubscriptionPage={goToSubscriptionPage}
                      />
                    </InfoCard>

                    <SizedBox height={24} />
                    {/* <SubscriberFeatures /> */}
                  </Col>
                </Row>
                <SizedBox height={32} />

                {/* <PrimaryButton
          text={"Cancel"}
          onClick={goToProfile}
          isLoading={isLoading}
        /> */}
              </WidthContainer>
            )}
            <EmailModal
              show={showEmailModal}
              setShow={setShowEmailModal}
              connectGoogle={connectGoogle}
              disconnectAuth={disconnectAuth}
            />
            <AccountActivationSheet
              open={accountActivationOpen}
              setOpen={setAccountActivationOpen}
            />
          </>
        ) : (
          <>
            <Col>
              <SizedBox height={32} />
              <LoadingIndicator />
            </Col>
          </>
        )}
      </>
    </>
  );
}
