import { useContext, useState } from "react";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import OptionListTile from "./OptionListTile";
import { BiWallet } from "react-icons/bi";
import { BiEditAlt } from "react-icons/bi";
import { FiLogOut, FiShare, FiSend } from "react-icons/fi";
import { MdAttachMoney } from "react-icons/md"
import { FiUser } from "react-icons/fi";
import { AiFillCalendar } from "react-icons/ai";
import { SizedBox } from "./SizedBox";
import { WidthContainer } from "./WidthContainer";
import { Center } from "./Center";
import { useAppColors } from "../utils/useAppColors";
import { Web3Context } from "../contexts/Web3Context";
import { useNavigate } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";
import { Col, Modal, Row } from "react-bootstrap";
import { TitleText } from "./TitleText";
import { DynamicImage } from "./DynamicImage";
import IconButton from "./IconButton";
import { FaShare, FaTimes } from "react-icons/fa";

export default function OptionsBottomSheet({ open, setOpen, }) {
  const { user, logout, account, setShowSelfShare } = useContext(Web3Context);
  const navigate = useNavigate();
  const colors = useAppColors();

  async function goToEditProfile() {
    if (!user) {
      return;
    }
    setOpen(false);

    navigate("/e");
  }

  async function goToProfile() {
    if (!user) {
      return;
    }

    if (!user.username) {
      navigate("/e");
      return;
    }
    setOpen(false);
    navigate("/" + user.username);
    return;
  }

  async function goToEvents() {
    if (!user) {
      return;
    }

    if (!user.username) {
      navigate("/e");
      return;
    }
    setOpen(false);
    navigate("/s/events");
    return;
  }

  async function handleLogout() {
    setOpen(false);
    logout();
  }

  return (
    <>
      {user && account && (
        <>
          <BrowserView>
            <Modal
              dialogClassName="modal-60w"
              show={open}
              onHide={() => {
                setOpen(false);
              }}
            >
              <Modal.Body>
                <Center>
                  <div style={{ width: "90%" }}>
                    <SizedBox height={8} />
                    <Row className="d-flex align-items-end justify-content-end">
                      <Col className="d-flex align-items-end justify-content-end">
                        <IconButton
                          Icon={FaTimes}
                          onClick={() => {
                            setOpen(false);
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="d-flex flex-row justify-content-center">
                      <Col xs={2} className="d-flex align-items-center">
                        <DynamicImage
                          height={"60px"}
                          width={"60px"}
                          hasShadow={true}
                          borderRadius={"5000px"}
                          imageUrl={user.avatarUrl}
                        />
                      </Col>
                      <Col className="d-flex align-items-center">
                        <TitleText textAlign={"start"}>
                          {user.username ? user.username : "user"}
                        </TitleText>
                      </Col>
                    </Row>
                    <SizedBox height={32} />
                    <OptionListTile
                      onClick={goToProfile}
                      name={`${account.slice(0, 5) + "..." + account.slice(38, 42)
                        }`}
                      Icon={BiWallet}
                    />
                    <hr color={colors.textFieldColor} />

                    <OptionListTile
                      onClick={goToProfile}
                      name={"Profile"}
                      Icon={FiUser}
                    />
                    <hr color={colors.textFieldColor} />

                    {/* <OptionListTile
                      onClick={goToEvents}
                      name="Events"
                      Icon={AiFillCalendar}
                    />
                    <hr color={colors.textFieldColor} /> */}

                    <OptionListTile
                      onClick={goToEditProfile}
                      name="Edit Profile"
                      Icon={BiEditAlt}
                    />
                    <hr backgroundcolor={colors.textFieldColor} />
                    <OptionListTile
                      onClick={() => {
                        setShowSelfShare(true)
                        setOpen(false)
                      }}
                      name="Share Profile"
                      Icon={FiShare}
                    />
                    <hr backgroundcolor={colors.textFieldColor} />
                    <OptionListTile
                      onClick={() => {
                        navigate("/p/payments")
                        setOpen(false)
                      }}
                      name="Your Payments"
                      Icon={MdAttachMoney}
                    />
                    <hr backgroundcolor={colors.textFieldColor} />
                    <OptionListTile
                      onClick={() => {
                        navigate("/p/send")
                        setOpen(false)
                      }}
                      name="Send a payment"
                      Icon={FiSend}
                    />
                    <hr backgroundcolor={colors.textFieldColor} />

                    <OptionListTile
                      onClick={handleLogout}
                      name="Logout"
                      Icon={FiLogOut}
                    />
                    <SizedBox height={20} />
                  </div>
                </Center>
              </Modal.Body>
            </Modal>

          </BrowserView>
          <MobileView>
            {account ? (
              <>
                <BottomSheet
                  open={open}
                  onDismiss={() => {
                    setOpen(false);
                  }}
                >
                  <Center>
                    <div style={{ width: "90%" }}>
                      <SizedBox height={8} />

                      <OptionListTile
                        onClick={goToProfile}
                        name={`${account.slice(0, 5) + "..." + account.slice(38, 42)
                          }`}
                        Icon={BiWallet}
                      />
                      <hr color={colors.textFieldColor} />

                      <OptionListTile
                        onClick={goToProfile}
                        name={"Profile"}
                        Icon={FiUser}
                      />
                      <hr color={colors.textFieldColor} />

                      <OptionListTile
                        onClick={goToEditProfile}
                        name="Edit Profile"
                        Icon={BiEditAlt}
                      />
                      <hr backgroundcolor={colors.textFieldColor} />
                      <OptionListTile
                        onClick={() => {
                          setShowSelfShare(true)
                          setOpen(false)
                        }}
                        name="Share Profile"
                        Icon={FiShare}
                      />
                      <hr backgroundcolor={colors.textFieldColor} />
                    <OptionListTile
                      onClick={() => {
                        navigate("/p/payments")
                        setOpen(false)
                      }}
                      name="Your Payments"
                      Icon={MdAttachMoney}
                    />
                    <hr backgroundcolor={colors.textFieldColor} />
                    <OptionListTile
                      onClick={() => {
                        navigate("/p/send")
                        setOpen(false)
                      }}
                      name="Send a payment"
                      Icon={FiSend}
                    />
                      <hr backgroundcolor={colors.textFieldColor} />
                      <OptionListTile
                        onClick={handleLogout}
                        name="Logout"
                        Icon={FiLogOut}
                      />
                    </div>
                  </Center>
                  <SizedBox height={32} />
                </BottomSheet>

              </>
            ) : null}
          </MobileView>
        </>
      )}
    </>
  );
}
