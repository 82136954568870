import { Dropdown, DropdownButton } from "react-bootstrap";
import { StandardShadow } from "./StandardShadow";
import styled from "styled-components";
import { useAppColors } from "../utils/useAppColors";

const InputName = styled.div`
  color: ${(props) => props.color};
  font-size: 14px;
  margin-bottom: 12px;
  font-weight: bold;
  text-align: start;
`;

const InputNameWrapper = styled.div`
  background-color: white;
  position: relative;
  top: 24px;
  left: 24px;
  padding-right: 4px;
  padding-left: 4px;
  z-index: 100;
`;

export default function SecondaryDropdownButton({
  options,
  text,
  onClick,
  name,
}) {
  const colors = useAppColors();
  return (
    <div>
      {name ? (
        <div className="d-flex">
          <InputNameWrapper>
            <InputName color={colors.textColor}>{name}</InputName>
          </InputNameWrapper>
        </div>
      ) : null}
      <StandardShadow borderRadius={"24px"}>
        <DropdownButton
          id="dropdown-basic-button"
          title={text}
          className="secondary-dropdown-button"
        >
          {Object.keys(options).map((type) => (
            <Dropdown.Item key={type} onClick={() => onClick({ type: type })}>
              {options[type]}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      </StandardShadow>
    </div>
  );
}
