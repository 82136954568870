import React from "react";
import styled from "styled-components";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import { useAppColors } from "../utils/useAppColors";

const DescriptionTextWrapper = styled.div`
  color: ${(props) => props.color};
  font-size: ${(props) => props.fontSize};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "start")};
`;

const DescriptionTextWrapperMobile = styled.div`
  color: ${(props) => props.color};
  font-size: ${(props) => props.fontSize};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "start")};
`;

export const DescriptionText = ({ children, textAlign, color, fontSize }) => {
  const colors = useAppColors();
  return (
    <div>
      <BrowserView>
        <DescriptionTextWrapper color={color ? color : colors.textColor} fontSize={fontSize ? fontSize : "18px"} textAlign={textAlign}>
          {children}
        </DescriptionTextWrapper>
      </BrowserView>
      <MobileView>
        <DescriptionTextWrapperMobile
          color={color ? color : colors.textColor}
          textAlign={textAlign}
          fontSize={fontSize ? fontSize : "18px"}
        >
          {children}
        </DescriptionTextWrapperMobile>
      </MobileView>
    </div>
  );
};
