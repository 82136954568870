import Footer from "../components/Footer";
import { ScheduleNav } from "../components/ScheduleNav";
import WebflowHomePage from "../webflow/WebflowHomePage";

export default function Home({ isLoggingIn }) {
  return (
    <div style={{ height: "100vh", display: "flex", flexFlow: "column" }}>
      <ScheduleNav isLoggingIn={isLoggingIn} />
      <WebflowHomePage />
      {/* <Footer /> */}
    </div>
  );
}
