import styled from "styled-components";
import { useAppColors } from "../utils/useAppColors";

const SubtitleTextWrapper = styled.div`
  color: ${(props) => props.color};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "24px")};
  font-weight: bold;
  text-align: ${(props) => (props.textAlign ? props.textAlign : "center")};
`;

export function SubtitleText({ children, color, fontSize, textAlign }) {
  const colors = useAppColors();
  return (
    <SubtitleTextWrapper
      color={color ? color : colors.primaryColor}
      fontSize={fontSize}
      textAlign={textAlign}
    >
      {children}
    </SubtitleTextWrapper>
  );
}
