import { textAlign } from "@mui/system";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FooterLink, Heading } from "./footerStyles";
import { SizedBox } from "./SizedBox";

const Footer = () => {
  return (
    <div>
      <SizedBox height={"18px"} />

      <div style={{ backgroundColor: "#FAFAFA" }}>
        <SizedBox height={"18px"} />
        <Container>
          <Row>
            <Col className="d-flex flex-column">
              <Heading>About</Heading>
              <FooterLink href="https://docs.schedule.bond">Team</FooterLink>
              <FooterLink href="https://schedule.bond/privacy-policy.pdf">Privacy Policy</FooterLink>
            </Col>
            <Col className="d-flex flex-column">
              <Heading>Support</Heading>
              <FooterLink href="https://docs.schedule.bond/2ed00fc8-12de-4f88-908b-48b4da87a5d6">
                Getting Started
              </FooterLink>
              <FooterLink href="https://docs.schedule.bond/5c5869f8-a064-473d-987e-4282bc5d51c8">
                F.A.Q.
              </FooterLink>
              <FooterLink href="#">Contact Us</FooterLink>
            </Col>
            <Col className="d-flex flex-column">
              <Heading>Socials</Heading>
              <FooterLink href="https://twitter.com/ScheduleBond">
                Twitter
              </FooterLink>
              <FooterLink href="https://discord.gg/4jtExX5dNJ">
                Discord
              </FooterLink>
            </Col>
          </Row>
        </Container>
        <SizedBox height={"18px"} />
      </div>
    </div>
  );
};
export default Footer;
