import { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { BrowserView, MobileView } from "react-device-detect";
import { Center } from "../components/Center";
import { PaymentListItem } from "../components/PaymentListItem"
import LoadingIndicator from "../components/LoadingIndicator";
import { WidthContainer } from "../components/WidthContainer";
import { Web3Context } from "../contexts/Web3Context";
import api from "../utils/api";
import InfoCard from "../components/InfoCard";
import { TitleText } from "../components/TitleText";
import { SizedBox } from "../components/SizedBox";
import { SocialMediaRouterSection } from "../components/SocialMediaRouterSection" 
import { DescriptionText } from "../components/DescriptionText";

export default function Payments() {
    const { user, jwt, showSnackbar, log } = useContext(Web3Context)
    const [isLoading, setIsLoading] = useState(true)
    const [payments, setPayments] = useState([])

    async function getInitialData() {
        try {
            const response = await api.getPayments({ jwt: jwt })
            if (response.error) {
                throw Error(response.error)
            }
            setPayments(response.payments)
            setIsLoading(false)
            return
        } catch (e) {
            showSnackbar({ message: e.message, severity: "error" });
            log({
                eventName: "general error",
                eventParams: {
                    userUid: user ? user._id : undefined,
                    error: e,
                },
            });
            setIsLoading(false);
            return;
        }
    }

    useEffect(() => {
        if (user) {
            getInitialData()
        }
    }, [user])
    return <>
        <BrowserView>
            <WidthContainer width="60%" topPadding={36}>
                <>
                    {
                        isLoading || !user?
                            <> 
                                <Center>
                                    <LoadingIndicator />
                                </Center>
                            </>
                            :
                            <Row>
                                <Col>
                                    <InfoCard padding="24px">
                                        <SocialMediaRouterSection twitterAddress={user.twitter} />
                                        </InfoCard>
                                    <SizedBox height={16} />
                                    <InfoCard padding="24px">
                                        <TitleText textAlign={"start"}>Your Payments</TitleText>
                                        <DescriptionText>
                                            The payments you've sent and received through Schedule.bond
                                        </DescriptionText>
                                        <SizedBox height="16px" />
                                        {payments.map(p => <PaymentListItem key={p._id} payment={p} />)}
                                    </InfoCard>
                                </Col>
                            </Row>
                    }
                </>
            </WidthContainer>
        </BrowserView>
        <MobileView>
            <WidthContainer width="60%" topPadding={36}>
                <>
                    {
                        isLoading || !user?
                            <> 
                                <Center>
                                    <LoadingIndicator />
                                </Center>
                            </>
                            :
                            <Row>
                                <Col>
                                    <InfoCard padding="24px">
                                        <SocialMediaRouterSection twitterAddress={user.twitter} />
                                        </InfoCard>
                                    <SizedBox height={16} />
                                    <InfoCard padding="24px">
                                        <TitleText textAlign={"start"}>Your Payments</TitleText>
                                        <DescriptionText>
                                            The payments you've sent and received through Schedule.bond
                                        </DescriptionText>
                                        <SizedBox height="16px" />
                                        {payments.map(p => <PaymentListItem key={p._id} payment={p} />)}
                                    </InfoCard>
                                </Col>
                            </Row>
                    }
                </>
            </WidthContainer>
        </MobileView>
    </>
}