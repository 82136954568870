import { useContext, useEffect, useState } from "react";
import { Web3Context } from "../contexts/Web3Context";
import ethereumUtils from "../utils/ethereum";
import styled from "styled-components";
import InfoCard from "./InfoCard";
import { Row } from "react-bootstrap";
import { TitleText } from "./TitleText";
import { DescriptionText } from "./DescriptionText";
import { StandardShadow } from "./StandardShadow";
import { SizedBox } from "./SizedBox";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { PrimaryButton } from "./PrimaryButton";
import BondView from "./BondView";
import { SubtitleText } from "./SubtitleText";
import BondView2 from "./BondView2";

//for integrations bonds
import ky from "ky";

const testAddy = "0xbb997742326E8Da7bfe63077f7F66aae07C9D676";
const testAddyLength = testAddy.length;

const BondsViewerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  border: 2px solid grey;
  border-radius: 20px;
  padding: 16px;
`;

export default function BondsViewer2({
  bonds,
  otherUser,
  user,
  selectedBond,
  selectBond,
  editProfile,
  scheduleMeeting,
}) {
  const {  networkId } = useContext(Web3Context);


  return (
    <InfoCard padding={"24px"}>
      {(
        <>
          <Row className="d-flex">
            <TitleText textAlign={"start"}>Bonds</TitleText>
          </Row>
          <Row>
            <DescriptionText>
              {`In order to schedule a meeting with ${otherUser.username}, you have to create an account and qualify for at least ONE of their bonds.`}
            </DescriptionText>
          </Row>

          {((!bonds || bonds.length === 0) && (!otherUser.hasFriendTechEnabled)) && (
            <>
              <SizedBox height={16} />
              <SubtitleText>
                {otherUser.hasEmail ? `${otherUser.username} allows anyone to schedule a meeting with them.` :
                  `${otherUser.username}'s account is not fully enabled yet.`}
              </SubtitleText>
            </>
          )}
          {bonds && bonds.map((b, bondIndex) => (
            <div key={"bond-" + bondIndex + "bye"}>
              <>
                <SizedBox height={12} />
                {bondIndex === 0 ? (
                  <></>
                ) : (
                  <DescriptionText textAlign={"center"}>{`OR`}</DescriptionText>
                )}
              </>

              <SizedBox height={8} />
              <BondView2
                bond={b}
                match={b.match}
                selected={selectedBond === b.bondId}
                selectBond={selectBond}
              />
            </div>
          ))}
          
        </>
      )}
      <SizedBox height={42} />
      {user && otherUser._id === user._id ? (
        <>
          <PrimaryButton text="Edit Profile" onClick={editProfile} />
        </>
      ) : (
        <>
          <PrimaryButton
            text="Schedule Meeting"
            onClick={scheduleMeeting}
            disabled={(typeof selectedBond === "undefined" && bonds.length > 0) || !otherUser.hasEmail}
          />
        </>
      )}
    </InfoCard>
  );
}