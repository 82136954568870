import styled from "styled-components";
import { StandardShadow } from "./StandardShadow";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { Center } from "./Center";
import { useAppColors } from "../utils/useAppColors";
import { isMobile } from "react-device-detect";
import RuleView2 from "./RuleView2";

const BondViewWrapper = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  justify-content: space-between;
  border-top: 2px solid ${(props) => props.borderColor};
  border-left: 2px solid ${(props) => props.borderColor};
  border-bottom: 2px solid ${(props) => props.borderColor};
  border-right: 2px solid ${(props) => props.borderColor};
  border-radius: 24px;
  padding: 20px;
  cursor: pointer;
  background-color: ${(props) => props.backgroundColor};
`;

const RulesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  font-weight: bold;
`;

const MobileRulesWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: start;
  align-items: start;
  width: 100%;
  font-weight: bold;
`;

const AndLink = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
`;

const SelectedCircle = styled.div`
  color: ${(props) => props.color};
  border-radius: 5000px;
  padding:2px;
  display:flex;
  align-items;center;
  justify-content:center;
`;
export default function BondView2({
  bond,
  match,
  selected,
  selectBond,
}) {
  const colors = useAppColors();
  const bondClass = match ? "active-gradient-selector" : "";
  const bondBackgroundColor = bond.type == "friend-tech-holder" ? "#96e4ff" : "white";
  return (
    <StandardShadow borderRadius={"24px"}>
      <BondViewWrapper
        match={match}
        className={bondClass}
        selected={selected}
        borderColor={match ? colors.successGreen : "black"}
        backgroundColor={bondBackgroundColor}
        onClick={() => {
          console.log("bondId:", bond.bondId )
          console.log("bondIndex: ", bond.bondIndex)
          selectBond({ bondId: bond.bondId, bondIndex: bond.bondIndex, match: match });
        }}
      >
        {isMobile ? (
          <>
            <MobileRulesWrapper>
              {bond.rules.map((r, ruleIndex) => (
                <>
                  <RuleView2
                    key={"bond-" + bond.bondId + "-rule-" + r._id + "p"}
                    rule={r}
                    isMobile={isMobile}
                  />
                </>
              ))}
            </MobileRulesWrapper>
          </>
        ) : (
          <>
            <RulesWrapper>
              {bond.rules.map((r, ruleIndex) => (
                <RuleView2
                  key={"bond-" + bond.bondId + "-rule-" + r._id}
                  rule={r}
                  isMobile={isMobile}
                />
              ))}
              <>
                {bond.rules.length > 1 && (
                  <AndLink key={"bond-" + bond.bondId + "-rule-link"}>
                  🔗
                  </AndLink>
                )}
              </>
            </RulesWrapper>
          </>
        )}
        {selected ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <SelectedCircle
              className="lighter-active-gradient"
              color={colors.primaryColor}
            >
              <BsFillCheckCircleFill size={20} />
            </SelectedCircle>
          </div>
        ) : null}
      </BondViewWrapper>
    </StandardShadow>
  );
}