export default function WebflowHomePage() {
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <iframe
        title="homepage"
        src={"https://schedule-bond-landing.netlify.app"}
        style={{ height: "100%", width: "100%" }}
      />
    </div>
  );
}
