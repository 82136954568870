import { Col, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import config from "../utils/config";
import { Center } from "./Center";
import { DynamicImage } from "./DynamicImage";
import IconButton from "./IconButton";
import InfoCard from "./InfoCard";
import LoadingIndicator from "./LoadingIndicator";
import ShareBottomSheet from "./ShareBottomSheet";
import { SizedBox } from "./SizedBox";
import { SubtitleText } from "./SubtitleText";
import { TitleText } from "./TitleText";
import { FiShare } from "react-icons/fi";
import { useState } from "react";
import { DescriptionText } from "./DescriptionText";
import styled from "styled-components";
import { StandardShadow } from "./StandardShadow";
import { BsTwitter } from "react-icons/bs";

const SocialMediaLinkWrapper = styled.div`
  border-radius: 5000px;
  width: ${(props) => (props.size ? props.size : "40px")};
  height: ${(props) => (props.size ? props.size : "40px")};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "white"};
  color: ${(props) => (props.color ? props.color : "black")};
  border: 2px solid black;
  cursor: pointer;
`;

function SocialMediaLink({ url, Icon, size, backgroundColor, color }) {
  return (
    <Center>
      <StandardShadow borderRadius={"5000px"}>
        <a href={url} target={"_blank"} rel="noreferrer">
          <SocialMediaLinkWrapper
            size={size}
            color={color}
            backgroundColor={backgroundColor}
          >
            {Icon}
          </SocialMediaLinkWrapper>
        </a>
      </StandardShadow>
    </Center>
  );
}

export default function ProfileCard({ u, isHorizontal }) {
  const [shareOpen, setShareOpen] = useState(false);
  return (
    <>
      {isMobile || isHorizontal ? (
        <>
          {!u ? (
            <LoadingIndicator />
          ) : (
            <>
              <InfoCard padding={"24px"}>
                <Row style={{ width: "100%" }}>
                  <Col xs={4}>
                    <DynamicImage
                      imageUrl={
                        u.avatarUrl ? u.avatarUrl : config.defaultAvatarUrl
                      }
                      hasShadow={true}
                      borderRadius={"5000px"}
                      width={"100%"}
                    />
                  </Col>
                  <Col xs={7}>
                    <Row>
                      <Center>
                        <TitleText fontSize="22px">{u.displayName}</TitleText>
                      </Center>
                    </Row>
                    <SizedBox height={8} />
                    <Row>
                      <Center>
                        <SubtitleText fontSize={"18px"}>
                          {u.username}
                        </SubtitleText>
                      </Center>
                    </Row>
                  </Col>
                  <Col xs={1}>
                    <IconButton
                      Icon={FiShare}
                      onClick={() => {
                        setShareOpen(true);
                      }}
                    />
                  </Col>
                </Row>
                <SizedBox height={12} />
                <Row>
                  <Row>
                    <Center>
                      <DescriptionText>{u.bio ? u.bio : ""}</DescriptionText>
                    </Center>
                  </Row>
                </Row>
                <Row>
                  {u.twitterUserId && (
                    <SocialMediaLink
                      url={`https://twitter.com/intent/user?user_id=${u.twitterUserId}`}
                      Icon={<BsTwitter />}
                    />
                  )}
                </Row>

                <ShareBottomSheet
                  open={shareOpen}
                  setOpen={setShareOpen}
                  otherUser={u}
                />
              </InfoCard>
            </>
          )}
        </>
      ) : (
        <>
          {!u ? (
            <LoadingIndicator />
          ) : (
            <InfoCard padding={"24px"}>
              <Row>
                <Col className="d-flex justify-content-end">
                  <IconButton
                    Icon={FiShare}
                    onClick={() => {
                      setShareOpen(true);
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Center>
                  <div>
                    <DynamicImage
                      imageUrl={
                        u.avatarUrl ? u.avatarUrl : config.defaultAvatarUrl
                      }
                      hasShadow={true}
                      borderRadius={"5000px"}
                      width={"200px"}
                      height={"200px"}
                    />
                  </div>
                </Center>
              </Row>
              <SizedBox height={16} />
              <Row>
                <Center>
                  <TitleText>{u.displayName}</TitleText>
                </Center>
              </Row>
              <SizedBox height={8} />
              <Row>
                <Center>
                  <SubtitleText>{u.username}</SubtitleText>
                </Center>
              </Row>
              <SizedBox height={8} />
              <Row>
                <Center>
                  <DescriptionText>{u.bio ? u.bio : ""}</DescriptionText>
                </Center>
              </Row>
              <SizedBox height={16} />
              <Row>
                {u.twitterUserId && (
                  <SocialMediaLink
                    url={`https://twitter.com/intent/user?user_id=${u.twitterUserId}`}
                    Icon={<BsTwitter />}
                  />
                )}
              </Row>
              <ShareBottomSheet
                open={shareOpen}
                setOpen={setShareOpen}
                otherUser={u}
              />
            </InfoCard>
          )}
        </>
      )}
    </>
  );
}
