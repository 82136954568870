import { useContext, useState } from "react";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import OptionListTile from "./OptionListTile";
import { BiWallet } from "react-icons/bi";
import { BiEditAlt } from "react-icons/bi";
import { FiLogOut } from "react-icons/fi";
import { FiUser } from "react-icons/fi";
import { AiFillCalendar } from "react-icons/ai";
import { SizedBox } from "./SizedBox";
import { WidthContainer } from "./WidthContainer";
import { Center } from "./Center";
import { useAppColors } from "../utils/useAppColors";
import { Web3Context } from "../contexts/Web3Context";
import { useNavigate } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";
import { Col, Modal, Row } from "react-bootstrap";
import { TitleText } from "./TitleText";
import { DynamicImage } from "./DynamicImage";
import IconButton from "./IconButton";
import { FaTimes } from "react-icons/fa";
import InfoCard from "./InfoCard";
import FeatureListItem from "./FeatureListItem";
import { PrimaryButton } from "./PrimaryButton";
import { FlatButton } from "./FlatButton";
import { SubtitleText } from "./SubtitleText";
import { DescriptionText } from "./DescriptionText";
import api from "../utils/api";
import LoadingIndicator from "./LoadingIndicator";
import { isBrowser } from "react-device-detect";

export default function SubscriptionBottomSheet({ open, setOpen, subActive }) {
  const { user, setUser, jwt, logout, account, showSnackbar, log } =
    useContext(Web3Context);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const colors = useAppColors();

  async function goToEditProfile() {
    if (!user) {
      return;
    }
    setOpen(false);

    navigate("/e");
  }

  async function goToProfile() {
    if (!user) {
      return;
    }

    if (!user.username || !user.email) {
      navigate("/e");
      return;
    }
    setOpen(false);
    navigate("/" + user.username);
    return;
  }

  async function goToEvents() {
    if (!user) {
      return;
    }

    if (!user.username || !user.email) {
      navigate("/e");
      return;
    }
    setOpen(false);
    navigate("/s/events");
    return;
  }

  async function handleLogout() {
    setOpen(false);
    logout();
  }

  async function subscribeToBondMax() {
    try {
      setIsLoading(true);
      const paymentLinkResp = await api.createPaymentLink({ jwt });
      if (paymentLinkResp.error) {
        setIsLoading(false);
        showSnackbar({ message: paymentLinkResp.error, severity: "error" });
        log({
          eventName: "createPaymentLink error",
          eventParams: {
            userUid: user ? user._id : undefined,
            error: paymentLinkResp.error,
          },
        });
        return;
      }
      log({
        eventName: "createPaymentLink",
        eventParams: {
          userUid: user ? user._id : undefined,
        },
      });
      window.location.href = paymentLinkResp.paymentLink;
      if (!isBrowser) {
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
      log({
        eventName: "general error",
        eventParams: {
          userUid: user ? user._id : undefined,
          error: e,
        },
      });
      showSnackbar({ message: e.message, severity: "error" });
      return;
    }
  }

  async function unsubscribeToBondMax() {
    try {
      setIsLoading(true);
      const cancelSubscriptionResp = await api.cancelSubscription({ jwt });
      if (cancelSubscriptionResp.error) {
        setIsLoading(false);
        showSnackbar({
          message: cancelSubscriptionResp.error,
          severity: "error",
        });
        log({
          eventName: "cancelSubscription error",
          eventParams: {
            userUid: user ? user._id : undefined,
            error: cancelSubscriptionResp.error,
          },
        });
        return;
      }
      log({
        eventName: "cancelSubscription",
        eventParams: {
          userUid: user ? user._id : undefined,
        },
      });
      setUser(cancelSubscriptionResp.user);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      log({
        eventName: "general error",
        eventParams: {
          userUid: user ? user._id : undefined,
          error: e,
        },
      });
      showSnackbar({ message: e.message, severity: "error" });
      return;
    }
  }

  return (
    <>
      {user && account && (
        <>
          <BrowserView>
            <Modal dialogClassName="modal-60w" show={open} onHide={() => {}}>
              <Modal.Body>
                <Center>
                  <div style={{ width: "90%" }}>
                    <SizedBox height={8} />
                    <Row className="d-flex align-items-end justify-content-end">
                      <Col className="d-flex align-items-end justify-content-end">
                        {!isLoading && (
                          <IconButton
                            Icon={FaTimes}
                            onClick={() => {
                              setOpen(false);
                            }}
                          />
                        )}
                      </Col>
                    </Row>
                    <Row className="d-flex flex-row justify-content-center">
                      <Col>
                        <TitleText textAlign={"start"}>
                          {subActive
                            ? "Unsubscribe from Bond.MAX"
                            : "Subscribe to Bond.MAX"}
                        </TitleText>
                      </Col>
                    </Row>
                    <SizedBox height={12} />
                    <DescriptionText>
                      {subActive
                        ? "Unsubscribing from Bond.MAX will disable features from your account"
                        : "Subscribing to Bond.MAX gives you access to features that are not available in the free version."}
                    </DescriptionText>
                    <SizedBox height={12} />
                    <SubtitleText textAlign={"start"}>
                      {subActive
                        ? "Features that will be disabled"
                        : "Features"}
                    </SubtitleText>
                    <SizedBox height={12} />
                    <InfoCard padding="18px">
                      <Row>
                        {!subActive && (
                          <>
                            <FeatureListItem text={"NFT Ownership"} xs={6} />
                            <FeatureListItem text={"ETH Balance"} xs={6} />
                            <FeatureListItem text={"ERC20 Balance"} xs={6} />
                          </>
                        )}
                        <FeatureListItem
                          text={"Text Alerts"}
                          xs={6}
                          unavailable={subActive}
                        />
                        <FeatureListItem
                          text={"Webhook Alerts"}
                          comingSoon={true}
                          xs={6}
                          unavailable={subActive}
                        />
                        <FeatureListItem
                          text={"ETH Payments"}
                          comingSoon={true}
                          xs={6}
                          unavailable={subActive}
                        />
                      </Row>
                    </InfoCard>
                    <SizedBox height={32} />

                    <>
                      {isLoading ? (
                        <LoadingIndicator />
                      ) : (
                        <>
                          {subActive ? (
                            <Center>
                              <FlatButton
                                color={colors.errorRed}
                                onClick={unsubscribeToBondMax}
                              >
                                Cancel Subscription
                              </FlatButton>
                            </Center>
                          ) : (
                            <PrimaryButton
                              text={"Subscribe"}
                              onClick={subscribeToBondMax}
                            />
                          )}
                        </>
                      )}
                    </>

                    <SizedBox height={20} />
                  </div>
                </Center>
              </Modal.Body>
            </Modal>
          </BrowserView>
          <MobileView>
            {account ? (
              <>
                <BottomSheet
                  open={open}
                  onDismiss={() => {
                    setOpen(false);
                  }}
                >
                  <Center>
                    <div style={{ width: "90%" }}>
                      <SizedBox height={8} />

                      <OptionListTile
                        onClick={goToProfile}
                        name={`${
                          account.slice(0, 5) + "..." + account.slice(38, 42)
                        }`}
                        Icon={BiWallet}
                      />
                      <hr color={colors.textFieldColor} />

                      <OptionListTile
                        onClick={goToProfile}
                        name={"Profile"}
                        Icon={FiUser}
                      />
                      <hr color={colors.textFieldColor} />

                      <OptionListTile
                        onClick={goToEditProfile}
                        name="Edit Profile"
                        Icon={BiEditAlt}
                      />
                      <hr backgroundcolor={colors.textFieldColor} />

                      <OptionListTile
                        onClick={handleLogout}
                        name="Logout"
                        Icon={FiLogOut}
                      />
                    </div>
                  </Center>
                  <SizedBox height={32} />
                </BottomSheet>
              </>
            ) : null}
          </MobileView>
        </>
      )}
    </>
  );
}
