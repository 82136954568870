import { useContext, useEffect, useState } from "react";
import { BrowserView, isMobile, MobileView } from "react-device-detect";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { WidthContainer } from "../components/WidthContainer";
import { Web3Context } from "../contexts/Web3Context";
import api from "../utils/api";

const dappUrl = "schedule.bond";

export default function GoogleSigninPage() {
    const { user, setUser, jwt, log, showSnackbar } = useContext(Web3Context)
    const location = useLocation();
    const navigate = useNavigate()
    let [searchParams, setSearchParams] = useSearchParams();
    const [code, setCode] = useState(searchParams.get("code"));
    let { route } = useParams();
    const [isLoading, setIsLoading] = useState(true)
    const [deepLink, setDeepLink] = useState(
        "https://metamask.app.link/dapp/" +
          dappUrl +
          location.pathname +
          location.search
      );
    
      useEffect(() => {
        setDeepLink(
          "https://metamask.app.link/dapp/" +
            dappUrl +
            location.pathname +
            location.search
        );
      }, [location]);
    async function routeToMetamask(){
        window.location = deepLink
    }

    useEffect(() => {
        if(isMobile && !window.etheremum){
            routeToMetamask()
        }
    }, [])


    async function handleCode() {
        try {
            setIsLoading(true)
            if (!code) {
                throw Error("Code not found")
            }

            const r = await api.processAuthCode({
                authCode: code,
                platform: "google",
                route: route,
                jwt: jwt,
            });

            if (r.error) {
                showSnackbar({ message: r.error.toString(), severity: "error" });
                log({
                    eventName: "processAuthCode error",
                    eventParams: {
                        userUid: user ? user._id : undefined,
                        error: r.error,
                    },
                });
                setIsLoading(false);
            } else {
                log({
                    eventName: "processAuthCode",
                    eventParams: {
                        userUid: user ? user._id : undefined,
                    },
                });
                showSnackbar({
                    message: "Successfully Synced Email!",
                    severity: "success",
                });
                setUser(r.user);
                if (route === "edit") {
                    navigate(`/e`)
                } else {
                    navigate(`/p/onboarding?i=2`)
                }
            }

            setIsLoading(false);

        } catch (e) {
            log({
                eventName: "general Error",
                eventParams: {
                    userUid: user ? user._id : undefined,
                    error: e,
                },
            });
            showSnackbar({
                message: e.message,
                severity: "error",
            });
        }
    }

    useEffect(() => {
        if (user && jwt) {
            handleCode()
        }
    }, [user, jwt])

    return <>
        <BrowserView>
            <div>
                Processing Email
            </div>
        </BrowserView>
        <MobileView>
            <div>
                Processing Email
            </div>
        </MobileView>
    </>
}