import moment from "moment";
import React from "react";
import { useContext, useEffect, useState } from "react";
import PopoverWrapper from "./PopoverWrapper";
import "./timezonemenu.css";

export default function TimeZoneMenu({ onChangeTimeZone, isView, timeZone }) {
  const [currentTz, setCurrentTz] = useState(moment.tz.guess());

  useEffect(() => {
    setCurrentTz(moment.tz.guess());
  }, []);

  useEffect(() => {
    onChangeTimeZone && onChangeTimeZone(currentTz);
  }, []);

  const ChangeTimeZone = (event) => {
    setCurrentTz(event.target.value);
    onChangeTimeZone && onChangeTimeZone(currentTz);
  };

  let zones = [
    ["(GMT-11:00) Midway Island, Samoa", "Pacific/Midway"],
    ["(GMT-10:00) Hawaii", "Pacific/Honolulu"],
    ["(GMT-8:00) Alaska", "America/Juneau"],
    ["(GMT-7:00) Dawson, Yukon", "America/Dawson"],
    ["(GMT-7:00) Arizona", "America/Phoenix"],
    ["(GMT-7:00) Tijuana", "America/Tijuana"],
    ["(GMT-7:00) Pacific Time", "America/Los_Angeles"],
    ["(GMT-6:00) Mountain Time", "America/Boise"],
    ["(GMT-6:00) Chihuahua, La Paz, Mazatlan", "America/Chihuahua"],
    ["(GMT-6:00) Saskatchewan", "America/Regina"],
    ["(GMT-6:00) Central America", "America/Belize"],
    ["(GMT-5:00) Central Time", "America/Chicago"],
    ["(GMT-5:00) Guadalajara, Mexico City", "America/Mexico_City"],
    ["(GMT-5:00) Bogota, Lima, Quito", "America/Bogota"],
    ["(GMT-4:00) Eastern time", "America/Detroit"],
    ["(GMT-4:00) Eastern time", "America/New_York"],
    ["(GMT-4:00) Indiana East", "America/Indianapolis"],
    ["(GMT-4:00) Caracas, La Paz", "America/Carcas"],
    ["(GMT-4:00) Santiago", "America/Santiago"],
    ["(GMT-3:00) Brasilia", "America/Sao_Paulo"],
    ["(GMT-3:00) Montevideo", "America/Montevideo"],
    ["(GMT-3:00) Buenos Aires, Georgetown", "America/Argentina/Buenos_Aires"],
    ["(GMT-2:30) Newfoundland and Labrador", "America/St_Johns"],
    ["(GMT-2:00) Greenland", "America/Godthab"],
    ["(GMT-1:00) Cape Verde Islands", "Atlantic/Cape_Verde"],
    ["(GMT+0:00) Azores", "Atlantic/Azores"],
    ["(GMT+0:00) UTC", "Etc/GMT"],
    ["(GMT+0:00) Casablanca, Monrovia", "Africa/Casablanca"],
    ["(GMT+1:00) London", "Europe/London"],
    ["(GMT+1:00) Dublin", "Europe/Dublin"],
    ["(GMT+1:00) Lisbon", "Europe/Lisbon"],
    ["(GMT+1:00) Canary Islands", "Atlantic/Canary"],
    ["(GMT+1:00) West Central Africa", "Africa/Algiers"],
    ["(GMT+2:00) Belgrade, Bratislava, Budapest", "Europe/Belgrade"],
    ["(GMT+2:00) Sarajevo, Skopje, Warsaw, Zagreb", "Europe/Sarajevo"],
    ["(GMT+2:00) Brussels, Copenhagen", "Europe/Brussels"],
    ["(GMT+2:00) Amsterdam, Berlin, Bern", "Europe/Amsterdam"],
    ["(GMT+2:00) Cairo", "Africa/Cairo"],
    ["(GMT+2:00) Harare, Pretoria", "Africa/Harare"],
    ["(GMT+3:00) Bucharest", "Europe/Bucharest"],
    ["(GMT+3:00) Helsinki, Kiev, Riga", "Europe/Helsinki"],
    ["(GMT+3:00) Athens, Minsk", "Europe/Athens"],
    ["(GMT+3:00) Jerusalem", "Asia/Jerusalem"],
    ["(GMT+3:00) Moscow, Istanbul, St. Petersburg", "Europe/Moscow"],
    ["(GMT+3:00) Kuwait, Riyadh", "Asia/Kuwait"],
    ["(GMT+3:00) Nairobi", "Africa/Nairobi"],
    ["(GMT+3:00) Baghdad", "Asia/Baghdad"],
    ["(GMT+4:00) Abu Dhabi, Muscat", "Asia/Dubai"],
    ["(GMT+4:00) Baku, Tbilisi, Yerevan", "Asia/Baku"],
    ["(GMT+4:30) Tehran", "Asia/Tehran"],
    ["(GMT+4:30) Kabul", "Asia/Kabul"],
    ["(GMT+5:00) Yekaterinburg", "Asia/Yekaterinburg"],
    ["(GMT+5:00) Islamabad, Karachi", "Asia/Karachi"],
    ["(GMT+5:30) Kolkata, Chennai", "Asia/Kolkata"],
    ["(GMT+5:30) Sri Jayawardenpura", "Asia/Colombo"],
    ["(GMT+5:45) Kathmandu", "Asia/Kathmandu"],
    ["(GMT+6:00) Astana, Dhaka", "Asia/Dhaka"],
    ["(GMT+6:00) Almaty, Novosibirsk", "Asia/Almaty"],
    ["(GMT+6:30) Yangon Rangoon", "Asia/Rangoon"],
    ["(GMT+7:00) Bangkok, Hanoi, Jakarta", "Asia/Bangkok"],
    ["(GMT+7:00) Krasnoyarsk", "Asia/Krasnoyarsk"],
    ["(GMT+8:00) Beijing, Shanghai", "Asia/Shanghai"],
    ["(GMT+8:00) Kuala Lumpur", "Asia/Kuala_Lumpur"],
    ["(GMT+8:00) Taipei", "Asia/Taipei"],
    ["(GMT+8:00) Perth", "Australia/Perth"],
    ["(GMT+8:00) Irkutsk, Ulaanbaatar", "Asia/Irkutsk"],
    ["(GMT+9:00) Seoul", "Asia/Seoul"],
    ["(GMT+9:00) Osaka, Tokyo", "Asia/Tokyo"],
    ["(GMT+9:30) Darwin", "Australia/Darwin"],
    ["(GMT+9:30) Adelaide", "Australia/Adelaide"],
    ["(GMT+10:00) Yakutsk", "Asia/Yakutsk"],
    ["(GMT+10:00) Canberra, Melbourne, Sydney", "Australia/Sydney"],
    ["(GMT+10:00) Brisbane", "Australia/Brisbane"],
    ["(GMT+10:00) Hobart", "Australia/Hobart"],
    ["(GMT+10:00) Vladivostok", "Asia/Vladivostok"],
    ["(GMT+10:00) Guam", "Pacific/Guam"],
    ["(GMT+11:00) Magadan Solomon Islands", "Pacific/Magadan"],
    ["(GMT+12:00) Kamchatka, Marshall Islands", "Asia/Kamchatka"],
    ["(GMT+12:00) Fiji Islands", "Pacific/Fiji"],
    ["(GMT+12:00) Auckland, Wellington", "Pacific/Auckland"],
    ["(GMT+13:00) Nuku'alofa", "Pacific/Tongatapu"],
  ];

  let tzList = zones.map((zone) => {
    return (
      <option className="item" value={zone[1]}>
        {zone[0]}
      </option>
    );
  });
  return (
    <div>
      {!isView && <select
        className="TZSelector"
        value={currentTz}
        onChange={ChangeTimeZone}
      >
        {tzList}
      </select>}
      {isView && <PopoverWrapper popoverTitle={"Timezone"} popoverBody={"This timezone is pulled from your google calendar"}>
        <div style={{fontWeight: "bold"}}>
          {timeZone.replace("_", " ")} 
        </div>
      </PopoverWrapper>
      }
    </div>
  );
}
