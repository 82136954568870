import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { WidthContainer } from "../components/WidthContainer";
import LoadingIndicator from "../components/LoadingIndicator";
import { Web3Context } from "../contexts/Web3Context";
import api from "../utils/api";
import BondsViewer2 from "../components/BondsViewer2";
import { Col, Row } from "react-bootstrap";
import ProfileCard from "../components/ProfileCard";

import { SizedBox } from "../components/SizedBox";
import { isMobile } from "react-device-detect";
import { Banner } from "../components/Banner";

export default function Profile3() {
  const navigate = useNavigate();
  let { username } = useParams();
  const { user, account, jwt, theme, showSnackbar, log, login } =
    useContext(Web3Context);
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const [otherUser, setOtherUser] = useState(undefined);
  const [hasMatch, setHasMatch] = useState(false);
  const [selectedBond, setSelectedBond] = useState(undefined);
const [bonds, setBonds] = useState([])

  async function selectBond({ bondId, match }) {
    if (user && otherUser._id !== user._id) {
      if (match) {
        setSelectedBond(bondId);
      }
    } else {
      login();
    }
  }

  async function getUserData() {
    try {
      const response = await api.getUserData2({
        username: username,
        jwt: jwt,
      });
      console.log("user data response: ", response)
      if (response.error) {
        setIsLoading(false);
        showSnackbar({ message: response.error, severity: "error" });
        log({
          eventName: "getUserData error",
          eventParams: {
            userUid: user ? user._id : undefined,
            error: response.error,
          },
        });
        return;
      }
      console.log("bonds: ", response.bonds)
      setBonds(response.bonds)
      setHasMatch(false);
      setOtherUser(response.user);
      if (response.bonds) {
        for (const m of response.bonds) {
          if (m.match) {
            setHasMatch(true);
          }
        }
      }

      log({
        eventName: "getUserData",
        eventParams: {
          userUid: user ? user._id : undefined,
        },
      });

      setIsLoading(false);
    } catch (e) {
      showSnackbar({ message: e.message, severity: "error" });
      log({
        eventName: "general error",
        eventParams: {
          userUid: user ? user._id : undefined,
          error: e,
        },
      });
      setIsLoading(false);
      return;
    }
  }

  async function goToEditProfile() {
    navigate("/e");
  }

  async function goToScheduleProfile() {
    //("SELECTED BOND:", selectedBond);
    let q = {
      u: otherUser.username,
    };

    if (typeof selectedBond !== "undefined") {
      q.b = selectedBond;
    }
    const queryParams = new URLSearchParams(q).toString();

    navigate("/s?" + queryParams);
  }

  useEffect(() => {
    getUserData();
  }, [jwt, username]);

  return (
    <div
      style={{
        backgroundColor: `${
          theme === "dark" ? "#030303" : "#fef8f7"
        } !important`,
      }}
    >
      {user && otherUser && otherUser._id === user._id && <Banner />}
      <Banner 
        show={otherUser && !otherUser.hasEmail} 
        text={otherUser && user && otherUser._id === user._id ? "Please sync your email to complete your profile" :"This user is unavailable for meetings"} 
        onClick={otherUser && user && otherUser._id === user._id ? () => {navigate("/e")} : undefined}
      />
      <WidthContainer topPadding={36}>
        {isMobile ? (
          <>
            {otherUser ? (
              <Row>
                <Col>
                  <Row>
                    <Col>
                      <ProfileCard u={otherUser} />
                    </Col>
                  </Row>
                  <SizedBox height={16} />
                  <Row>
                    <Col>
                      <BondsViewer2
                        otherUser={otherUser}
                        user={user}
                        bonds={bonds ? bonds : []}
                        selectedBond={selectedBond}
                        selectBond={selectBond}
                        scheduleMeeting={goToScheduleProfile}
                        editProfile={goToEditProfile}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            ) : (
              <div>
                <LoadingIndicator />
              </div>
            )}
          </>
        ) : (
          <>
            {otherUser ? (
              <Row>
                <Col>
                  <ProfileCard u={otherUser} />
                </Col>
                <Col>
                <BondsViewer2
                    otherUser={otherUser}
                    user={user}
                    bonds={bonds ? bonds : []}
                    selectedBond={selectedBond}
                    selectBond={selectBond}
                    scheduleMeeting={goToScheduleProfile}
                    editProfile={goToEditProfile}
                  />
                </Col>
              </Row>
            ) : (
              <div>
                <LoadingIndicator />
              </div>
            )}
          </>
        )}
      </WidthContainer>
    </div>
  );
}