import React, { useContext, useState } from "react";
import { SpinnerDiamond } from "spinners-react";
import styled from "styled-components";
import { Web3Context } from "../contexts/Web3Context";
import { useAppColors } from "../utils/useAppColors";
import { Center } from "./Center";
import { StandardShadow } from "./StandardShadow";

const SecondaryButtonWrapper = styled.button`
  width: 100%;
  height: ${props => props.height ? props.height : "auto"};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "24px"};
  background-color: ${(props) => props.backgroundColor};
  border-left: 2px solid ${(props) => props.borderColor};
  border-top: 2px solid ${(props) => props.borderColor};
  color: ${(props) => props.color};
  font-weight: bold;
  font-size: ${props => props.fontSize ? props.fontSize : "20px"};
  padding: ${(props) =>
    props.padding ? props.padding : "20px 40px 20px 40px"};
  &:hover {
    background-color: ${(props) => props.hoverBackgroundColor};
  }
`;

const DisabledButtonWrapper = styled.button`
  width: 100%;
  height: ${props => props.height ? props.height : "auto"};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "24px"};
  background-color: ${(props) => props.backgroundColor};
  border-left: 2px solid ${(props) => props.borderColor};
  border-top: 2px solid ${(props) => props.borderColor};
  border-bottom: 2px solid ${(props) => props.borderColor};
  border-right: 2px solid ${(props) => props.borderColor};
  color: ${"black"};
  font-weight: bold;
  font-size: ${props => props.fontSize ? props.fontSize : "20px"};
  padding: ${(props) =>
    props.padding ? props.padding : "20px 40px 20px 40px"};
`;

export const SecondaryButton = ({
  text,
  children,
  onClick,
  disabled,
  requireUser,
  requireAddress,
  padding,
  borderRadius,
  isLoading,
  height,
  fontSize
}) => {
  const { user, jwt } = useContext(Web3Context);
  const colors = useAppColors();
  //   const [showRequireUser, setShowRequireUser] = useState(false);
  //   const [showRequireAddress, setShowRequireAddress] = useState(false);

  async function safeOnClick() {
    // if (requireUser) {
    //   if (!user || !user.username || !user.displayName || !jwt) {
    //     setShowRequireUser(true);
    //     return;
    //   }
    // }

    // if (requireAddress) {
    //   if (!user || !user.shippingAddress) {
    //     setShowRequireAddress(true);
    //     return;
    //   }
    // }
    onClick();
  }

  if (disabled) {
    //(disabled);
    return (
      <div>
        <StandardShadow borderRadius={borderRadius ? borderRadius : "24px"}>
          <DisabledButtonWrapper
            borderRadius={borderRadius}
            disabled={disabled}
            padding={padding}
            backgroundColor={colors.textFieldColor}
            borderColor={colors.textColor}
            height={height}
            fontSize={fontSize}
          >
            {!isLoading ? (
              <>{text ? text : <>{children}</>}</>
            ) : (
              <>
                <Center>
                  <SpinnerDiamond size={30} color={colors.primaryColor} />
                </Center>
              </>
            )}
          </DisabledButtonWrapper>
        </StandardShadow>
      </div>
    );
  }
  return (
    <div>
      <StandardShadow borderRadius={borderRadius ? borderRadius : "24px"}>
        <SecondaryButtonWrapper
          disabled={disabled}
          onClick={safeOnClick}
          padding={padding}
          borderRadius={borderRadius}
          color={colors.textFieldTextColor}
          borderColor={"black"}
          backgroundColor={colors.activeTextFieldColor}
          hoverBackgroundColor={colors.textFieldColor}
          height={height}
          fontSize={fontSize}

        >
          {!isLoading ? (
            <>{text ? text : <>{children}</>}</>
          ) : (
            <>
              <Center>
                <SpinnerDiamond size={30} color={colors.primaryColor} />
              </Center>
            </>
          )}
        </SecondaryButtonWrapper>
      </StandardShadow>
    </div>
  );
};
