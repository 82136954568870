import React, { useContext } from "react";

import { Col, Modal, Dropdown, DropdownButton, Row } from "react-bootstrap";
import { Center } from "./Center";
import { SizedBox } from "./SizedBox";
import { TitleText } from "./TitleText";
import styled from "styled-components";
import GoogleSigninButton from "../google_signin_button_light.png";
import { FlatButton } from "./FlatButton";
import { Web3Context } from "../contexts/Web3Context";
import { BrowserView, MobileView } from "react-device-detect";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import copyToClipboard from "copy-to-clipboard";

const GoogleImgButton = styled.img`
  cursor: pointer;
  width: 50%;
`;

function GoogleButton({ connectGoogle }) {
  return <GoogleImgButton src={GoogleSigninButton} onClick={connectGoogle} />;
}

export default function EmailModal({
  show,
  setShow,
  connectGoogle,
  disconnectAuth,
  authUrl
}) {
  const { user, showSnackbar } = useContext(Web3Context);
  return (
    <>
      <BrowserView>
        <Modal
          dialogClassName="modal-60w"
          show={show}
          onHide={() => {
            setShow(false);
          }}
        >
          <Modal.Body>
            <Center>
              <Row style={{ width: "80%" }}>
                <Col>
                  <SizedBox height={24} />
                  <TitleText>Sync Email</TitleText>
                  <SizedBox height={24} />
                  <Center>
                    <GoogleButton connectGoogle={connectGoogle} />
                  </Center>
                  <SizedBox height={24} />

                  {user && user.email && (
                    <Center>
                      <FlatButton onClick={disconnectAuth}>
                        Disconnect Email
                      </FlatButton>
                    </Center>
                  )}
                  <SizedBox height={36} />
                </Col>
              </Row>
            </Center>
          </Modal.Body>
        </Modal>
      </BrowserView>
      <MobileView>
        <BottomSheet
          open={show}
          onDismiss={() => {
            setShow(false);
          }}
        >
          <Center>
            <Row style={{ width: "90%" }}>
              <Col>
                <SizedBox height={24} />
                <TitleText>Sync Email</TitleText>
                <SizedBox height={24} />
                <Center>
                  {authUrl && 
                    <Center>
                      <GoogleButton connectGoogle={() => {
                        //  window.open(authUrl, '_system')
                        const copiedString = copyToClipboard(authUrl);
                        showSnackbar({message: "Copied link to Google Signin, Please paste this into a non web3 browser", severity: "success"})
                        // window.location = `intent:${authUrl}#Intent;end`;
                      }} />
                    </Center>
                  }
                </Center>
                <SizedBox height={24} />

                {user && user.email && (
                  <Center>
                    <FlatButton onClick={disconnectAuth}>
                      Disconnect Email
                    </FlatButton>
                  </Center>
                )}
                <SizedBox height={36} />
              </Col>
            </Row>
          </Center>
        </BottomSheet>
      </MobileView>
    </>
  );
}
