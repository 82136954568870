import localStorage from "localStorage";

function setLocalStorageJWT(jwt) {
  if (jwt) {
    localStorage.setItem("jwt", JSON.stringify(jwt));
  } else {
    localStorage.removeItem("jwt");
  }
}

function setLocalStorageUser(user) {
  if (user) {
    localStorage.setItem("user", JSON.stringify(user));
  } else {
    localStorage.removeItem("user");
  }
}

function setLocalStorageTheme(theme) {
  if (theme) {
    localStorage.setItem("theme", theme);
  } else {
    localStorage.setItem("theme", "light");
  }
}

function getLocalStorageUser() {
  const r = localStorage.getItem("user");
  if (!r) {
    return;
  }
  return JSON.parse(r);
}

function setLocalStorageOneTimeLinkSecret({
  oneTimeLinkUid,
  oneTimeLinkSecret,
}) {
  localStorage.setItem(oneTimeLinkUid, oneTimeLinkSecret);
}

function getLocalStorageOneTimeLinkSecret({ oneTimeLinkUid }) {
  const r = localStorage.getItem(oneTimeLinkUid);
  if (!r) {
    return;
  }
  return r.toString();
}

function getLocalStorageJwt() {
  const r = localStorage.getItem("jwt");
  if (!r) {
    return;
  }
  return JSON.parse(r);
}

function getLocalStorageTheme() {
  const r = localStorage.getItem("theme");
  if (!r) {
    return;
  }
  return r;
}

const localstorage = {
  setLocalStorageUser,
  setLocalStorageJWT,
  getLocalStorageUser,
  getLocalStorageJwt,
  setLocalStorageTheme,
  getLocalStorageTheme,
  setLocalStorageOneTimeLinkSecret,
  getLocalStorageOneTimeLinkSecret,
};

export default localstorage;
