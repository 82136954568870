import { Col, Row } from "react-bootstrap";
import TimeCard from "./TimeCard";
import styled from "styled-components";
import Moment from "react-moment";
import { useEffect, useState } from "react";
import { SizedBox } from "./SizedBox";
import { TitleText } from "./TitleText";
import { SubtitleText } from "./SubtitleText";
import { useAppColors } from "../utils/useAppColors";
import BondView from "./BondView";
import { Center } from "./Center";
import { BrowserView, MobileView } from "react-device-detect";
import { DescriptionText } from "./DescriptionText";
import moment from "moment";

const CurrentDayText = styled.div`
  text-align: left;
  font-size: 20px;
  font-weight: bold;
`;

export default function ScheduleSidebar({
  selectTime,
  busy,
  timeZone,
  selectedDay,
  selectedTime,
  bond,
  timeSegments,
  setTimeSegment,
  timeSegment,
  timeZoneOffset,
  localTimeZoneOffset,
  otherUserTimeZone,
  otherUserDayStart,
  otherUserDayEnd,
}) {
  const colors = useAppColors();
  const [dayBusy, setDayBusy] = useState(undefined);
  const [freeDay15, setFreeDay15] = useState([]);
  const [freeDay30, setFreeDay30] = useState([]);
  const [freeDay45, setFreeDay45] = useState([]);
  const [freeDay60, setFreeDay60] = useState([]);
  const [freeDay, setFreeDay] = useState([]);

  function getFreeWithTime({ busy, timeMinutes }) {

    const timeIncMs = timeMinutes * 60 * 1000;
    const now = moment().tz(otherUserTimeZone);
    let startDay = moment(selectedDay).tz(otherUserTimeZone);

    let endDay = moment(selectedDay).tz(otherUserTimeZone);

    const startHours =
      otherUserDayStart && otherUserDayStart.hour ? otherUserDayStart.hour : 9;
    const startMinutes =
      otherUserDayStart && otherUserDayStart.minute
        ? otherUserDayStart.hour
        : 0;
    const startSeconds =
      otherUserDayStart && otherUserDayStart.second
        ? otherUserDayStart.second
        : 0;

    const endHours =
      otherUserDayEnd && otherUserDayEnd.hour ? otherUserDayEnd.hour : 17;
    const endMinutes =
      otherUserDayEnd && otherUserDayEnd.minute ? otherUserDayEnd.minute : 0;
    const endSeconds =
      otherUserDayEnd && otherUserDayEnd.second ? otherUserDayEnd.second : 0;

    startDay
      .startOf("day")
      .set("hour", startHours)
      .set("minute", startMinutes)
      .set("second", startSeconds);
    endDay
      .startOf("day")
      .set("hour", endHours)
      .set("minute", endMinutes)
      .set("second", endSeconds);

    let startDayMs = startDay.valueOf();
    const endDayMs = endDay.valueOf();



    const sdt = now.valueOf();
    if (startDayMs < sdt) {
      const leftover = sdt % (5 * 60 * 1000);
      startDayMs = sdt + (5 * 60 * 1000 - leftover);
    }

    let i = startDayMs;

    let freeTime = [];

    while (i <= endDayMs - timeIncMs) {
      let foundBusy = false;

      const startDate = i;
      const endDate = i + timeIncMs;

      for (const b of busy) {
        const busyStart = moment(new Date(b.start)).valueOf();
        const busyEnd = moment(new Date(b.end)).valueOf();

        if (endDate > busyStart && startDate < busyEnd) {
          // BUSY
          foundBusy = true;

          i = busyEnd;
        }
      }
      if (!foundBusy) {
        const st = moment(startDate).tz(timeZone)
        const en = moment(endDate).tz(timeZone)
        freeTime.push({
          start: st,
          end: en,
        });
        i = endDate;
      }
    }

    return freeTime;
  }

  function getFreeWithTime2({ busy, timeMinutes }) {
    const timeIncMs = timeMinutes * 60 * 1000;
    const dayMs = 24 * 60 * 60 * 1000;
    let startDay = new Date(selectedDay.getTime());
    let endDay = new Date(selectedDay.getTime());

    var now = moment().utc();
    // get the zone offsets for this time, in minutes
    let t = typeof timeZone === "string" ? timeZone : timeZone.value;
    var otherTzOffset = moment.tz.zone(otherUserTimeZone).offset(now) / 60;
    startDay.setHours(9 - otherTzOffset, 0, 0, 0);
    endDay.setHours(17 - otherTzOffset, 0, 0, 0);
    let dayStartMs = startDay.getTime();

    const sdt = selectedDay.getTime();
    if (dayStartMs < sdt) {
      const leftover = sdt % (5 * 60 * 1000);
      dayStartMs = sdt + (5 * 60 * 1000 - leftover);
    }

    const dayEndMs = endDay.getTime();

    let freeTime = [];

    let i = dayStartMs;

    while (i <= dayEndMs - timeIncMs) {
      let foundBusy = false;
      const startDate = new Date(i);
      const endDate = new Date(i + timeIncMs);

      for (const b of busy) {
        const busyStart = new Date(b.start);
        const busyEnd = new Date(b.end);
        if (endDate > busyStart && startDate < busyEnd) {
          // BUSY
          foundBusy = true;
          i = busyEnd.getTime();
        }
      }
      if (!foundBusy) {
        freeTime.push({
          start: startDate,
          end: endDate,
        });
        i = endDate.getTime();
      }
    }

    return freeTime;
  }

  function getDayBusy() {
    if (!busy) {
      return;
    }
    let daySpecBusy = [];
    const selectedDayEnd = new Date(selectedDay.getTime() + 24 * 60 * 60000);
    for (const b of busy) {
      const start = new Date(b.start);
      const end = new Date(b.end);
      if (end <= selectedDay || start >= selectedDayEnd) {
      } else {
        daySpecBusy.push(b);
      }
    }
    setDayBusy(daySpecBusy);
    const free = getFreeWithTime({
      busy: daySpecBusy,
      timeMinutes: timeSegment.timeMinutes,
    });
    setFreeDay(free);
  }

  useEffect(() => {
    getDayBusy();
  }, [selectedDay, timeSegment, timeZoneOffset]);

  return (
    <>
      <BrowserView>
        <Row>
          <Col style={{ height: "80vh", overflowY: "scroll" }}>
            <SubtitleText textAlign={"left"} color={colors.titleColor}>
              <Moment format="dddd DD, MMMM yyyy">{selectedDay}</Moment>
            </SubtitleText>
            <SizedBox height={16} />
            <>
              {(!freeDay || freeDay.length === 0) && (
                <DescriptionText>No Available Times</DescriptionText>
              )}
            </>
            {freeDay && freeDay.length > 0 && freeDay[0].start ? (
              <>
                {freeDay.map((a, i) => (
                  <div key={(a.start ? a.start.toString() : "") + i.toString()}
                  >
                    <TimeCard
                      time={a}
                      selectedTime={selectedTime}
                      selectTime={selectTime}
                    />
                    <SizedBox height={12} />
                  </div>
                ))}
              </>
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </BrowserView>
      <MobileView>
        <Row>
          <Col style={{ height: "80vh", overflowY: "scroll" }}>
            <SubtitleText textAlign={"left"} color={colors.titleColor}>
              <Moment format="dddd DD, MMMM yyyy">{selectedDay}</Moment>
            </SubtitleText>
            <SizedBox height={16} />
            <>
              {(!freeDay || freeDay.length === 0) && (
                <DescriptionText>No Available Times</DescriptionText>
              )}
            </>
            {freeDay && freeDay.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                }}
              >
                {freeDay.map((a, i) => (
                  <div style={{ width: "45%" }} key={(a.start ? a.start.toString() : "")  + i.toString()}>
                    <TimeCard
                      time={a}
                      selectedTime={selectedTime}
                      selectTime={selectTime}
                    />
                    <SizedBox height={12} />
                  </div>
                ))}
              </div>
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </MobileView>
    </>
  );
}
