import React from "react";
import styled from "styled-components";

const CenterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Center = (props) => {
  return <CenterWrapper>{props.children}</CenterWrapper>;
};
