import { useContext } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { WidthContainer } from "../components/WidthContainer";
import { Web3Context } from "../contexts/Web3Context";
import styled from "styled-components";
import { Gradient } from 'react-gradient';

const gradients = [
    ['#bd19d6', '#ea7d10'],
    ['#ff2121', '#25c668'],
];
const CoolCard = styled.div`
  width: 90%;
  backdrop-filter: blur(10px);
`;

const GradientWrapper = styled.div`
`

export default function Link() {
    const { user } = useContext(Web3Context);
    return (
        <>
            <BrowserView>
                <WidthContainer>
                        <Gradient
                            gradients={gradients}
                            property="background"
                            duration={3000}
                            angle="45deg"
                        />
                    <CoolCard>

                    </CoolCard>
                </WidthContainer>
            </BrowserView>
            <MobileView>
                <WidthContainer>

                </WidthContainer>
            </MobileView>
        </>
    );
}
