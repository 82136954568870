import styled from "styled-components";

export const FooterLink = styled.a`
  color: #000;
  margin-bottom: 5px;
  font-size: 12px;
  text-decoration: none;

  &:hover {
    color: #b626de;
    transition: 200ms ease-in;
  }
`;

export const Heading = styled.p`
  font-size: 18px;
  color: #bb5bfe;
  margin-bottom: 10px;
  font-weight: bold;
`;
