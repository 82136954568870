import { BigNumber } from "ethers";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { BrowserView, MobileView } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Web3Context } from "../contexts/Web3Context";
import { useAppColors } from "../utils/useAppColors";
import { DescriptionText } from "./DescriptionText";
import { DynamicImage } from "./DynamicImage";
import { SizedBox } from "./SizedBox";
import { StandardShadow } from "./StandardShadow";
import { SubtitleText } from "./SubtitleText";

const PaymentListItemWrapper = styled.div`
  color: "black";
  border-radius: 16px;
  padding: 12px;
  margin-bottom: 16px;
  border: 2px solid black;
`;


export function PaymentListItem({ payment }) {
  const colors = useAppColors();
  const { user } = useContext(Web3Context)
  const navigate = useNavigate();
  const [paymentText, setPaymentText] = useState("")
  const [otherUser, setOtherUser] = useState({
    userId: undefined,
    username: "Someone"
  })
  const [isFromUser, setIsFromUser] = useState(false)


  function generatePaymentEthText() {
    let fu = payment.fromAddress.toLowerCase() === user.evmAddress.toLowerCase()

    let numb
    try {
      numb = BigNumber.from(payment.quantity) / BigNumber.from(10).pow(payment.tokenDecimals)
    } catch (e) {

    }

    numb = (fu ? "-" : "+") + numb + ` ${payment.tokenSymbol} ($${payment.usdValue})`
    setPaymentText(numb)
  }

  function getOtherUser() {
    let fu = payment.fromAddress.toLowerCase() === user.evmAddress.toLowerCase()
    let oUserId
    let oUserAddress
    let oUsername
    let oAvatarUrl
    let oServiceId
    let oServiceUserId
    if (fu) {
      oUserId = payment.toUserId
      oUserAddress = payment.toAddress
      oUsername = payment.toUserUsername
      oAvatarUrl = payment.toUserAvatarUrl
      oServiceId = payment.serviceId
      oServiceUserId = payment.oServiceUserId
    } else {
      oUserId = payment.fromUserId
      oUserAddress = payment.fromAddress
      oUsername = payment.fromUserUsername
      oAvatarUrl = payment.fromUserAvatarUrl
    }
    let u = {
      _id: oUserId,
      username: oUsername,
      evmAddress: oUserAddress,
      avatarUrl: oAvatarUrl,
      serviceId: oServiceId,
      twitterUserId: oServiceUserId
    }

    setOtherUser(u)
    setIsFromUser(fu)
  }

  useEffect(() => {
    generatePaymentEthText()
    getOtherUser()
  }, [payment])

  return (
    <>
      <BrowserView>
        <StandardShadow borderRadius={"16px"} key={payment._id}>
          <PaymentListItemWrapper
            color={"white"}
            className="lighter-active-gradient"
            backgroundColor={colors.primaryColor}
            onClick={() => {
              if (otherUser.username && otherUser._id) {
                navigate(`/${otherUser.username}`)
              }
            }}
          >
            <Row>
              <Col>
                <Row>
                  <Col xs="2">
                    <DynamicImage height="50px" borderRadius={"5000px"} imageUrl={otherUser.avatarUrl} />
                  </Col>
                  <SizedBox width="12px" />
                  <Col className="d-flex align-items-start flex-column">
                    <SubtitleText fontSize={"18px"} color={"black"}>
                      {otherUser.username ? otherUser.username : "Someone"}
                    </SubtitleText>
                    <DescriptionText color={colors.primaryColor} fontSize="14px">
                      {moment(new Date(payment.createdAt)).format('D MMMM YYYY')}
                    </DescriptionText>
                    <DescriptionText>
                      {payment.message}
                    </DescriptionText>
                  </Col>
                </Row>
              </Col>
              <Col>

                <Row>
                  <Col className="d-flex justify-content-end">
                    <SubtitleText fontSize={"18px"} color={isFromUser ? colors.errorRed : colors.successGreen}>
                      {paymentText}
                    </SubtitleText>
                  </Col>
                </Row>
                <SizedBox height="8px" />
                <Row>
                  <Col className="d-flex justify-content-end">
                    <div className="d-flex justify-content-end">
                      <div style={{ borderRadius: "14px", backgroundColor: colors.primaryColor, padding: "4px 12px 4px 12px", fontWeight: "bold", color: "white" }}>
                        {payment.serviceId ? payment.serviceId : "direct address"}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>

          </PaymentListItemWrapper>
        </StandardShadow>
      </BrowserView>
      <MobileView>
        <StandardShadow borderRadius={"16px"} key={payment._id}>
          <PaymentListItemWrapper
            color={"white"}
            className="lighter-active-gradient"
            backgroundColor={colors.primaryColor}
            onClick={() => {
              if (otherUser.username && otherUser._id) {
                navigate(`/${otherUser.username}`)
              }
            }}
          >
            <Row>
              <Col>
                <Row>
                  <Col xs="2">
                    <DynamicImage height="50px" borderRadius={"5000px"} imageUrl={otherUser.avatarUrl} />
                  </Col>
                  <SizedBox width="12px" />
                  <Col className="d-flex align-items-start flex-column">
                    <SubtitleText fontSize={"14px"} color={"black"}>
                      {otherUser.username ? otherUser.username : "Someone"}
                    </SubtitleText>
                    <DescriptionText color={colors.primaryColor} fontSize="12px">
                      {moment(new Date(payment.createdAt)).format('D M YYYY')}
                    </DescriptionText>
        
                  </Col>
                </Row>
              </Col>
              <Col>

                <Row>
                  <Col className="d-flex justify-content-end">
                    <SubtitleText fontSize={"14px"} color={isFromUser ? colors.errorRed : colors.successGreen}>
                      {paymentText}
                    </SubtitleText>
                  </Col>
                </Row>
                <SizedBox height="8px" />
                <Row>
                  <Col className="d-flex justify-content-end">
                    <div className="d-flex justify-content-end">
                      <div style={{ borderRadius: "14px", backgroundColor: colors.primaryColor, padding: "4px 12px 4px 12px", fontSize: "12px", fontWeight: "bold", color: "white" }}>
                        {payment.serviceId ? payment.serviceId : "direct address"}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
            <DescriptionText>
                      {payment.message}
                    </DescriptionText>
            </Row>
          </PaymentListItemWrapper>
        </StandardShadow>
      </MobileView>
    </>
  );
}
