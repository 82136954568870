import { useState, useEffect } from "react";
import moment from "moment";
import React from "react";
import Chevron from "react-chevron";
import styled from "styled-components";
import { bgcolor } from "@mui/system";
import { Col, Row, Table } from "react-bootstrap";
import { useAppColors } from "../utils/useAppColors";
import { isMobile } from "react-device-detect";

const CalWrapper = styled.div`
    border: 0px solid #bb5bfe;
    padding: 10px;
    margin: 0;
    height: ${((props) => props.height ? props.height : "100%")};
    background-color: ${((props) => props.bgColor)}; 
    borderRadius: 24;
    width: ${((props) => props.width ? props.width : "90%")};
`;

const DateDiv = styled.div`
  background-color: #f0dcff;
  border-radius: 16px;
  text-align: center;
  justify-content: center;
  padding: 4px;
  line-height: 50px;
  font-weight: bold;
  color: #bb5bfe;
  font-size: 14px;
  cursor: pointer;
  width: 100%;
  &:hover {
  background-color: #d6b6ee !important;
  }
`;

const DayPast = styled.div`
  color: #b7b7b7;
  line-height: 24px;
  font-size: 14px;
  text-align: center;
  justify-content: center;
  padding: 4px;
  line-height: 50px;
`;

export default function Calendar({
  onAddMonth,
  onDayClick,
  allowedDays,
  startDate,
  endDate,
  width
}) {
  //const declarations
  const weekdaysShort = moment.weekdaysShort();
  const [dateContext, setDateContext] = useState(moment());
  const colors = useAppColors();
  //calendar consts
  const year = () => {
    return dateContext.format("Y");
  };
  const month = () => {
    return dateContext.format("MMMM");
  };
  const daysInMonth = () => {
    return dateContext.daysInMonth();
  };
  const currentDay = () => {
    return dateContext.format("D");
  };
  const firstDayOfMonth = () => {
    let dc = dateContext;
    let firstDay = moment(dc).startOf("month").format("d");
    return firstDay;
  };

  const darr = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

  //calendar functions
  const addMonth = (num) => {
    resetColors();
    let dateCon = {};
    dateCon = moment(dateContext).add(num, "month");
    setDateContext(dateCon);
    onAddMonth && onAddMonth();
  };

  //event handlers
  const DayClick = (e, day) => {
    resetColors();

    document.getElementById(day).firstChild.style.backgroundColor =
      colors.primaryColor;
    document.getElementById(day).firstChild.style.color = "white";

    let todayHour =
      day === dateContext.format("D") ? dateContext.format("H") : 0;
    let todayMin =
      day.toString() === dateContext.format("D").toString()
        ? dateContext.format("m")
        : 0;
    let dateSelected = new Date(
      dateContext.format("Y"),
      dateContext.format("M") - 1,
      day,
      todayHour,
      todayMin
    );

    onDayClick && onDayClick(e, dateSelected);
  };

  //build calendar parts
  // month and year labels used to have functionality to select value via dropdown/text input
  const MonthNav = () => {
    return <span className="label-month">{month()}</span>;
  };

  const YearNav = () => {
    return <span className="label-year">{year()}</span>;
  };

  //map weekday labels
  let weekdays = weekdaysShort.map((day) => {
    let dayUpper = day.toUpperCase();
    return (
      <td key={day} className="week-day">
        {dayUpper}
      </td>
    );
  });


  //create blank divs for previous month days before the 1st
  let blanks = [];
  for (let i = 0; i < firstDayOfMonth(); i++) {
    blanks.push(
      <td key={i * 80} className="emptySlot">
        {""}
      </td>
    );
  }

  //set params for unavailable days
  let days = [];
  let daysPassed = true;
  const daysArr = [0, 1, 2, 3, 4, 5, 6];
  let unavailDays = [];
  if (allowedDays !== undefined) {
    let availableDays = [];
    for (const d of darr) {
      if (allowedDays.includes(d)) {
        availableDays.push(darr.indexOf(d));
      }
    }
    unavailDays = daysArr.filter((x) => !availableDays.includes(x));
  } else {
    unavailDays = [0, 6];
  }

  //loop through all days and assign day-past, day-current, or day
  for (let i = 1; i < daysInMonth() + 1; i++) {
    let className = i.toString() === currentDay() ? "day current-day" : "day";
    if (daysPassed) {
      daysPassed = className === "day current-day" ? false : true;
      if (daysPassed) {
        className = "day-past";
      }
    }
    //set current day of month to regular day if month is not current month
    if (parseInt(dateContext.format("M")) !== parseInt(moment().format("M"))) {
      className = "day";
    }

    //remove all days that land on an unavailable day of the month
    unavailDays.forEach((day) => {
      let date = moment(
        dateContext.format("Y") + "-" + dateContext.format("M") + "-" + i
      );

      if (date.day().toString() === day.toString()) {
        className = "day-past";
      }
    });

    //check if this is a date-restricted or future meeting, otherwise start today
    if (startDate !== undefined) {
      if (parseInt(dateContext.format("M")) <= parseInt(startDate.getMonth())) {
        className = "day-past";
      } else if (
        parseInt(dateContext.format("M")) === parseInt(startDate.getMonth() + 1)
      ) {
        if (i < startDate.getDate()) {
          className = "day-past";
        }
      }

      //Start today id no startdate
      if (parseInt(startDate.format("M")) === parseInt(moment().format("M")) + 1 ) {
        if (i <= moment().format("D")) {
          className = "day-past";
        }
      }
      if (parseInt(startDate.format("M")) > parseInt(moment().format("M")) + 2 ) {
        className = "day-past";
      }
    } else {
      if (parseInt(dateContext.format("M")) < parseInt(moment().format("M"))) {
        className = "day-past";
      } else if (
        parseInt(dateContext.format("M")) === parseInt(moment().format("M"))) {
        if (i < moment().format("D")) {
          className = "day-past";
        }
      }
      if (parseInt(dateContext.format("M")) === parseInt(moment().format("M")) + 1 ) {
        if (i >= moment().format("D")) {
          className = "day-past";
        }
      }
      if (parseInt(dateContext.format("M")) > parseInt(moment().format("M")) + 1 ) {
        className = "day-past";
      }
    }

    //check if this is a time sensitive meeting that needs scheduled by a specific date, otherwise allow up to 1 month in the future
    if (endDate !== undefined) {
      if (dateContext.format("M") > startDate.getMonth() + 1) {
        className = "day-past";
      } else if (
        parseInt(dateContext.format("M")) === parseInt(startDate.getMonth() + 1)
      ) {
        if (i > endDate.getDate() + 1) {
          className = "day-past";
        }
      }
    }

    //push days into the array
    if (className === "day-past") {
      days.push(
        <td width={"10%"}>
        <DayPast>
          {i}
          </DayPast>
        </td>
      );
    } else {
      days.push(
        <td
          id={i}
          className={className}
          onClick={(e) => {
            DayClick(e, i);
          }}
          width={"10%"}
        >
          <DateDiv id={"day" + { i }}>
            <span>{i}</span>
          </DateDiv>
        </td>
      );
    
    }
  }

  //set colors of divs, will remove when I make the css inline (need to do to be able to customize hover color, also tidys up components folder)
  useEffect(() => {
    resetColors();
  }, []);

  //construct calendar from days and blanks
  var totalSlots = [...blanks, ...days];
  let rows = [];
  let cells = [];

  totalSlots.forEach((row, i) => {
    if (i % 7 !== 0) {
      cells.push(row);
    } else {
      let insertRow = cells.slice();
      rows.push(insertRow);
      cells = [];
      cells.push(row);
    }
    if (i === totalSlots.length - 1) {
      let insertRow = cells.slice();
      rows.push(insertRow);
    }
  });
  //map the rows to table rows, give unique key so chrome will stop complaining
  let trElems = rows.map((d, i) => {
    return <tr key={i * 101}>{d}</tr>;
  });
  return (
    <CalWrapper
      width={width ? width : "1500px"}
      bgColor={bgcolor}
      height={isMobile ? undefined : "70vh"}
      >
      <Table>
        <thead>
          <tr style={{fontSize: "1.5em"}}>
            <td colSpan="7">
              <a
                style={{ color: "purple" }}
                onClick={(e) => {
                  addMonth(-1);
                }}
              >
                <Chevron direction={"left"} color="red" />
              </a>
              <MonthNav /> <YearNav />{" "}
              <a
                style={{ color: "purple" }}
                onClick={(e) => {
                  addMonth(1);
                }}
              >
                <Chevron direction={"right"} />
              </a>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>{weekdays}</tr>
          {trElems}
        </tbody>
      </Table>
    </CalWrapper>
  );

  function resetColors() {
    let elems = document.getElementsByClassName("day");
    for (let i = 0; i < elems.length; i++) {
      elems[i].firstChild.style.backgroundColor = colors.calendarDayColor;
      elems[i].firstChild.style.color = colors.primaryColor;
    }
    //let currentDay = document.getElementsByClassName("day current-day");
    //if (currentDay[0] !== undefined) {
    //  currentDay[0].firstChild.style.backgroundColor = colors.calendarCurrentColor;
    //  currentDay[0].firstChild.style.color = colors.primaryColor;
    //}
  }
}
