import QRCodeStyling from "qr-code-styling";
import { useEffect, useRef, useState } from "react";

export default function QRCode({ url, height, width, margin }) {
  const ref = useRef(null);

  useEffect(() => {
    qrCode.append(ref.current);
  }, []);

  useEffect(() => {
    qrCode.update({
      data: url,
    });
  }, [url]);

  const qrCode = new QRCodeStyling({
    width: height ? height : 300,
    height: width ? width : 300,
    image: "./logo512.png",
    dotsOptions: {
      color: "#bd5bfe",
      type: "rounded",
    },
    imageOptions: {
      crossOrigin: "anonymous",
      margin: margin ? margin : 20,
    },
  });

  return <div ref={ref} />;
}
