import { useContext, useEffect, useState } from "react";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import OptionListTile from "./OptionListTile";
import { BiWallet } from "react-icons/bi";
import { BiEditAlt } from "react-icons/bi";
import { FiLogOut } from "react-icons/fi";
import { FiUser } from "react-icons/fi";
import { AiFillCalendar } from "react-icons/ai";
import { SizedBox } from "./SizedBox";
import { WidthContainer } from "./WidthContainer";
import { Center } from "./Center";
import { useAppColors } from "../utils/useAppColors";
import { Web3Context } from "../contexts/Web3Context";
import { useNavigate } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";
import { Col, Modal, Row } from "react-bootstrap";
import { TitleText } from "./TitleText";
import { DynamicImage } from "./DynamicImage";
import IconButton from "./IconButton";
import { FaTimes } from "react-icons/fa";
import { CustomInputWithSideSelector } from "./CustomInputWithSideSelector";
import { TokenSelectModal } from "./TokenSelectModal";
import { PrimaryButton } from "./PrimaryButton";
import utils from "../utils/utils";
import api from "../utils/api";
import { BigNumber, ethers } from "ethers";
import { useLottie } from "lottie-react";
import greenCheckMark from "../green-checkmark-animation.json"
import config from "../utils/config";
import LottieFrame from "./LottieFrame";
import { SubtitleText } from "./SubtitleText";
const ERC20 = require("../utils/abis/ERC20.json");


export default function PayBottomSheet({ username, avatarUrl, open, setOpen, serviceId, userId, userAddress, pendingRule }) {
  const { user, jwt, showSnackbar, log, account, socialMediaRouter, altCoins, eventRouter, setBlockchainData, signer } = useContext(Web3Context);
  const navigate = useNavigate();
  const colors = useAppColors();
  const [isLoading, setIsLoading] = useState(false)
  const [selectedToken, setSelectedToken] = useState(undefined);
  const [showTokenModal, setShowTokenModal] = useState(false);
  const [quantity, setQuantity] = useState("");
  const [tokenBalances, setTokenBalances] = useState([])
  const [hasPaid, setHasPaid] = useState(false)


  async function getTokenBalances() {
    try {
      setIsLoading(true)
      const response = await api.getTokenBalances({ jwt: jwt })
      setTokenBalances(response.tokenBalances)
      setIsLoading(false)
    } catch (e) {
      showSnackbar({ message: e.message, severity: "error" });
      log({
        eventName: "general error",
        eventParams: {
          userUid: user ? user._id : undefined,
          error: e,
        },
      });
      setIsLoading(false);
      return;
    }
  }


  useEffect(() => {
    if (user && !showTokenModal) {
      getTokenBalances()
    }
  }, [user, showTokenModal])

  async function setToken({ token }) {
    setSelectedToken(token);
    setShowTokenModal(false);
  }



  async function pay() {
    try {
      //check if balance is valid
      if (!(serviceId && userId)) {
        throw Error("No User Found")
      }
      setIsLoading(true)
      const balanceOfResp = await api.getTokenBalanceOf({ tokenAddress: selectedToken.address, jwt: jwt })
      if (balanceOfResp.error) {
        throw Error(balanceOfResp.error)
      }
      let am = parseFloat(quantity) * BigNumber.from((10 ** selectedToken.decimals).toString())
      const tc = new ethers.Contract(selectedToken.address, ERC20.abi, signer);

      let b = selectedToken.balance
      let symb = "ETH"
      if (selectedToken.address !== config.zeroAddress) {
        const ba = await tc.balanceOf(account)
        const de = await tc.decimals()
        symb = await tc.symbol()
        b = ba / (10 ** de)

      }
      console.log("BALANCE: ", b)
      if (parseFloat(quantity) > b) {
        throw Error(`${symb} amount (${quantity}) is greater than balance (${b})`)
      }

      if (!eventRouter) {
        throw Error("Connect to the Ethereum Chain")
      }

      let tx
      let ruleId = pendingRule ? pendingRule._id : "payment-rule"
      if (selectedToken.address === config.zeroAddress) {
        // ETHEREUM
        tx = await eventRouter.payEthThroughService(serviceId, userId, ruleId, {
          from: account,
          value: am.toString(),
        });
      } else {
        // ERC20
        //APPROVE TOKEN
        const tokenContract = new ethers.Contract(selectedToken.address, ERC20.abi, signer);
        //go
        console.log("eventRouter: ", eventRouter)
        tx = await tokenContract.approve(eventRouter.address, am.toString())
        await tx.wait();
        const allowance = await tokenContract.allowance(account, eventRouter.address)
        console.log("allowance: ", allowance)
        tx = await eventRouter.payERC20ThroughService(selectedToken.address, am.toString(), serviceId, userId, ruleId, {
          from: account,
          // value: am.toString(),
        });
      }
      const receipt = await tx.wait();
      setHasPaid(true)
      await setBlockchainData(altCoins, socialMediaRouter, eventRouter);
      // showSnackbar({ message: `Payment sent to ${username}!` })
      log({
        eventName: "EventRouter payment",
        eventParams: {
          userUid: user ? user._id : undefined,
          serviceId: serviceId,
          serviceUserId: userId,
          amount: am.toString(),
          tokenAddress: selectedToken.address
        },
      });
      setIsLoading(false)
    } catch (e) {
      showSnackbar({ message: e.message, severity: "error" });
      log({
        eventName: "general error",
        eventParams: {
          userUid: user ? user._id : undefined,
          error: e,
        },
      });
      setIsLoading(false);
      return;
    }
  }

  return (
    <>
      {user && account && (
        <>
          <BrowserView>
            <Modal
              dialogClassName="modal-60w"
              show={open}
              onHide={() => {
                setOpen(false);
              }}
            >
              <Modal.Body>
                {!hasPaid && <Center>
                  <div style={{ width: "90%" }}>
                    <SizedBox height={8} />
                    <Row className="d-flex align-items-end justify-content-end">
                      <Col className="d-flex align-items-end justify-content-end">
                        <IconButton
                          Icon={FaTimes}
                          onClick={() => {
                            setOpen(false);
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="d-flex flex-row justify-content-center">
                      <Col xs={2} className="d-flex align-items-center">
                        <DynamicImage
                          height={"60px"}
                          width={"60px"}
                          hasShadow={true}
                          borderRadius={"5000px"}
                          imageUrl={avatarUrl}
                        />
                      </Col>
                      <Col className="d-flex align-items-center">
                        <TitleText textAlign={"start"}>
                          Pay {username}
                        </TitleText>
                      </Col>
                    </Row>
                    <SizedBox height={32} />
                    <CustomInputWithSideSelector
                      type={"number"}
                      name={"Amount"}
                      placeholder={"0.0"}
                      value={quantity}
                      setValue={setQuantity}
                      selectorImage={selectedToken ? utils.formatIPFStoGateway(selectedToken.logoURI) : undefined}
                      selectorText={selectedToken ? selectedToken.symbol.toString() : "Select Token"}
                      onSelectorClick={() => {
                        setShowTokenModal(true)
                      }}
                    />
                    <Row style={{ width: "100%" }}>
                      <TokenSelectModal
                        show={showTokenModal}
                        setShow={setShowTokenModal}
                        onClick={setToken}
                        initialRule={undefined}
                        tokenBalances={tokenBalances}
                        hasEth={true}
                      />
                    </Row>
                    <SizedBox height={20} />
                    {quantity.length > 0 && parseFloat(quantity) > 0 && <PrimaryButton isLoading={isLoading} text="Pay" onClick={() => { pay() }} />}
                    <SizedBox height={20} />
                  </div>
                </Center>}
                {hasPaid && <Center>
                  <Col>
                    <Center>
                      <LottieFrame width={"60%"} animationData={greenCheckMark} loop={false} />
                    </Center>
                    <SubtitleText color={"black"}>
                      {`Successfully sent ${quantity ? quantity : 0} ${selectedToken && selectedToken.symbol ? selectedToken.symbol : "ETH"}`}
                    </SubtitleText>
                    <SizedBox height={16} />
                    <PrimaryButton text={"Close"} onClick={() => { setOpen(false) }} />
                    <SizedBox height={24} />
                  </Col>
                </Center>}
              </Modal.Body>
            </Modal>
          </BrowserView>
          <MobileView>
            {account ? (
              <>
                <BottomSheet
                  open={open}
                  onDismiss={() => {
                    setOpen(false);
                  }}
                >
                  {!hasPaid && <Center>
                    <div style={{ width: "90%" }}>
                      <SizedBox height={8} />

                      <Row className="d-flex align-items-end justify-content-end">
                        <Col className="d-flex align-items-end justify-content-end">
                          <IconButton
                            Icon={FaTimes}
                            onClick={() => {
                              setOpen(false);
                            }}
                          />
                        </Col>
                      </Row>
                      <Row className="d-flex flex-row justify-content-center">
                        <Col xs={2} className="d-flex align-items-center">
                          <DynamicImage
                            height={"60px"}
                            width={"60px"}
                            hasShadow={true}
                            borderRadius={"5000px"}
                            imageUrl={avatarUrl}
                          />
                        </Col>
                        <SizedBox width="16px" />
                        <Col className="d-flex align-items-center">
                          <TitleText textAlign={"start"}>
                            Pay {username}
                          </TitleText>
                        </Col>
                      </Row>
                      <SizedBox height={"20px"} />
                      <CustomInputWithSideSelector
                        type={"number"}
                        name={"Amount"}
                        placeholder={"0.0"}
                        value={quantity}
                        setValue={setQuantity}
                        selectorImage={selectedToken ? utils.formatIPFStoGateway(selectedToken.logoURI) : undefined}
                        selectorText={selectedToken ? selectedToken.symbol.toString() : "Select Token"}
                        selectorXs={5}
                        onSelectorClick={() => {
                          setShowTokenModal(true)
                        }}
                      />
                      <Row style={{ width: "100%" }}>
                        <TokenSelectModal
                          show={showTokenModal}
                          setShow={setShowTokenModal}
                          onClick={setToken}
                          initialRule={undefined}
                          tokenBalances={tokenBalances}
                          hasEth={true}
                        />
                      </Row>
                      <SizedBox height={20} />
                      {quantity.length > 0 && parseFloat(quantity) > 0 && <PrimaryButton isLoading={isLoading} text="Pay" onClick={() => { pay() }} />}
                      <SizedBox height={20} />
                    </div>
                  </Center>}
                  {hasPaid && <Center>
                    <WidthContainer>
                      <Col>
                        <Center>
                          <LottieFrame width={"60%"} animationData={greenCheckMark} loop={false} />
                        </Center>
                        <SubtitleText color={"black"}>
                          {`Successfully sent ${quantity ? quantity : 0} ${selectedToken && selectedToken.symbol ? selectedToken.symbol : "ETH"}`}
                        </SubtitleText>
                        <SizedBox height={16} />
                        <PrimaryButton text={"Close"} onClick={() => { setOpen(false) }} />
                        <SizedBox height={24} />
                      </Col>
                    </WidthContainer>
                  </Center>}
                  <SizedBox height={32} />
                </BottomSheet>
              </>
            ) : null}
          </MobileView>
        </>
      )}
    </>
  );
}
