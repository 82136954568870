import { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { BrowserView, MobileView } from "react-device-detect";
import { AiOutlineCrown } from "react-icons/ai";
import InfoCard from "../components/InfoCard";
import ProfileCard from "../components/ProfileCard";
import { SizedBox } from "../components/SizedBox";
import { SubtitleText } from "../components/SubtitleText";
import { TitleText } from "../components/TitleText";
import { WidthContainer } from "../components/WidthContainer";
import { Web3Context } from "../contexts/Web3Context";
import { useAppColors } from "../utils/useAppColors";
import styled from "styled-components";
import { MdOutlineBubbleChart } from "react-icons/md";
import { SecondaryButton } from "../components/SecondaryButton";
import SubscriptionBottomSheet from "../components/SubscriptionBottomSheet";
import FeatureListItem from "../components/FeatureListItem";

const SubscriptionCardWrapper = styled.div`
  background-color: ${(props) => props.backgroundColor};
  border: 1px solid ${(props) => props.borderColor};
  border-radius: 24px;
  padding: ${(props) => props.padding};
  cursor: pointer;
`;

function SubscriptionCard({
  children,
  backgroundColor,
  borderColor,
  cardPadding,
  onClick,
}) {
  return (
    <SubscriptionCardWrapper
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      padding={cardPadding}
      onClick={onClick}
    >
      {children}
    </SubscriptionCardWrapper>
  );
}


export function BondMaxOption({ onClick }) {
  const colors = useAppColors();
  return (
    <SubscriptionCard
      backgroundColor={colors.lightPurple}
      borderColor={colors.primaryColor}
      cardPadding={"24px 0px 0px 0px"}
      onClick={onClick}
    >
      <Row>
        <Col>
          <Row>
            <Col xs={4}>
              <SubMonthlyPriceContainer size="small" amount={"$8.99"} />
            </Col>
            <Col xs={1}>
              <div
                style={{
                  width: "1px",
                  height: "100%",
                  backgroundColor: colors.textFieldColor,
                }}
              ></div>
            </Col>
            <Col>
              <SubtitleText textAlign={"start"}>Features</SubtitleText>
              <SizedBox height={12} />
              <FeatureListItem text={"NFT Ownership"} xs={12} />
              <FeatureListItem text={"ETH Balance"} xs={12} />
              <FeatureListItem text={"ERC20 Balance"} xs={12} />
              <FeatureListItem text={"Text Alerts"} xs={12} />
              <FeatureListItem
                text={"Webhook Alerts"}
                comingSoon={true}
                xs={12}
              />
              <FeatureListItem
                text={"ETH Payments"}
                comingSoon={true}
                xs={12}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <SizedBox height={16} />
    </SubscriptionCard>
  );
}

function SubscriptionOption({ onClick }) {
  const colors = useAppColors();
  return (
    <SubscriptionCard
      backgroundColor={"white"}
      borderColor={colors.textFieldColor}
      cardPadding={"24px 0px 0px 0px"}
      onClick={onClick}
    >
      <Col>
        <Row></Row>
        <Row>
          <Col>
            <Row style={{ padding: "0px 24px 12px 24px" }}>
              <Col xs={8}>
                <Row>
                  <Col xs={3}>
                    <MdOutlineBubbleChart
                      size={40}
                      color={colors.primaryColor}
                    />
                  </Col>
                  <Col>
                    <TitleText textAlign={"start"}>$0.00</TitleText>
                  </Col>
                </Row>
              </Col>
              <Col>
                <SubtitleText textAlign={"end"}>Free</SubtitleText>
              </Col>
            </Row>

            <div
              style={{
                backgroundColor: "#f6f6f6",
                padding: "24px",
                borderRadius: "24px 24px 24px 24px",
              }}
            >
              <Row style={{ padding: "0px 24px 12px 0px" }}>
                <Col>
                  <SubtitleText textAlign={"start"}>Features</SubtitleText>
                </Col>
              </Row>
              <Row>
                <FeatureListItem text={"NFT Ownership"} xs={6} />
                <FeatureListItem text={"ETH Balance"} xs={6} />
                <FeatureListItem text={"ERC20 Balance"} xs={6} />
                <FeatureListItem
                  text={"Text Alerts"}
                  xs={6}
                  unavailable={true}
                />
                <FeatureListItem
                  text={"Webhook Alerts"}
                  xs={6}
                  unavailable={true}
                />
                <FeatureListItem
                  text={"Eth Payments"}
                  xs={6}
                  unavailable={true}
                />
              </Row>
            </div>
          </Col>
        </Row>
      </Col>
    </SubscriptionCard>
  );
}

function SubscriptionOptions() {
  return <Row></Row>;
}

function SubMonthlyPriceContainer({ amount, size, Icon }) {
  const colors = useAppColors();
  return (
    <>
      <Col>
        <Row>
          <Col className="d-flex justify-content-end">
            {Icon ? (
              <Icon
                size={size === "small" ? 36 : 48}
                color={colors.primaryColor}
              />
            ) : (
              <AiOutlineCrown
                size={size === "small" ? 36 : 48}
                color={colors.primaryColor}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-end">
            <TitleText fontSize={size === "small" ? "28px" : undefined}>
              {amount}
            </TitleText>
          </Col>
        </Row>
        <Row>
          <Col color={colors.textColor} className="d-flex justify-content-end">
            /Month
          </Col>
        </Row>
      </Col>
    </>
  );
}

function CurrentSubscriptionFeatures({ subActive, setOpen }) {
  const { user } = useContext(Web3Context);
  return (
    <Row>
      <Col>
        <Row>
          <Col className="d-flex align-items-center">
            <TitleText fontSize={"28px"} textAlign={"start"}>
              {subActive ? "Bond.MAX" : "Current Subscription"}
            </TitleText>
          </Col>
          {user && user.subscription && user.subscription.status === "active" && (
            <Col xs={4}>
              <SecondaryButton
                text={"cancel"}
                onClick={() => {
                  setOpen(true);
                }}
              />
            </Col>
          )}
        </Row>
        <Row>
          <Col>
            <SubtitleText textAlign={"start"}>Features</SubtitleText>
          </Col>
        </Row>
        <SizedBox height={"24px"} />
        <Row>
          <Col>
            <InfoCard padding={"24px"}>
              <Row>
                <FeatureListItem text={"NFT Ownership"} xs={6} />
                <FeatureListItem text={"ETH Balance"} xs={6} />
                <FeatureListItem text={"ERC20 Balance"} xs={6} />
                <FeatureListItem
                  text={"Text Alerts"}
                  xs={6}
                  unavailable={!subActive}
                />
                <FeatureListItem
                  text={"Webhook Alerts"}
                  xs={6}
                  unavailable={!subActive}
                  comingSoon={true}
                />
                <FeatureListItem
                  text={"ETH Payments"}
                  xs={6}
                  unavailable={!subActive}
                  comingSoon={true}
                />
              </Row>
            </InfoCard>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

function CurrentSubscriptionSection({ subActive, setOpen }) {
  const colors = useAppColors();

  return (
    <>
      <Row>
        <Col xs={3} className="d-flex flex-row align-items-center">
          <SubMonthlyPriceContainer
            amount={subActive ? "$8.99" : "$0.00"}
            Icon={subActive ? undefined : MdOutlineBubbleChart}
          />
        </Col>
        <Col xs={1}>
          <div
            style={{
              width: "1px",
              height: "100%",
              backgroundColor: colors.textFieldColor,
            }}
          ></div>
        </Col>
        <Col>
          <CurrentSubscriptionFeatures
            subActive={subActive}
            setOpen={setOpen}
          />
        </Col>
      </Row>
    </>
  );
}

export default function SubscriptionPage() {
  const { user } = useContext(Web3Context);
  const [subActive, setSubActive] = useState(false);
  const [subOpen, setSubOpen] = useState(false);

  useEffect(() => {
    if (user && user.subscription && user.subscription.status === "active") {
      setSubActive(true);
    } else {
      setSubActive(false);
    }
  }, [user]);
  return (
    <>
      <BrowserView>
        <WidthContainer topPadding={36}>
          <Row>
            <Col xs={4}>
              <ProfileCard u={user} subActive={subActive} />
            </Col>
            <Col>
              <CurrentSubscriptionSection
                subActive={subActive}
                setOpen={setSubOpen}
              />
              <SizedBox height={32} />
              <Row>
                <Col>
                  <SubscriptionOption
                    onClick={() => {
                      setSubOpen(true);
                    }}
                    subActive={subActive}
                  />
                </Col>
                <Col>
                  <BondMaxOption
                    onClick={() => {
                      setSubOpen(true);
                    }}
                    subActive={subActive}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </WidthContainer>
      </BrowserView>
      <MobileView>
        <WidthContainer topPadding={36}></WidthContainer>
      </MobileView>
      <SubscriptionBottomSheet
        open={subOpen}
        setOpen={setSubOpen}
        subActive={subActive}
      />
    </>
  );
}
