import { Col, Row } from "react-bootstrap";
import api from "../utils/api";
import { useAppColors } from "../utils/useAppColors";
import moment from "moment";
import Calendar from "./calendar";
import TodaySnapshot from "./TodaySnapshot";
import { useEffect, useState} from "react";

export default function MyCalendar({user, jwt}) {
    const [snapshot, setSnapshot] = useState()
    const colors = useAppColors();

    async function getUserEvents(d) {
        const response = await api.getEvents({
            jwt: jwt
        });
        console.log(response.events);
        let todaysEvents = [];
        if(response.events != null)
        {
    
            for(const event of response.events) {
                let date = moment(event.startDate);
                let day = moment(d);
                console.log(parseInt(date.format("D")))
                if(parseInt(date.format("D")) == parseInt(day.format("D"))){
                    todaysEvents.push(event);
                }
            };
            
            for(let i = 0; i < todaysEvents.length; i++)
            {
                console.log(moment(todaysEvents[i].startDate));
                let time = moment(todaysEvents[i].startDate).format("HH");
                console.log(time);
                let min = i;
                for(let j = i+1; j < todaysEvents.length; j++)
                {
                    let time2 = moment(todaysEvents[j].startDate).format("HH");
                    if(time2 < time)
                    {
                        min = j;
                    }
                }
                if(min != i)
                {
                    let tmp = todaysEvents[i];
                    todaysEvents[i] = todaysEvents[min];
                    todaysEvents[min] = tmp;
                }
            }
            console.log(todaysEvents);
    
            if(todaysEvents.length != 0)
            {
            setSnapshot(<TodaySnapshot 
            events={todaysEvents}
            t={d}
            uid={user._id}
            jwt={jwt}
            />)
            }
            else
            {
                setSnapshot(<TodaySnapshot noEvents={true} t={d}/>)
            }
        }
        else
        (
            setSnapshot(<p>There was an error fetching the events.</p>)
        )
    
    }

    useEffect(() => {
        getUserEvents(moment())
      }, []);
return(
<Row>
<Col>
    <Calendar
      onDayClick={(e, day) => {
        getUserEvents(day);
      }}
      allowedDays={["sun","mon", "tue", "wed", "thu", "fri", "sat"]}
    />
</Col>
<Col>
    {snapshot}
</Col>
</Row>
)
}