import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

function popover(popoverTitle, popoverBody) {
  return (
    <Popover id="popover-basic">
      <Popover.Header as="h3" style={{fontFamily:"Poppins"}}>{popoverTitle}</Popover.Header>
      <Popover.Body style={{fontFamily:"Poppins"}}>{popoverBody}</Popover.Body>
    </Popover>
  );
}

const PopoverWrapper = ({
  children,
  popoverTitle,
  popoverBody,
  trigger,
  placement,
}) => (
  <OverlayTrigger
    trigger={trigger ? trigger : "hover"}
    placement={placement ? placement : "top"}
    overlay={popover(popoverTitle, popoverBody)}
  >
    {children}
  </OverlayTrigger>
);

export default PopoverWrapper;
