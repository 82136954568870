import React from "react";
import styled from "styled-components";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import { SizedBox } from "./SizedBox";

const WidthContainerWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const WidthContainerCenter = styled.div`
  width: ${(props) => (props.width ? props.width : "65%")};

  @media (max-width: 1024px) {
    width: 45%;
  }
`;

const MobileContainerCenter = styled.div`
  width: 90%;
`;

export const WidthContainer = (props) => {
  return (
    <>
      <BrowserView>
      <SizedBox height={props.topPadding} /> 

        <WidthContainerWrapper>
          <WidthContainerCenter width={props.width}>
            {props.children}
          </WidthContainerCenter>
        </WidthContainerWrapper>
      </BrowserView>
      <MobileView>
      <SizedBox height={props.topPadding} /> 
        <WidthContainerWrapper>
          <MobileContainerCenter>{props.children}</MobileContainerCenter>
        </WidthContainerWrapper>
      </MobileView>
    </>
  );
};
