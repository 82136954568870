import moment from "moment";
import { useEffect, useState } from "react";
import api from "../utils/api";
import { StandardShadow } from "./StandardShadow";
import { useAppColors } from "../utils/useAppColors";
import { SecondaryButton } from "./SecondaryButton";
import {Navigate} from "react-router-dom";
import { SelectAllRounded } from "@material-ui/icons";

export default function EventCard ({jwt, userIds, uid, start}) {
    const [card, setCard] = useState(<SecondaryButton
        disabled={true}
        text={"Fetching Event"}
        />)

    const colors = useAppColors();
    const meetTime = moment(start).format("H:mm A");

    let idList = []
    for (const id of userIds){
        if(id !== uid){
            idList = [...idList, id] 
        }
    }

    const getNames = async () => {
        console.log(userIds);
        const response = await api.getUsers({jwt: jwt, userIds: idList});
        console.log(response.users);
        let uList = []
        for (const user of response.users){
            uList = [...uList, user.username] 
        }
        console.log(uList)
        setCard(<SecondaryButton
                disabled={true}
                text={meetTime + " | " + uList.join(" & ")}
                />)
    }

    useEffect(() => {

        getNames();
        

    }, []);

    return(
        <div>
            {card}
        </div>
    )
}