import { useContext } from "react";
import { Col } from "react-bootstrap";
import ProfileCard from "../components/ProfileCard";
import { SizedBox } from "../components/SizedBox";
import { TitleText } from "../components/TitleText";
import { WidthContainer } from "../components/WidthContainer";
import { Web3Context } from "../contexts/Web3Context";

export function TwitterVerifiedPage() {
  const { user } = useContext(Web3Context);
  return (
    <WidthContainer topPadding={36}>
      <Col>
        <SizedBox height={"32px"} />
        <TitleText>Twitter Successfully Linked! 🔗</TitleText>
        <SizedBox height={"24px"} />
        <ProfileCard u={user} />
      </Col>
    </WidthContainer>
  );
}
