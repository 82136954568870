import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { DynamicImage } from "./DynamicImage";
import { SizedBox } from "./SizedBox";
import utils from "../utils/utils";
import { useAppColors } from "../utils/useAppColors";
import specialSymbols from "../utils/specialSymbols";
import { BigNumber } from "ethers";

const ERC20ListItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  margin-bottom: 8px;
  cursor: pointer;
`;

const TokenNameWrapper = styled.div``;
const TokenSymbolWrapper = styled.div`
  color: ${(props) => props.color};
`;

export default function ERC20ListItem({ token, onClick }) {
  const colors = useAppColors();
  const [name, setName] = useState(token.name);
  const [symbol, setSymbol] = useState(token.symbol);
  const [lowerCaseAddress, setLowerCaseAddress] = useState(
    token.address.toLowerCase()
  );


  useEffect(() => {
    const lca = token.address.toLowerCase();
    setLowerCaseAddress(lca);
    setName(token.name);
    setSymbol(token.symbol);
    const data = specialSymbols.data[lca];
    if (data) {
      if (data.symbol) {
        setSymbol(data.symbol);
      }
      if (data.name) {
        setName(data.name);
      }
    }
  }, [token]);

  return (
    <ERC20ListItemWrapper onClick={onClick}>
      <DynamicImage
        hasShadow={false}
        height={"40px"}
        width={"40px"}
        borderRadius={"500px"}
        imageUrl={utils.formatIPFStoGateway(token.logoURI)}
      />
      <SizedBox width={8} />
      <div>
        <TokenNameWrapper color={colors.textColor}>{name}</TokenNameWrapper>
        <TokenSymbolWrapper color={colors.textColor}>
          {symbol} {(token.balance ? token.balance : 0).toString()}
        </TokenSymbolWrapper>
      </div>
    </ERC20ListItemWrapper>
  );
}
