import styled from "styled-components";
import { useAppColors } from "../utils/useAppColors";

const SelectedCircleWrapper = styled.div`
  color: ${(props) => props.color};
  border-radius: 5000px;
  padding:2px;
  display:flex;
  align-items;center;
  justify-content:center;
`;
export function SelectedCircle({ children }) {
  const colors = useAppColors();
  return (
    <SelectedCircleWrapper
      color={"white"}
      className="lighter-active-gradient"
      backgroundColor={colors.primaryColor}
    >
      {children}
    </SelectedCircleWrapper>
  );
}
