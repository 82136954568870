import { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Web3Context } from "../contexts/Web3Context";
import { useAppColors } from "../utils/useAppColors";
import { CustomInputWithSideSelector } from "./CustomInputWithSideSelector";
import { DynamicImage } from "./DynamicImage";
import { SecondaryButton } from "./SecondaryButton";
import { SizedBox } from "./SizedBox";
import { SubtitleText } from "./SubtitleText";
import { ethers } from "ethers";
import config from "../utils/config";
import api from "../utils/api";
import { VerifyTwitterButton } from "./VerifyTwitterButton";
import { DescriptionText } from "./DescriptionText";
import IconButton from "./IconButton";
import { BsExclamationLg } from "react-icons/bs";
import { BrowserView, MobileView } from "react-device-detect";

export const SocialMediaRouterSection = ({ }) => {
    const { user, eventRouter, socialMediaRouter, log, showSnackbar, setBlockchainData, altCoins, jwt } = useContext(Web3Context)
    const [isLoading, setIsLoading] = useState(true)
    const [twitterAddress, setTwitterAddress] = useState("")
    const [twitterLinkedStatus, setTwitterLinkedStatus] = useState(false)
    const [pendingAmounts, setPendingAmounts] = useState([])


    async function linkTwitter() {
        try {
            setIsLoading(true);
            const response = await api.createTwitterAuthUrl({ jwt: jwt });
            if (response.error) {
                throw Error(response.error);
            }
            window.location.href = response.authUrl;

        } catch (e) {
            setIsLoading(false);
            log({
                eventName: "general error",
                eventParams: {
                    userUid: user ? user._id : undefined,
                    error: e,
                },
            });
            showSnackbar({ message: e.message, severity: "error" });
            return;
        }
    }

    async function getServiceAddress() {
        if (!user) {
            setIsLoading(false)
            return
        }
        if (!user.twitterUserId) {
            setIsLoading(false)
            return
        }

        if (!socialMediaRouter) {
            setIsLoading(false)
            return
        }

        const address = await socialMediaRouter.getAddress("twitter", user.twitterUserId)
        if (address !== config.zeroAddress) {
            setTwitterAddress(address)
            setTwitterLinkedStatus(true)
        }
        setIsLoading(false)
    }

    async function getPendingAmounts() {
        if (!user) {
            setIsLoading(false)
            return
        }
        if (!user.twitterUserId) {
            setIsLoading(false)
            return
        }

        const oaResp = await api.getOwedAmounts({ serviceId: "twitter", userId: user.twitterUserId, jwt: jwt })
        setPendingAmounts(oaResp.owedAmounts)
        setIsLoading(false)
    }

    async function linkTwitterToRouter() {
        try {
            setIsLoading(true)
            if (!eventRouter) {
                throw Error("Connect to Ethereum")
            }
            const isAddress = await ethers.utils.isAddress(twitterAddress)
            if (!isAddress) {
                throw Error("Address is not valid")
            }

            const sigResponse = await api.generateSocialMediaSignature({ platform: "twitter", address: twitterAddress, jwt: jwt })
            if (sigResponse.error) {
                throw Error(sigResponse.error)
            }
            const sig = sigResponse.sig
            const _signature = sig.signature
            const _address = sig.address
            const _nonce = sig.nonce
            const _serviceId = sig.serviceId
            const _userId = sig.serviceUserId
            let tx
            if (pendingAmounts && pendingAmounts.length > 0) {
                const tokenAddresses = pendingAmounts.map(a => a.token.address)
                tx = await eventRouter.addAccountAndWithdrawl(_signature, _address, _serviceId, _userId, _nonce, tokenAddresses);
            } else {
                tx = await socialMediaRouter.addAccount(_signature, _address, _serviceId, _userId, _nonce);
            }

            const receipt = await tx.wait();
            log({
                eventName: "AddAccount",
                eventParams: {
                    userUid: user ? user._id : undefined,
                    accountAddress: _address,
                    serviceId: _serviceId,
                    userId: _userId,
                    nonce: _nonce,
                },
            });
            showSnackbar({ message: "Successfully added account!", severity: "success" });
            setIsLoading(false)
            getPendingAmounts()
            await setBlockchainData(altCoins, socialMediaRouter, eventRouter);
        }
        catch (e) {
            setIsLoading(false);
            log({
                eventName: "general error",
                eventParams: {
                    userUid: user ? user._id : undefined,
                    error: e,
                },
            });
            showSnackbar({ message: e.message, severity: "error" });
            return;
        }
    }

    useEffect(() => {
        getPendingAmounts()
        getServiceAddress()
    }, [user])

    const colors = useAppColors();

    return (<>
        <BrowserView>
            <Col>
                <Row>
                    <SubtitleText textAlign={"start"} color={colors.titleColor}>
                        Social Media Router
                    </SubtitleText>
                </Row>
                <Row>
                    <DescriptionText>
                        The social media router is used so that people can send you crypto through social media, rather than your crypto address.
                    </DescriptionText>
                </Row>
                {user && user.twitterUserId && <Row className="">
                    <Col>
                        {!twitterAddress && <Row className="d-flex align-items-center justify-content-start">
                            <Col xs={"1"} className="d-flex align-items-center justify-content-center">
                                <IconButton Icon={BsExclamationLg} onClick={() => { }} backgroundColor={colors.primaryColor} color={"white"} />
                            </Col>
                            <Col className="d-flex align-items-center justify-content-start">
                                <SubtitleText textAlign={"start"} color={colors.titleColor}>
                                    Step 2: Sync your address with the Twitter Router
                                </SubtitleText>
                            </Col>

                        </Row>}
                        {twitterAddress && <Row className="d-flex align-items-center justify-content-start">

                            <Col className="d-flex align-items-center justify-content-start">
                                <SubtitleText textAlign={"start"} color={colors.titleColor}>
                                    Sync an address with the Twitter Router
                                </SubtitleText>
                            </Col>

                        </Row>}
                        <CustomInputWithSideSelector
                            name={"Twitter"}
                            onSelectorClick={() => {
                                setTwitterAddress(user.evmAddress)
                            }}
                            selectorText={"Default"}
                            placeholder={"Input address"}
                            selectorTextPlaceholder={"Select Token"}
                            value={twitterAddress}
                            setValue={setTwitterAddress}
                        />
                    </Col>
                    <Col className="d-flex align-items-end" xs="3">
                        <SecondaryButton text="Link" onClick={linkTwitterToRouter} />
                    </Col>
                </Row>}
                <SizedBox height="16px" />
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                {user && !user.twitterUserId && <Row className="d-flex align-items-center justify-content-start">
                                    <Col xs={"1"} className="d-flex align-items-center justify-content-center">
                                        <IconButton Icon={BsExclamationLg} onClick={() => { }} backgroundColor={colors.primaryColor} color={"white"} />
                                    </Col>
                                    <Col className="d-flex align-items-center justify-content-start">
                                        <SubtitleText textAlign={"start"} color={colors.titleColor}>
                                            Step 1: Link your Twitter Account
                                        </SubtitleText>
                                    </Col>

                                </Row>}
                                {user && user.twitterUserId && <Row className="d-flex align-items-center justify-content-start">

                                    <Col className="d-flex align-items-center justify-content-start">
                                        <SubtitleText textAlign={"start"} color={colors.titleColor}>
                                            Or: Change your Twitter Account
                                        </SubtitleText>
                                    </Col>
                                </Row>}
                                <SizedBox height={"20px"} />
                                <Row>
                                    <VerifyTwitterButton text={
                                        user.twitterPlatformSpecifics
                                            ? user.twitterPlatformSpecifics.data.username
                                            : "Link your Twitter"
                                    }
                                        onClick={linkTwitter}
                                        isLoading={isLoading} />
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {twitterLinkedStatus && <div style={{ "backgroundColor": colors.primaryColor, fontWeight: "bold", marginTop: "16px", color: "white", borderRadius: "14px", width: "160px", padding: "8px" }}>STATUS: LINKED</div>}</Col>
                </Row>
                <SizedBox height={"16px"} />
                <Row>
                    <Col>
                        {pendingAmounts && pendingAmounts.length > 0 && <SubtitleText textAlign={"start"}>Amounts to collect</SubtitleText>}
                        <SizedBox height={"8px"} />

                        {pendingAmounts && pendingAmounts.map(p => <div>
                            <Row>
                                <Col xs={1}>
                                    <DynamicImage width={"30px"} borderRadius={"5000px"} imageUrl={p.token.image} />
                                </Col>
                                <Col className="d-flex" style={{ "fontWeight": "bold", "paddingBottom": "12px" }}>
                                    {`${ethers.BigNumber.from(p.owedAmount.hex) / (10 ** p.token.decimals)} ${p.token.symbol}`}</Col>
                            </Row>
                        </div>)}
                    </Col>
                </Row>
                <SizedBox height={16} />
            </Col>
        </BrowserView>
        <MobileView>
            <Col>
                <Row>
                    <SubtitleText textAlign={"start"} color={colors.titleColor}>
                        Social Media Router
                    </SubtitleText>
                </Row>
                <Row>
                    <DescriptionText fontSize={"16px"}>
                        The social media router is used so that people can send you crypto through social media, rather than your crypto address.
                    </DescriptionText>
                </Row>
                {user && user.twitterUserId && <Row className="">
                    <Row>
                        <Col>
                            {!twitterAddress && <Row className="d-flex align-items-center justify-content-start">
                                <Col xs={"1"} className="d-flex align-items-center justify-content-center">
                                    <IconButton Icon={BsExclamationLg} onClick={() => { }} backgroundColor={colors.primaryColor} color={"white"} />
                                </Col>
                                <Col className="d-flex align-items-center justify-content-start">
                                    <SubtitleText fontSize={"20px"} textAlign={"start"} color={colors.titleColor}>
                                        Step 2: Sync your address with the Twitter Router
                                    </SubtitleText>
                                </Col>

                            </Row>}
                            {twitterAddress && <Row className="d-flex align-items-center justify-content-start">

                                <Col className="d-flex align-items-center justify-content-start">
                                    <SubtitleText fontSize={"20px"} textAlign={"start"} color={colors.titleColor}>
                                        Sync an address with the Twitter Router
                                    </SubtitleText>
                                </Col>

                            </Row>}
                            <CustomInputWithSideSelector
                                name={"Twitter"}
                                onSelectorClick={() => {
                                    setTwitterAddress(user.evmAddress)
                                }}
                                selectorText={"Default"}
                                placeholder={"Input address"}
                                selectorTextPlaceholder={"Select Token"}
                                value={twitterAddress}
                                setValue={setTwitterAddress}
                            />
                        </Col>

                    </Row>
                    <SizedBox height="16px" />

                    <Row>
                        <Col  >
                            <SecondaryButton text="Link" onClick={linkTwitterToRouter} />
                        </Col>
                    </Row>
                </Row>
                }
                <SizedBox height="16px" />
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                {user && !user.twitterUserId && <Row className="d-flex align-items-center justify-content-start">
                                    <Col xs={"1"} className="d-flex align-items-center justify-content-center">
                                        <IconButton Icon={BsExclamationLg} onClick={() => { }} backgroundColor={colors.primaryColor} color={"white"} />
                                    </Col>
                                    <Col className="d-flex align-items-center justify-content-start">
                                        <SubtitleText fontSize={"20px"} textAlign={"start"} color={colors.titleColor}>
                                            Step 1: Link your Twitter Account
                                        </SubtitleText>
                                    </Col>

                                </Row>}
                                {user && user.twitterUserId && <Row className="d-flex align-items-center justify-content-start">

                                    <Col className="d-flex align-items-center justify-content-start">
                                        <SubtitleText fontSize={"20px"} textAlign={"start"} color={colors.titleColor}>
                                            Or: Change your Twitter Account
                                        </SubtitleText>
                                    </Col>
                                </Row>}
                                <SizedBox height={"20px"} />
                                <Row>
                                    <VerifyTwitterButton text={
                                        user.twitterPlatformSpecifics
                                            ? user.twitterPlatformSpecifics.data.username
                                            : "Link your Twitter"
                                    }
                                        onClick={linkTwitter}
                                        isLoading={isLoading} />
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {twitterLinkedStatus && <div style={{ "backgroundColor": colors.primaryColor, fontWeight: "bold", marginTop: "16px", color: "white", borderRadius: "14px", width: "160px", padding: "8px" }}>STATUS: LINKED</div>}</Col>
                </Row>
                <SizedBox height={"16px"} />
                <Row>
                    <Col>
                        {pendingAmounts && pendingAmounts.length > 0 && <SubtitleText textAlign={"start"}>Amounts to collect</SubtitleText>}
                        <SizedBox height={"8px"} />

                        {pendingAmounts && pendingAmounts.map(p => <div>
                            <Row>
                                <Col xs={1}>
                                    <DynamicImage width={"30px"} borderRadius={"5000px"} imageUrl={p.token.image} />
                                </Col>
                                <Col className="d-flex" style={{ "fontWeight": "bold", "paddingBottom": "12px" }}>
                                    {`${ethers.BigNumber.from(p.owedAmount.hex) / (10 ** p.token.decimals)} ${p.token.symbol}`}</Col>
                            </Row>
                        </div>)}
                    </Col>
                </Row>
                <SizedBox height={16} />
            </Col>
        </MobileView>
    </>)
}