import { Col, Row } from "react-bootstrap";
import { FaBeer } from "react-icons/fa";
import { useAppColors } from "../utils/useAppColors";
import { TitleText } from "./TitleText";

export default function OptionListTile({ Icon, name, onClick }) {
  const colors = useAppColors();
  return (
    <div onClick={onClick} style={{ cursor: "pointer" }}>
      <Row>
        <Col
          xs={2}
          className="d-flex align-items-center justify-content-center"
        >
          <Icon color={colors.primaryColor} size={20} />
        </Col>
        <Col className="d-flex align-items-start">
          <TitleText fontSize={"16px"}>{name}</TitleText>
        </Col>
      </Row>
    </div>
  );
}
