import { Center } from "../components/Center";
import LoadingIndicator from "../components/LoadingIndicator";
import { TitleText } from "../components/TitleText";
import { WidthContainer } from "../components/WidthContainer";

export default function AuthTokenConfirm() {
  return (
    <WidthContainer topPadding={36}>
                  {/* <SizedBox height={36} /> */}
      <Center>
        <TitleText>Thank you for logging in!</TitleText>
        <LoadingIndicator />
      </Center>
    </WidthContainer>
  );
}
