import { Col, Modal, Row } from "react-bootstrap";
import { BrowserView, MobileView } from "react-device-detect";
import { useAppColors } from "../utils/useAppColors";
import { DynamicImage } from "./DynamicImage";
import OptionListTile from "./OptionListTile";
import { SizedBox } from "./SizedBox";
import { TitleText } from "./TitleText";
import { CopyToClipboard } from "react-copy-to-clipboard";
import useCopy from "../utils/useCopy";
import { useContext, useState } from "react";
import copyToClipboard from "copy-to-clipboard";
import { FiUser } from "react-icons/fi";
import { MdContentCopy } from "react-icons/md";
import { BsFillFileEarmarkLock2Fill } from "react-icons/bs";
import { FaTimes } from "react-icons/fa";
import { BottomSheet } from "react-spring-bottom-sheet";
import { Center } from "./Center";
import BondSnackbar from "./BondSnackbar";
import { Web3Context } from "../contexts/Web3Context";
import IconButton from "./IconButton";
import api from "../utils/api";
import LoadingIndicator from "./LoadingIndicator";
import QRCode from "./QRCode";

function getScheduleBondUserUrl(username) {
  return `https://schedule.bond/u/${username}`;
}

export default function ShareBottomSheet({ open, setOpen, otherUser }) {
  const { showSnackbar, user, jwt, log } = useContext(Web3Context);
  const colors = useAppColors();
  const [isLoading, setIsLoading] = useState(false);
  const [oneTimeUrl, setOneTimeUrl] = useState("");
  const [copied, copy, setCopied] = useCopy(oneTimeUrl);
  const isUser = user && user._id === otherUser._id;

  async function copyOneTimeLink() {
    try {
      setIsLoading(true);
      const resp = await api.createOneTimeLink({ jwt: jwt });
      if (resp.error) {
        log({
          eventName: "createOneTimeLink error",
          eventParams: {
            userUid: user ? user._id : undefined,
            error: resp.error,
          },
        });
        throw Error(resp.error);
      }
      const otl = resp.oneTimeLink;
      log({
        eventName: "createOneTimeLink",
        eventParams: {
          userUid: user ? user._id : undefined,
          oneTimeLinkUrl: resp.oneTimeLink,
        },
      });
      copyToClipboard(otl);
      showSnackbar({
        message: "Copied one time link!",
        severity: "success",
      });
      setIsLoading(false);
    } catch (e) {
      log({
        eventName: "general error",
        eventParams: {
          userUid: user ? user._id : undefined,
          error: e,
        },
      });
      showSnackbar({ message: e.message, severity: "error" });
      setIsLoading(false);
    }
  }

  return (
    <>
      {otherUser && (
        <>
          <BrowserView>
            <Modal
              dialogClassName="modal-40w"
              show={open}
              onHide={() => {
                setOpen(false);
              }}
            >
              <Modal.Body>
                {isLoading ? (
                  <Center>
                    <Col>
                      <SizedBox height={48} />
                      <Center>
                        {" "}
                        <LoadingIndicator />
                      </Center>
                      <SizedBox height={48} />
                    </Col>
                  </Center>
                ) : (
                  <Center>
                    <div style={{ width: "90%" }}>
                      <SizedBox height={8} />

                      <Row className="d-flex align-items-end justify-content-end">
                        <Col className="d-flex align-items-end justify-content-end">
                          <IconButton
                            Icon={FaTimes}
                            onClick={() => {
                              setOpen(false);
                            }}
                          />
                        </Col>
                      </Row>

                      <Row className="d-flex flex-row justify-content-center">
                        <Col xs={2} className="d-flex align-items-center">
                          <DynamicImage
                            height={"60px"}
                            width={"60px"}
                            hasShadow={true}
                            borderRadius={"5000px"}
                            imageUrl={otherUser.avatarUrl}
                          />
                        </Col>
                        <Col className="d-flex align-items-center">
                          <TitleText textAlign={"start"}>
                            {otherUser.username ? otherUser.username : "user"}
                          </TitleText>
                        </Col>
                      </Row>
                      <SizedBox height={32} />
                      <OptionListTile
                        onClick={() => {
                          const v = getScheduleBondUserUrl(otherUser.username);
                          const copiedString = copyToClipboard(v);
                          showSnackbar({
                            message: "Copied link!",
                            severity: "success",
                          });
                        }}
                        name={`Copy link to profile`}
                        Icon={MdContentCopy}
                      />
                      {isUser && <hr />}

                      {isUser && (
                        <OptionListTile
                          onClick={() => {
                            copyOneTimeLink();
                          }}
                          name={`Copy one time link to schedule`}
                          Icon={BsFillFileEarmarkLock2Fill}
                        />
                      )}
                      <SizedBox height={32} />

                      {/* <hr color={colors.textFieldColor} /> */}
                      <Row>
                        <Col className="d-flex align-items-center justify-content-center">
                          <QRCode
                            url={getScheduleBondUserUrl(otherUser.username)}
                          />
                        </Col>
                      </Row>
                      <SizedBox height={32} />
                    </div>
                  </Center>
                )}
              </Modal.Body>
            </Modal>
          </BrowserView>
          <MobileView>
            <>
              <BottomSheet
                open={open}
                onDismiss={() => {
                  setOpen(false);
                }}
              >
                {isLoading ? (
                  <Center>
                    <SizedBox height={32} />
                    <LoadingIndicator />
                  </Center>
                ) : (
                  <Center>
                    <div style={{ width: "90%" }}>
                      <SizedBox height={8} />
                      <Row className="d-flex flex-row justify-content-center">
                        <Col xs={2} className="d-flex align-items-center">
                          <DynamicImage
                            height={"60px"}
                            width={"60px"}
                            hasShadow={true}
                            borderRadius={"5000px"}
                            imageUrl={otherUser.avatarUrl}
                          />
                        </Col>
                        <Col xs={1}></Col>
                        <Col className="d-flex align-items-center">
                          <TitleText textAlign={"start"}>
                            {otherUser.username ? otherUser.username : "user"}
                          </TitleText>
                        </Col>
                      </Row>
                      <hr />
                      <OptionListTile
                        onClick={() => {
                          const v = getScheduleBondUserUrl(otherUser.username);
                          const copiedString = copyToClipboard(v);
                          showSnackbar({
                            message: "Copied link!",
                            severity: "success",
                          });
                        }}
                        name={`Copy link to profile`}
                        Icon={MdContentCopy}
                      />
                      {isUser && <hr />}

                      {isUser && (
                        <OptionListTile
                          onClick={() => {
                            copyOneTimeLink();
                          }}
                          name={`Copy one time link to schedule`}
                          Icon={BsFillFileEarmarkLock2Fill}
                        />
                      )}
                    </div>
                  </Center>
                )}
                <SizedBox height={32} />
                <Row>
                  <Col className="d-flex align-items-center justify-content-center">
                    <QRCode
                      url={getScheduleBondUserUrl(otherUser.username)}
                      height={200}
                      width={200}
                      margin={4}
                    />
                  </Col>
                </Row>
                <SizedBox height={48} />
              </BottomSheet>
            </>
          </MobileView>
        </>
      )}
    </>
  );
}
