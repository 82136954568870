import React from "react";
import { Col, Row } from "react-bootstrap";
import styled, { css } from "styled-components";
import { useAppColors } from "../utils/useAppColors";
import { Center } from "./Center";
import { SizedBox } from "./SizedBox";
import { StandardShadow } from "./StandardShadow";

const CustomTextFieldWrapper = styled.div`
  width: 100%;
  border-radius: 24px;
  background-color: white;
  border: 2px solid black;
  color: #130d0d;
  font-size: 18px;
  disabled: ${(props) => (props.disabled ? props.disabled : false)};
`;

const InputName = styled.div`
  color: ${(props) => "black"};
  font-size: 14px;
  margin-bottom: 12px;
  font-weight: bold;
  text-align: start;
`;

const InputNameWrapper = styled.div`
  background-color: white;
  position: relative;
  top: 24px;
  left: 24px;
  padding-right: 4px;
  padding-left: 4px;
`;

const CustomTextAreaWrapper = styled.textarea`
  width: 100%;
  border-radius: 24px;
  background-color: white;
  border: none;
  color: #130d0d;
  font-size: 18px;
  padding: 20px 40px 20px 40px;
`;

const ClearInput = styled.input`
  padding: 20px 40px 20px 40px;
  width: 100%;
  border-radius: 24px;
  border: none;
  &:focus {
    outline: none;
  }
`;

const SideSelector = styled.div`
  background-color: ${(props) => props.backgroundColor};
  padding: 12px;
  border-radius: 18px;
  cursor: pointer;
  font-weight: bold;
  display: flex;
  align-items: center;
`;

const SideSelectorImage = styled.img`
  height: 30px;
  width: 30px;
  border-radius: 500px;
`;

const SideSelectorText = styled.div``;

export const CustomInputWithSideSelector = ({
  name,
  placeholder,
  value,
  setValue,
  type,
  rows,
  disabled,
  onSelectorClick,
  selectorText,
  selectorImage,
  selectorTextPlaceholder,
  selectorXs
}) => {
  const colors = useAppColors();
  return (
    <div>
      {name ? (
        <div className="d-flex">
          <InputNameWrapper>
            <InputName color={colors.textColor}>{name}</InputName>
          </InputNameWrapper>
        </div>
      ) : null}
      <StandardShadow borderRadius={"24px"}>
        <CustomTextFieldWrapper borderColor={colors.textColor}>
          <Row style={{ flexWrap: "nowrap",  }}>
            <Col>
              <ClearInput
                type={type ?? "text"}
                name={name}
                placeholder={placeholder}
                value={value}
                onChange={(e) => {
                  if (setValue) {
                    setValue(e.target.value);
                  }
                }}
                disabled={disabled}
              />
            </Col>
            <Col
              className="d-flex align-items-center justify-content-end"
              style={{ marginRight: "8px" }}
              xs={selectorXs ? selectorXs : 4}
            >
              <SideSelector
                backgroundColor={colors.textFieldColor}
                onClick={onSelectorClick}
              >
                {selectorImage ? (
                  <SideSelectorImage src={selectorImage} />
                ) : null}
                {selectorImage ? <SizedBox width={4} /> : null}
                <SideSelectorText>
                  {selectorText ? selectorText : selectorTextPlaceholder}
                </SideSelectorText>
              </SideSelector>
            </Col>
          </Row>
        </CustomTextFieldWrapper>
      </StandardShadow>
    </div>
  );
};
