import styled from "styled-components";
import { useContext, useState } from "react";
import { Web3Context } from "../contexts/Web3Context";
import { useAppColors } from "../utils/useAppColors";

export const FlatButtonWrapper = styled.div`
  color: ${(props) => props.color};
  font-weight: bold;
  padding: 8px;
  cursor: pointer;
`;

export const FlatButton = ({ children, color, onClick }) => {
  const { user, jwt } = useContext(Web3Context);
  const colors = useAppColors();

  async function safeOnClick() {
    // if (requireUser) {
    //   if (!user || !user.username || !user.displayName || !jwt) {
    //     setShowRequireUser(true);
    //     return;
    //   }
    // }

    // if (requireAddress) {
    //   if (!user || !user.shippingAddress) {
    //     setShowRequireAddress(true);
    //     return;
    //   }
    // }
    onClick();
  }
  return (
    <>
      <FlatButtonWrapper
        color={color ? color : colors.primaryColor}
        onClick={safeOnClick}
      >
        {children}
      </FlatButtonWrapper>
    </>
  );
};
