import { Col, Row } from "react-bootstrap";
import { BsCheckCircleFill } from "react-icons/bs";
import { useAppColors } from "../utils/useAppColors";
import styled from "styled-components";
import { FaTimes } from "react-icons/fa";
import { MdOutlineBubbleChart } from "react-icons/md";
import { SecondaryButton } from "../components/SecondaryButton";
import PopoverWrapper from "../components/PopoverWrapper";
import SubscriptionBottomSheet from "../components/SubscriptionBottomSheet";

const UnavailableCircleIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.backgroundColor};
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  border-radius: 5000px;
`;

const SubscriptionCardWrapper = styled.div`
  background-color: ${(props) => props.backgroundColor};
  border: 1px solid ${(props) => props.borderColor};
  border-radius: 24px;
  padding: ${(props) => props.padding};
  cursor: pointer;
`;

function UnavailableCircleIcon() {
  const colors = useAppColors();

  return (
    <UnavailableCircleIconWrapper
      backgroundColor={colors.textFieldColor}
      height={40}
      width={40}
    >
      <FaTimes color={colors.primaryColor} />
    </UnavailableCircleIconWrapper>
  );
}

export default function FeatureListItem({ text, comingSoon, unavailable, xs }) {
  const colors = useAppColors();
  return (
    <>
      <Col xs={xs ? xs : 4} style={{ paddingBottom: "12px" }}>
        <Row>
          <Col xs={2} className="d-flex ">
            <div>
              {!unavailable ? (
                <BsCheckCircleFill color={colors.successGreen} />
              ) : (
                <UnavailableCircleIcon />
              )}
            </div>
          </Col>
          <Col xs={1}></Col>
          <Col>
            <Row style={{ fontSize: "14px" }}>{text}</Row>
            <Row style={{ color: colors.textColor, fontSize: "12px" }}>
              {comingSoon ? "Coming soon" : ""}
            </Row>
          </Col>
        </Row>
      </Col>
    </>
  );
}
