import ky from "ky";
//const rootUrl = "http://localhost:8888/v1";
// const rootUrl = "https://schedule-bond-api-dev.netlify.app/v1";

const rootUrl = "https://api.schedule.bond/v1";

async function apiCall(path, body, jwt) {
  const headers = {
    "Content-Type": "application/json",
    authorization: jwt ? jwt : undefined,
  };

  try {
    const response = await ky
      .post(rootUrl + path, {
        json: body,
        headers: headers,
        throwHttpErrors: false,
      })
      .json();
    if (response.error === "jwt expired") {
      response.error = "Please Login Again";
    }
    return response;
  } catch (e) {
    return { error: "Something went wrong..." };
  }
}

async function login({ message, signature }) {
  try {
    const path = "/login";
    const body = {
      message: message,
      signature: signature,
    };
    const response = await apiCall(path, body, undefined);
    return response;
  } catch (e) {
    return { error: "Something went wrong..." };
  }
}

async function logout({ logoutAll, jwt }) {
  try {
    const path = "/logout";
    const body = {
      logoutAll: logoutAll,
    };
    const response = await apiCall(path, body, jwt);
    return response;
  } catch (e) {
    return { error: "Something went wrong..." };
  }
}

async function createFbToken({ jwt }) {
  try {
    const path = "/createFbToken";
    const body = {};
    const response = await apiCall(path, body, jwt);
    return response;
  } catch (e) {
    return { error: "Something went wrong..." };
  }
}

async function getUserData({ address, username, userId, jwt }) {
  try {
    const path = "/getUserData";
    const body = {
      address: address,
      username: username,
      userId: userId,
    };
    const response = await apiCall(path, body, jwt);
    return response;
  } catch (e) {
    return { error: "Something went wrong..." };
  }
}

async function getUserData2({ address, username, userId, jwt }) {
  try {
    const path = "/getUserData2";
    const body = {
      address: address,
      username: username,
      userId: userId,
    };
    const response = await apiCall(path, body, jwt);
    return response;
  } catch (e) {
    return { error: "Something went wrong..." };
  }
}

async function getUsers({ userIds, jwt }) {
  try {
    const path = "/getUsers";
    const body = {
      userIds: userIds,
    };
    const response = await apiCall(path, body, jwt);
    return response;
  } catch (e) {
    return { error: "Something went wrong..." };
  }
}

async function updateUserData({ user, jwt }) {
  try {
    const path = "/updateUserData";
    const body = {
      ...user,
    };
    const response = await apiCall(path, body, jwt);
    return response;
  } catch (e) {
    return { error: "Something went wrong..." };
  }
}

async function updateUserData2({ user, jwt }) {
  try {
    const path = "/updateUserData2";
    const body = {
      ...user,
    };
    const response = await apiCall(path, body, jwt);
    return response;
  } catch (e) {
    return { error: "Something went wrong..." };
  }
}

async function updateScheduleBonds({ bonds, jwt }) {
  try {
    const path = "/updateScheduleBonds";
    const body = {
      bonds: bonds,
    };
    const response = await apiCall(path, body, jwt);
    return response;
  } catch (e) {
    return { error: "Something went wrong..." };
  }
}

async function updateAvatar({ url, platform, isDelete, jwt }) {
  try {
    const path = "/updateAvatar";
    const body = {
      url: url,
      platform: platform,
      isDelete: isDelete,
    };
    const response = await apiCall(path, body, jwt);
    return response;
  } catch (e) {
    return { error: "Something went wrong..." };
  }
}

async function checkUsername({ username, jwt }) {
  try {
    const path = "/updateUserData";
    const body = {
      username: username,
    };
    const response = await apiCall(path, body, jwt);
    return response;
  } catch (e) {
    return { error: "Something went wrong..." };
  }
}

async function createPaymentLink({ jwt }) {
  try {
    const path = "/createPaymentLink";
    const body = {};
    const response = await apiCall(path, body, jwt);
    return response;
  } catch (e) {
    return { error: "Something went wrong..." };
  }
}

async function cancelSubscription({ jwt }) {
  try {
    const path = "/cancelSubscription";
    const body = {};
    const response = await apiCall(path, body, jwt);
    return response;
  } catch (e) {
    return { error: "Something went wrong..." };
  }
}

async function getAuthUrl({ platform, device, route, jwt }) {
  try {
    const path = "/getAuthUrl";
    const body = { platform: platform, device: device, route: route };
    const response = await apiCall(path, body, jwt);
    return response;
  } catch (e) {
    return { error: "Something went wrong..." };
  }
}

async function processAuthCode({ authCode, platform, route, jwt }) {
  try {
    const path = "/processAuthCode";
    const body = { authCode: authCode, platform: platform, route: route };
    const response = await apiCall(path, body, jwt);
    return response;
  } catch (e) {
    return { error: "Something went wrong..." };
  }
}

async function getUserNfts({ jwt }) {
  try {
    const path = "/getUserNfts";
    const body = {};
    const response = await apiCall(path, body, jwt);
    return response;
  } catch (e) {
    return { error: "Something went wrong..." };
  }
}

async function searchNfts({ query, jwt }) {
  try {
    const path = "/searchNfts";
    const body = {
      query: query,
    };
    const response = await apiCall(path, body, jwt);
    return response;
  } catch (e) {
    return { error: "Something went wrong..." };
  }
}

async function getScheduleData({
  username,
  bondIndex,
  oneTimeLinkUid,
  oneTimeLinkSecret,
  tz,
  jwt,
}) {
  try {
    const path = "/getScheduleData";
    const body = {
      username: username,
      bondIndex: bondIndex,
      oneTimeLinkUid: oneTimeLinkUid,
      oneTimeLinkSecret: oneTimeLinkSecret,
      tz: tz,
    };
    const response = await apiCall(path, body, jwt);
    return response;
  } catch (e) {
    return { error: "Something went wrong..." };
  }
}

async function getScheduleData2({
  username,
  bondId,
  oneTimeLinkUid,
  oneTimeLinkSecret,
  tz,
  jwt,
}) {
  try {
    const path = "/getScheduleData2";
    const body = {
      username: username,
      bondId: bondId,
      oneTimeLinkUid: oneTimeLinkUid,
      oneTimeLinkSecret: oneTimeLinkSecret,
      tz: tz,
    };
    const response = await apiCall(path, body, jwt);
    return response;
  } catch (e) {
    return { error: "Something went wrong..." };
  }
}

async function createEvent({
  username,
  bondIndex,
  time,
  email,
  guestEmails,
  meetingNotes,
  oneTimeLinkSecret,
  oneTimeLinkUid,
  tz,
  jwt,
}) {
  try {
    const path = "/createEvent";
    const body = {
      username: username,
      bondIndex: bondIndex,
      email: email,
      guestEmails: guestEmails,
      meetingNotes: meetingNotes,
      tz: tz,
      time: time,
      oneTimeLinkSecret: oneTimeLinkSecret,
      oneTimeLinkUid: oneTimeLinkUid,
    };
    const response = await apiCall(path, body, jwt);
    return response;
  } catch (e) {
    return { error: "Something went wrong..." };
  }
}

async function createEvent2({
  username,
  bondId,
  time,
  email,
  guestEmails,
  meetingNotes,
  oneTimeLinkSecret,
  oneTimeLinkUid,
  tz,
  jwt,
}) {
  try {
    const path = "/createEvent2";
    const body = {
      username: username,
      bondId: bondId,
      email: email,
      guestEmails: guestEmails,
      meetingNotes: meetingNotes,
      tz: tz,
      time: time,
      oneTimeLinkSecret: oneTimeLinkSecret,
      oneTimeLinkUid: oneTimeLinkUid,
    };
    const response = await apiCall(path, body, jwt);
    return response;
  } catch (e) {
    return { error: "Something went wrong..." };
  }
}

async function getEventDetails({ eventUid, guestEmail, jwt }) {
  try {
    const path = "/getEventDetails";
    const body = {
      eventUid: eventUid,
      guestEmail: guestEmail,
    };
    const response = await apiCall(path, body, jwt);
    return response;
  } catch (e) {
    return { error: "Something went wrong..." };
  }
}

async function getEvents({ jwt }) {
  try {
    const path = "/getEvents";
    const body = {};
    const response = await apiCall(path, body, jwt);
    return response;
  } catch (e) {
    return { error: "Something went wrong..." };
  }
}

async function createOneTimeLink({ bondIndex, jwt }) {
  try {
    const path = "/createOneTimeLink";
    const body = {
      bondIndex: bondIndex,
    };
    const response = await apiCall(path, body, jwt);
    return response;
  } catch (e) {
    return { error: "Something went wrong..." };
  }
}

async function disconnectAuth({ jwt }) {
  try {
    const path = "/disconnectAuth";
    const body = {};
    const response = await apiCall(path, body, jwt);
    return response;
  } catch (e) {
    return { error: "Something went wrong..." };
  }
}

async function createLogin() {
  try {
    const path = "/createLogin";
    const body = {};
    const response = await apiCall(path, body, undefined);
    return response;
  } catch (e) {
    return { error: "Something went wrong..." };
  }
}

async function createTwitterAuthUrl({ jwt }) {
  try {
    const path = "/createTwitterAuthUrl";
    const body = {};
    const response = await apiCall(path, body, jwt);
    return response;
  } catch (e) {
    return { error: "Something went wrong..." };
  }
}

async function getTwitterProfile({ search, jwt }) {
  try {
    const path = "/getTwitterProfile";
    const body = { search: search };
    const response = await apiCall(path, body, jwt);
    return response;
  } catch (e) {
    return { error: "Something went wrong..." };
  }
}

async function getTokenBalances({ jwt }) {
  try {
    const path = "/getTokenBalances";
    const body = {};
    const response = await apiCall(path, body, jwt);
    return response;
  } catch (e) {
    return { error: "Something went wrong..." };
  }
}

async function getTokenBalanceOf({ tokenAddress, jwt }) {
  try {
    const path = "/getTokenBalanceOf";
    const body = { tokenAddress: tokenAddress };
    const response = await apiCall(path, body, jwt);
    return response;
  } catch (e) {
    return { error: "Something went wrong..." };
  }
}

async function getOwedAmounts({ serviceId, userId, jwt }) {
  try {
    const path = "/getOwedAmounts";
    const body = { serviceId: serviceId, userId: userId };
    const response = await apiCall(path, body, jwt);
    return response;
  } catch (e) {
    return { error: "Something went wrong..." };
  }
}

async function generateSocialMediaSignature({ platform, address, jwt }) {
  try {
    const path = "/generateSocialMediaSignature";
    const body = { platform: platform, address: address };
    const response = await apiCall(path, body, jwt);
    return response;
  } catch (e) {
    return { error: "Something went wrong..." };
  }
}

async function getTokenData({ tokens, jwt }) {
  try {
    const path = "/getTokenData";
    const body = { tokens: tokens };
    const response = await apiCall(path, body, jwt);
    return response;
  } catch (e) {
    return { error: "Something went wrong..." };
  }
}

async function getPayments({ jwt }) {
  try {
    const path = "/getPayments";
    const body = {};
    const response = await apiCall(path, body, jwt);
    return response;
  } catch (e) {
    return { error: "Something went wrong..." };
  }
}

async function createMessagePendingRule({ message, jwt }) {
  try {
    const path = "/createMessagePendingRule";
    const body = { message };
    const response = await apiCall(path, body, jwt);
    return response;
  } catch (e) {
    return { error: "Something went wrong..." };
  }
}

async function addPhoneNumber({ code, phoneNumber, jwt }) {
  try {
    const path = "/addPhoneNumber";
    const body = { code, phoneNumber };
    const response = await apiCall(path, body, jwt);
    return response;
  } catch (e) {
    return { error: "Something went wrong..." };
  }
}

async function getUserTimeZone({ jwt }) {
  try {
    const path = "/getUserTimeZone";
    const body = {};
    const response = await apiCall(path, body, jwt);
    return response;
  } catch (e) {
    return { error: "Something went wrong..." };
  }
}

async function checkFriendTech({ twitterUsername, user }) {
  try {
    console.log("twt " + twitterUsername);
    const path = "/checkFriendTechTwitter";
    const body = {
      twitterUsername: twitterUsername,
      ...user
    };
    const response = await apiCall(path, body);
    return response;
  } catch (e) {
    return { error: "Something went wrong..." };
  }
}

const api = {
  login,
  getUserData,
  updateUserData,
  updateUserData2,
  updateAvatar,
  checkUsername,
  createPaymentLink,
  getAuthUrl,
  processAuthCode,
  getUserNfts,
  searchNfts,
  getScheduleData,
  createEvent,
  getEventDetails,
  createOneTimeLink,
  createLogin,
  getEvents,
  disconnectAuth,
  getUsers,
  cancelSubscription,
  createTwitterAuthUrl,
  createFbToken,
  getTwitterProfile,
  getTokenBalances,
  getTokenBalanceOf,
  getOwedAmounts,
  generateSocialMediaSignature,
  getTokenData,
  getPayments,
  createMessagePendingRule,
  logout,
  getUserData2,
  getScheduleData2,
  createEvent2,
  updateScheduleBonds,
  addPhoneNumber,
  getUserTimeZone,
  checkFriendTech
};

export default api;
