import { useState, useEffect } from "react";
import { useAppColors } from "../utils/useAppColors";
import {Col, Row} from "react-bootstrap"
import { SubtitleText } from "./SubtitleText";
import { SizedBox } from "./SizedBox";

export default function DayButtons({availableDays, onDayClick}) {
  const colors = useAppColors();
  const [dayElems, setDayElems] = useState(undefined);

const darr = ["mon", "tue", "wed", "thu", "fri", "sat"];

  useEffect(() => {
    setDayElems([
      dayCell("Sun"),
      dayCell("Mon"),
      dayCell("Tue"),
      dayCell("Wed"),
      dayCell("Thu"),
      dayCell("Fri"),
      dayCell("Sat")
     ]);
  }, []);

  async function DayClick(e, i) {
    let day = i.toLowerCase();
    let list = availableDays;
    if(list.includes(day))
    {
      list.splice(list.indexOf(day), 1);
      document.getElementById(i).firstChild.style.backgroundColor = colors.cardBackgroundColor;
      document.getElementById(i).firstChild.style.color = colors.textColor;
    }
    else
    {
      list.push(day);
      list.sort((a, b) => darr.indexOf(a) - darr.indexOf(b));
      document.getElementById(i).firstChild.style.backgroundColor = colors.primaryColorLight;
      document.getElementById(i).firstChild.style.color = colors.primaryColor;
    }
    console.log(list);
    onDayClick && onDayClick(list);
  }

  const dayCell = (d) =>
  {
    let included = availableDays.includes(d.toLowerCase());
    return(
      <td
      width="14.2%"
          id={d} 
          key={d}
          className={d}
          onClick={(e) => {
            DayClick(e, d);
          }}
        >
          <div style={{
                backgroundColor: included ? colors.primaryColorLight : colors.cardBackgroundColor,
                borderRadius: "16px",
                paddingTop: "100%",
                textAlign: "center",
                justifyContent: "center",
                padding: "10px",
                lineHeight: "50px",
                width: "90%",
                marginLeft: "5%",
                fontWeight: "bold",
                color: included ? colors.primaryColor : colors.textColor,
                cursor: "pointer",
          }}
            >
            <span>{d}</span>
          </div>
        </td>
    )
  }



  return (
    <Col>
      <Row>
        <SubtitleText textAlign={"start"} color={colors.titleColor}>
          Available Days
        </SubtitleText>
      </Row>
      <SizedBox height={24} />
        <Row>
        <table width="100%">
      <tbody>
       <tr>{dayElems}</tr>
       </tbody>
    </table>
        </Row>
    </Col>
  );
}