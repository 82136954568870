import { Col, Row } from "react-bootstrap";
import { BrowserView, isMobile, MobileView } from "react-device-detect";
import InfoCard from "../components/InfoCard";
import { SizedBox } from "../components/SizedBox";
import { SubtitleText } from "../components/SubtitleText";
import { TitleText } from "../components/TitleText";
import { WidthContainer } from "../components/WidthContainer";
import { useAppColors } from "../utils/useAppColors";
import UndrawCalendar from "../undraw-art/undraw_calendar_re_ki49.svg"
import UndrawEther from "../undraw-art/undraw_ether_re_y7ft.svg"
import UndrawProfile from "../undraw-art/undraw_profile_re_4a55.svg"
import UndrawLink from "../undraw-art/undraw_share_link_re_54rx.svg"
import { DescriptionText } from "../components/DescriptionText";
import { FlatButton } from "../components/FlatButton";
import { PrimaryButton } from "../components/PrimaryButton";
import { useContext, useEffect, useState } from "react";
import { Web3Context } from "../contexts/Web3Context";
import { CustomTextField } from "../components/CustomTextInput";
import { Center } from "../components/Center";
import { UploadImageWidget } from "../components/UploadImageWidget";
import { SecondaryButton } from "../components/SecondaryButton";
import EmailModal from "../components/EmailModal";
import api from "../utils/api";
import BondsEditor2 from "../components/BondsEditor2";
import config from "../utils/config";
import utils from "../utils/utils";
import firebaseUtils from "../utils/firebase";
import imageCompression from 'browser-image-compression';
import { v4 } from "uuid"
import { useNavigate, useSearchParams } from "react-router-dom";

function BottomControls({ changeIndex, index, beforeNext, isLoading, updateUserProfile, username, email, saveBond, editingBond }) {
    const navigate = useNavigate()
    const { user } = useContext(Web3Context)
    const [isDisabled, setIsDisabled] = useState(false)

    useEffect(() => {
        if (index === 1) {
            if (!username) {
                setIsDisabled(true)
            } else {
                setIsDisabled(false)
            }
        }

        if (index === 2) {
            if (!email && !isMobile) {
                setIsDisabled(true)
            } else {
                setIsDisabled(false)
            }
        }
    }, [index, user, username, email])

    return <>
        <Row>
            {!isMobile && <Col>

            </Col>}
            <Col>
                {index === 3 &&
                    <Row>

                        <Col className="d-flex justify-content-end align-items-center">
                            <FlatButton onClick={() => {
                                navigate(`/${user.username}`)
                            }}>
                                Save for later
                            </FlatButton>
                        </Col>
                    </Row>
                }
                <Row>
                    <Col className="d-flex justify-content-center align-items-center">
                        {index !== 0 && <FlatButton onClick={() => {
                            changeIndex(index - 1)
                        }}>
                            Previous
                        </FlatButton>}
                    </Col>
                    <Col>
                        <PrimaryButton isLoading={isLoading} text="Next" padding="8px" borderRadius={"12px"} disabled={isDisabled} onClick={async () => {
                            // await beforeNext()
                            if (index === 1) {
                                const updateProfileResp = await updateUserProfile()
                                if(updateProfileResp.error){
                                    return
                                }
                            }

                            if (index === 3) {
                                if (editingBond && editingBond.bondId) {
                                    await saveBond()
                                }
                                navigate(`/${user.username}`)
                            }
                            changeIndex(index + 1)
                        }} />
                    </Col>
                </Row>
            </Col>
        </Row>
    </>

}


function WelcomeView() {
    const colors = useAppColors()

    return <>
        <BrowserView>
            <Row>
                <Col>
                    <TitleText>Welcome to Schedule.bond!</TitleText>
                    <SubtitleText color={colors.textColor}>The premier Web3 based scheduling app</SubtitleText>
                    <SizedBox height={24} />
                    <Row>
                        <Col>
                            <Row className="d-flex align-items-center justify-content-center" style={{ height: "100%" }}>
                                <img src={UndrawEther} style={{ width: "40%" }} alt={"ethereum"} />
                            </Row>
                        </Col>
                        <Col>
                            <InfoCard padding="24px">
                                <SubtitleText textAlign={"start"} color="black">
                                    Our Goal
                                </SubtitleText>
                                <SizedBox height={12} />
                                <DescriptionText textAlign={"start"}>
                                    You can use Schedule.bond to token-gate meetings, allowing you to guarantee the people meeting with you have value
                                </DescriptionText>
                                <SizedBox height={8} />
                                <DescriptionText>
                                    - NFTs
                                </DescriptionText>
                                <SizedBox height={8} />
                                <DescriptionText>
                                    - ERC20 Tokens
                                </DescriptionText>
                                <SizedBox height={8} />
                                <DescriptionText>
                                    - Ethereum
                                </DescriptionText>
                                <SizedBox height={8} />
                                <DescriptionText>
                                    can all be used to token-gate your time
                                </DescriptionText>
                            </InfoCard>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </BrowserView>
        <MobileView>
            <Row>
                <Col>
                    <TitleText>Welcome to Schedule.bond!</TitleText>
                    <SubtitleText color={colors.textColor}>The premier Web3 based scheduling app</SubtitleText>
                    <SizedBox height={24} />
                    <Row>
                        <Col>
                            <Row className="d-flex align-items-center justify-content-center" style={{ height: "100%" }}>
                                <img src={UndrawEther} style={{ width: "40%" }} alt={"ethereum"} />
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <InfoCard padding="24px">
                                <SubtitleText textAlign={"start"} color="black">
                                    Our Goal
                                </SubtitleText>
                                <SizedBox height={12} />
                                <DescriptionText textAlign={"start"}>
                                    You can use Schedule.bond to token-gate meetings, allowing you to guarantee the people meeting with you have value
                                </DescriptionText>
                                <SizedBox height={8} />
                                <DescriptionText>
                                    - NFTs
                                </DescriptionText>
                                <SizedBox height={8} />
                                <DescriptionText>
                                    - ERC20 Tokens
                                </DescriptionText>
                                <SizedBox height={8} />
                                <DescriptionText>
                                    - Ethereum
                                </DescriptionText>
                                <SizedBox height={8} />
                                <DescriptionText>
                                    can all be used to token-gate your time
                                </DescriptionText>
                            </InfoCard>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </MobileView>
    </>
}

function AccountInfoView({ username, setUsername, bio, setBio, displayName, setDisplayName, selectedImage, setImage }) {
    const colors = useAppColors()
    const { user, jwt } = useContext(Web3Context)


    return <>
        <BrowserView>
            <Row>
                <Col>
                    <TitleText>Add some account info</TitleText>
                    <SizedBox height={24} />
                    <Row>
                        <Col xs={4}>
                            <Row className="d-flex align-items-center justify-content-center" style={{ height: "100%" }} >
                                <Col className="d-flex align-items-center justify-content-center" >
                                    <Center>
                                        <img src={UndrawProfile} style={{ width: "80%" }} alt={"profile"} />
                                    </Center>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <InfoCard padding="24px">

                                <Row>
                                    <Col>
                                        <SubtitleText textAlign={"start"} color="black">
                                            Your Profile
                                        </SubtitleText>
                                        <DescriptionText >Other users will be able to see this info on your profile</DescriptionText>

                                        <SizedBox height={24} />
                                        <UploadImageWidget selectedImage={selectedImage} setImage={setImage} width={140} />
                                    </Col>
                                    <Col>
                                        <CustomTextField
                                            name="Username"
                                            placeholder={"Input your username"}
                                            value={username}
                                            setValue={setUsername}
                                            type={"text"}
                                        />
                                        <CustomTextField
                                            name="Display Name"
                                            placeholder={"Input your display name"}
                                            value={displayName}
                                            setValue={setDisplayName}
                                            type={"text"}
                                        />
                                        <CustomTextField
                                            name="Bio"
                                            placeholder={"Input your bio"}
                                            value={bio}
                                            setValue={setBio}
                                            type={"textarea"}
                                            rows={2}
                                        />
                                    </Col>
                                </Row>



                            </InfoCard>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </BrowserView>
        <MobileView>
            <Row>
                <Col>
                    <TitleText>Add some account info</TitleText>
                    <SizedBox height={24} />
                    {/* <Row>
                        <Col >
                            <Row className="d-flex align-items-center justify-content-center" style={{ height: "100%" }} >
                                <Col className="d-flex align-items-center justify-content-center" >
                                    <Center>
                                        <img src={UndrawProfile} style={{ width: "80%" }} alt={"profile"} />
                                    </Center>
                                </Col>
                            </Row>
                        </Col>
                        </Row> */}

                    <Row>
                        <Col>
                            <InfoCard padding="24px">

                                <Row>
                                    <Col>
                                        <SubtitleText textAlign={"start"} color="black">
                                            Your Profile
                                        </SubtitleText>
                                        <DescriptionText >Other users will be able to see this info on your profile</DescriptionText>

                                        <SizedBox height={24} />
                                        <UploadImageWidget selectedImage={selectedImage} setImage={setImage} width={140} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <CustomTextField
                                            name="Username"
                                            placeholder={"Input your username"}
                                            value={username}
                                            setValue={setUsername}
                                            type={"text"}
                                        />
                                        <CustomTextField
                                            name="Display Name"
                                            placeholder={"Input your display name"}
                                            value={displayName}
                                            setValue={setDisplayName}
                                            type={"text"}
                                        />
                                        <CustomTextField
                                            name="Bio"
                                            placeholder={"Input your bio"}
                                            value={bio}
                                            setValue={setBio}
                                            type={"textarea"}
                                            rows={2}
                                        />
                                    </Col>
                                </Row>



                            </InfoCard>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </MobileView>
    </>
}

function SyncEmailView({ email, isLoading, setShowEmailModal }) {
    const colors = useAppColors()
    const { user, jwt } = useContext(Web3Context)


    return <>
        <BrowserView>
            <Row>
                <Col>
                    <TitleText>Sync your email</TitleText>
                    <SizedBox height={24} />
                    <Row>
                        <Col xs={6}>
                            <Row className="d-flex align-items-center justify-content-center" style={{ height: "100%" }} >
                                <Col className="d-flex align-items-center justify-content-center" >
                                    <Center>
                                        <img src={UndrawCalendar} style={{ width: "80%" }} alt={"profile"} />
                                    </Center>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <InfoCard padding="24px">

                                <Row>
                                    <Col>
                                        <SubtitleText textAlign={"start"} color="black">
                                            Your Email
                                        </SubtitleText>
                                        <DescriptionText>
                                            If you want to be able to fully utilize the calendar features of Schedule.bond you need sync an email. Features include:
                                        </DescriptionText>
                                        <SizedBox height={8} />
                                        <DescriptionText>
                                            - Allowing others to schedule meetings with you
                                        </DescriptionText>
                                        <SizedBox height={8} />
                                        <DescriptionText>
                                            - Avoiding busy times on your schedule
                                        </DescriptionText>
                                        <SizedBox height={8} />
                                        <DescriptionText>
                                            - Updating your Google Calendar with meetings made through the app
                                        </DescriptionText>
                                        <SizedBox height={24} />
                                        <SecondaryButton
                                            text={email ? email : "+ Sync Email"}
                                            onClick={() => {
                                                setShowEmailModal(true);
                                            }}
                                            disabled={isLoading}
                                            isLoading={isLoading}
                                        />
                                        <SizedBox height={32} />
                                    </Col>

                                </Row>



                            </InfoCard>
                        </Col>
                    </Row>
                </Col>

            </Row>
        </BrowserView>
        <MobileView>
            <Row>
                <Col>
                    <TitleText>Sync your email</TitleText>
                    <SizedBox height={24} />
                    <Row>
                        <Col >
                            <Row className="d-flex align-items-center justify-content-center" style={{ height: "100%" }} >
                                <Col className="d-flex align-items-center justify-content-center" >
                                    <Center>
                                        <img src={UndrawCalendar} style={{ width: "60%" }} alt={"profile"} />
                                    </Center>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <InfoCard padding="24px">

                                <Row>
                                    <Col>
                                        <SubtitleText textAlign={"start"} color="black">
                                            Your Email
                                        </SubtitleText>
                                        <DescriptionText>
                                            If you want to be able to fully utilize the calendar features of Schedule.bond you need sync an email. Features include:
                                        </DescriptionText>
                                        <SizedBox height={8} />
                                        <DescriptionText>
                                            - Allowing others to schedule meetings with you
                                        </DescriptionText>
                                        <SizedBox height={8} />
                                        <DescriptionText>
                                            - Avoiding busy times on your schedule
                                        </DescriptionText>
                                        <SizedBox height={8} />
                                        <DescriptionText>
                                            - Updating your Google Calendar with meetings made through the app
                                        </DescriptionText>
                                        <SizedBox height={24} />
                                        <TitleText>
                                            Please Sync your email on a desktop
                                        </TitleText>
                                        {/* <SecondaryButton
                                            text={email ? email : "+ Sync Email"}
                                            onClick={() => {
                                                setShowEmailModal(true);
                                            }}
                                            disabled={isLoading}
                                            isLoading={isLoading}
                                            fontSize={"16px"}
                                        /> */}
                                        <SizedBox height={32} />
                                    </Col>

                                </Row>



                            </InfoCard>
                        </Col>
                    </Row>
                </Col>

            </Row>
        </MobileView>
    </>
}

function UpdateBondsView({ addBond, removeBond, saveBond, revertBond, addRule, bonds, editingBond, setRule, selectBond, removeRule, ownedNfts, ownedNftContractData, isLoading }) {
    const colors = useAppColors()
    const { user, jwt } = useContext(Web3Context)


    return <>
        <BrowserView>
            <Row>
                <Col>
                    <TitleText>Create your bonds</TitleText>
                    <SizedBox height={24} />
                    <Row>
                        <Col xs={4}>
                            <Row className="d-flex align-items-center justify-content-center" style={{ height: "100%" }} >
                                <Col className="d-flex align-items-center justify-content-center" >
                                    <Center>
                                        <img src={UndrawLink} style={{ width: "80%" }} alt={"profile"} />
                                    </Center>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <InfoCard padding="24px">

                                <Row>
                                    <Col>
                                        <SubtitleText textAlign={"start"} color="black">
                                            Your Bonds
                                        </SubtitleText>
                                        <BondsEditor2
                                            addBond={addBond}
                                            removeBond={removeBond}
                                            saveBond={saveBond}
                                            revertBond={revertBond}
                                            addRule={addRule}
                                            bonds={bonds}
                                            editingBond={editingBond}
                                            setRule={setRule}
                                            selectBond={selectBond}
                                            removeRule={removeRule}
                                            nfts={ownedNfts}
                                            ownedNftContractData={ownedNftContractData}
                                            isLoading={isLoading}
                                            user={user}
                                        />

                                        <SizedBox height={32} />
                                    </Col>

                                </Row>



                            </InfoCard>
                        </Col>
                    </Row>
                </Col>

            </Row>
        </BrowserView>
        <MobileView>
            <Row>
                <Col>
                    <TitleText>Create your bonds</TitleText>
                    <SizedBox height={24} />
                    <Row>
                        <Col >
                            <Row className="d-flex align-items-center justify-content-center" style={{ height: "100%" }} >
                                <Col className="d-flex align-items-center justify-content-center" >
                                    <Center>
                                        <img src={UndrawLink} style={{ width: "50%" }} alt={"profile"} />
                                    </Center>
                                </Col>
                            </Row>
                        </Col>
                        </Row>
                        <Row>
                        <Col>
                            <InfoCard padding="24px">

                                <Row>
                                    <Col>
                                        
                                        <BondsEditor2
                                            addBond={addBond}
                                            removeBond={removeBond}
                                            saveBond={saveBond}
                                            revertBond={revertBond}
                                            addRule={addRule}
                                            bonds={bonds}
                                            editingBond={editingBond}
                                            setRule={setRule}
                                            selectBond={selectBond}
                                            removeRule={removeRule}
                                            nfts={ownedNfts}
                                            ownedNftContractData={ownedNftContractData}
                                            isLoading={isLoading}
                                            user={user}
                                        />

                                        <SizedBox height={32} />
                                    </Col>

                                </Row>
                            </InfoCard>
                        </Col>
                    </Row>
                </Col>

            </Row>
        </MobileView>
    </>
}

function OnboardingViews({ index, setIndex, username, setUsername, bio, setBio, displayName, setDisplayName, selectedImage, setImage, isLoading, setIsLoading, email, setShowEmailModal, addBond, removeBond, saveBond, revertBond, addRule, bonds, editingBond, setRule, selectBond, removeRule, ownedNfts, ownedNftContractData }) {


    return <>
        {index === 0 && <WelcomeView />}
        {index === 1 && <AccountInfoView
            username={username}
            setUsername={setUsername}
            bio={bio}
            setBio={setBio}
            displayName={displayName}
            setDisplayName={setDisplayName}
            selectedImage={selectedImage}
            setImage={setImage}
        />
        }
        {index === 2 && <SyncEmailView email={email} isLoading={isLoading} setShowEmailModal={setShowEmailModal} />
        }
        {
            index === 3 && <UpdateBondsView addBond={addBond}
                removeBond={removeBond}
                saveBond={saveBond}
                revertBond={revertBond}
                addRule={addRule}
                bonds={bonds}
                editingBond={editingBond}
                setRule={setRule}
                selectBond={selectBond}
                removeRule={removeRule}
                nfts={ownedNfts}
                ownedNftContractData={ownedNftContractData}
                isLoading={isLoading}
                />
        }


    </>
}


export default function Onboarding() {
    const { user, setUser, jwt, showSnackbar, log, bonds, setBonds } = useContext(Web3Context)
    const [index, setIndex] = useState(0)
    const [username, setUsername] = useState("")
    const [displayName, setDisplayName] = useState("")
    const [bio, setBio] = useState("")
    const [selectedImage, setImage] = useState(undefined)
    const [showEmailModal, setShowEmailModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [externalPopup, setExternalPopup] = useState(null);
    const [email, setEmail] = useState(undefined)
    const [localBonds, setLocalBonds] = useState([]);
    const [editingBond, setEditingBond] = useState({});
    const [previousBond, setPreviousBond] = useState({});
    const [ownedNfts, setOwnedNfts] = useState([]);
    const [ownedNftContractData, setOwnedNftContractData] = useState([]);
    let [searchParams, setSearchParams] = useSearchParams();
    const [initialIndex, setInitialIndex] = useState(searchParams.get("i"));
    const [authUrl, setAuthUrl] = useState(undefined)


    async function getInitialAuthUrl(){
        try{
            const aResponse = await api.getAuthUrl({platform: "google", device: "mobile", route: "onboarding", jwt:jwt})
            if(aResponse.error){
                return
            }
            console.log("authUrl: ", aResponse.url)
            setAuthUrl(aResponse.url)
        }catch(e){

        }
    }

    function attachImages(_nfts, _ownedNftsContracts) {
        if (!_nfts || !_ownedNftsContracts) {
            return;
        }
        for (const o of _ownedNftsContracts) {
            let n = _nfts.filter((n) => n.contract.address === o.address);
            if (n.length > 0) {
                let i = n ? utils.formatIPFStoGateway(n[0].metadata.image) : undefined;
                o.image = i;
                o.logoURI = i;
            }
        }
        setOwnedNftContractData(_ownedNftsContracts);
    }

    useEffect(() => {
        if(initialIndex){
            try{
                let i = parseInt(initialIndex)
                setIndex(i)
            }catch(e){

            }
        }
    }, [initialIndex])

    async function setInitialData() {
        
        if (!user) {
            return;
        }
        setUsername(user.username ? user.username : "");
        setDisplayName(user.displayName ? user.displayName : "");
        setBio(user.bio ? user.bio : "");
        setEmail(user.email ? user.email : "");
        setImage(user.avatarUrl ? user.avatarUrl : config.defaultAvatarUrl);
        setLocalBonds(bonds ? bonds : [])
        const nftsResp = await api.getUserNfts({ jwt: jwt });
        if (nftsResp.error) {
            log({
                eventName: "getUserNfts error",
                eventParams: {
                    userUid: user ? user._id : undefined,
                    error: nftsResp.error,
                },
            });
            showSnackbar({ message: nftsResp.error.toString(), severity: "error" });
        }
        log({
            eventName: "getUserNfts",
            eventParams: {
                userUid: user ? user._id : undefined,
            },
        });
        setOwnedNfts(nftsResp.nfts.ownedNfts);
        attachImages(nftsResp.nfts.ownedNfts, nftsResp.nftContracts);
    }

    async function disconnectAuth() {
        try {
            setShowEmailModal(false);
            setIsLoading(true);
            const response = await api.disconnectAuth({ jwt });
            if (response.error) {
                const e = response.error;
                setIsLoading(false);
                log({
                    eventName: "disconnectAuth error",
                    eventParams: {
                        userUid: user ? user._id : undefined,
                        error: e,
                    },
                });
                showSnackbar({ message: e.toString(), severity: "error" });
                return;
            }
            setUser(response.user);
            setEmail(undefined);
            showSnackbar({
                message: "Successfully Disconnected Email",
                severity: "success",
            });
            setIsLoading(false);
            return;
        } catch (e) {
            setIsLoading(false);
            log({
                eventName: "general error",
                eventParams: {
                    userUid: user ? user._id : undefined,
                    error: e,
                },
            });
            showSnackbar({ message: e.message, severity: "error" });
            return;
        }
    }

    async function connectGoogle(e) {
        try {
            setShowEmailModal(false);
            setIsLoading(true);
            const w = 1200;
            const h = 600;
            const left = window.screenX + (window.outerWidth - w) / 2;
            const top = window.screenY + (window.outerHeight - h) / 2.5;
            const title = `Schedule.bond CONNECT TO GOOGLE`;
            const popup = window.open(
                "",
                title,
                `width=${w},height=${h},left=${left}`
            );
            const getUrlResp = await api.getAuthUrl({ jwt: jwt, platform: "google" });
            if (getUrlResp.error) {
                showSnackbar({ message: getUrlResp.error.toString(), severity: "error" });
                log({
                    eventName: "getAuthUrl error",
                    eventParams: {
                        userUid: user ? user._id : undefined,
                        error: getUrlResp.error,
                    },
                });
                setIsLoading(false);
                return;
            }
            log({
                eventName: "getAuthUrl",
                eventParams: {
                    userUid: user ? user._id : undefined,
                },
            });
            const url = getUrlResp.url;

            const urlSearchParams = new URLSearchParams(url);
            const params = Object.fromEntries(urlSearchParams.entries());
            // Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
            let base = url.split("?")[0];
            let client_id = params.client_id; // "some_value"
            let rearragnedParams = "?client_id=" + client_id;
            for (const key of Object.keys(params)) {
                if (key !== "client_id") {
                    rearragnedParams = rearragnedParams + `&${key}=${params[key]}`;
                }
            }
            popup.location = url;
            // popup.location = base + rearragnedParams;
            // showSnackbar({ message: url, severity: "success" });
            // const popup = window.open(
            //   url,
            //   title,
            //   `width=${w},height=${h},left=${left}`
            // );
            setExternalPopup(popup);
        } catch (e) {
            log({
                eventName: "general error",
                eventParams: {
                    userUid: user ? user._id : undefined,
                    error: e,
                },
            });
            showSnackbar({ message: e.message, severity: "error" });
        }
    }


    useEffect(() => {

        setInitialData()
        if(user && (!authUrl || authUrl.length === 0)){
            getInitialAuthUrl()
        }

    }, [user])

    async function changeIndex(newIndex) {
        if (newIndex < 0) {
            setIndex(0)
            return
        }

        setIndex(newIndex)
    }

    async function addBond() {
        setEditingBond({ rules: [], bondId: v4(), isNew: true });
    }

    async function addRule({ rule }) {
        if (!rule._id) {
            rule.isNew = true
            rule._id = v4()
        }
        console.log("ADDRULE: ", rule)
        editingBond.rules = [...editingBond.rules, rule];
        console.log("EditingbondRules: ", editingBond.rules)

        setEditingBond(editingBond);
    }

    async function setRule({ bondId, ruleId, rule }) {
        let b = [...localBonds];
        let bondInd
        let ruleInd
        for (let i = 0; i < localBonds.length; i++) {
            if (localBonds[i].bondId === bondId) {
                bondInd = i
            }
        }

        for (let j = 0; j < localBonds[bondId].rules.length; j++) {
            if (ruleId === localBonds[bondId].rules[j]._id) {
                ruleInd = j
            }
        }


        b[bondInd].rules[ruleInd] = rule;
        setLocalBonds(b);
    }

    async function removeRule({ ruleId }) {
        console.log("ruleId: ", ruleId)
        let r = [...editingBond.rules];
        let newR = []
        for (const rule of r) {
            if (ruleId !== rule._id) {
                newR.push(rule)
            }
        }
        editingBond.rules = newR;
        setEditingBond(editingBond);
    }

    async function selectBond({ bondId }) {
        let b = [...localBonds];
        let bondInd
        for (let i = 0; i < b.length; i++) {
            if (b[i].bondId === bondId) {
                bondInd = i
            }
        }

        const bo = b[bondInd]
        setEditingBond(bo);
        setPreviousBond(bo);
        b.splice(bondInd, 1);
        setLocalBonds(b);
    }

    async function saveBond() {
        let nb = [...localBonds];

        if (!editingBond.rules || editingBond.rules.length === 0) {
            setEditingBond({});
        } else {
            console.log("Saving bond")
            nb.push(editingBond);
            setIsLoading(true)
            const response = await api.updateScheduleBonds({ bonds: [editingBond], jwt: jwt })
            if (response.error) {
                setIsLoading(false)
                showSnackbar({ message: response.error, severity: "error" })
                return
            }
            const userDataResp = await api.getUserData2({ jwt: jwt })
            setBonds(userDataResp.bonds)

            setIsLoading(false)
            setLocalBonds(userDataResp.bonds);
            setEditingBond({});
        }
    }

    async function revertBond() {
        let nb = [...localBonds];
        const nbl = nb.length;
        if (!previousBond.rules || previousBond.rules.length === 0) {
            setEditingBond({});
        } else {
            nb.push(previousBond);
            setLocalBonds(nb);
            setEditingBond({});
        }
    }

    async function removeBond() {
        editingBond.action = "delete"
        setIsLoading(true)
        const resp = await api.updateScheduleBonds({ bonds: [editingBond], jwt: jwt })
        const userDataResp = await api.getUserData2({ jwt: jwt })
        setBonds(userDataResp.bonds)
        setLocalBonds(userDataResp.bonds);
        setEditingBond({});

        setIsLoading(false)
    }

    async function updateUserProfile() {
        try {
            setIsLoading(true);
            let newUser = { ...user };
            newUser.username = username;
            newUser.displayName = displayName;
            newUser.bio = bio;

            try {
                const fbResp = await api.createFbToken({ jwt: jwt });

                await firebaseUtils.signinWithFirebaseToken(fbResp.firebaseToken);
            } catch (e) {
                console.log(e);
            }

            let avatarUrl;
            let compressedFile
            if (selectedImage !== user.avatarUrl) {
                if (
                    selectedImage &&
                    (typeof selectedImage !== "string" ||
                        !selectedImage.includes("https://"))
                ) {
                    const options = {
                        maxSizeMB: 1,
                        maxWidthOrHeight: 1920,
                        useWebWorker: true
                    }
                    compressedFile = await imageCompression(selectedImage, options);
                    const avatarUploadResponse = await firebaseUtils.updateAvatarImage(
                        compressedFile,
                        user.evmAddress
                    );
                    if (avatarUploadResponse.error) {
                        showSnackbar({
                            message: avatarUploadResponse.error,
                            severity: "error",
                        });
                        setIsLoading(false);
                        return;
                    }
                    avatarUrl = avatarUploadResponse.downloadUrl;
                } else {

                    avatarUrl = compressedFile;
                }

                const avResponse = await api.updateAvatar({
                    url: avatarUrl,
                    platform: "web",
                    isDelete: selectedImage === undefined,
                    jwt: jwt,
                });
                if (avResponse.error) {
                    log({
                        eventName: "updateAvatar error",
                        eventParams: {
                            userUid: user ? user._id : undefined,
                            error: avResponse.error,
                        },
                    });
                    showSnackbar({ message: avResponse.error, severity: "error" });
                    setIsLoading(false);
                    return;
                }
                log({
                    eventName: "updateAvatar",
                    eventParams: {
                        userUid: user ? user._id : undefined,
                    },
                });
                setUser(avResponse.user);
            }

            const response = await api.updateUserData2({ user: newUser, jwt: jwt });
            if (response.error) {
                showSnackbar({ message: response.error.toString(), severity: "error" });
                log({
                    eventName: "updateUserData error",
                    eventParams: {
                        userUid: user ? user._id : undefined,
                        error: response.error,
                    },
                });
                setIsLoading(false);
                return;
            }
            setUser(response.user);
            showSnackbar({
                message: "Successfully updated profile!",
                severity: "success",
            });
            log({
                eventName: "updateUserData",
                eventParams: {
                    userUid: user ? user._id : undefined,
                },
            });
            setIndex(index + 1)
            setIsLoading(false)
            //   navigate("/" + (otherUsername ? otherUsername : response.user.username));
        } catch (e) {
            log({
                eventName: "general error",
                eventParams: {
                    userUid: user ? user._id : undefined,
                    error: e.message,
                },
            });
            showSnackbar({ message: e.message, severity: "error" });
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (!externalPopup) {
            return;
        }

        const timer = setInterval(async () => {
            try {
                if (!externalPopup) {
                    timer && clearInterval(timer);
                    return;
                }
                const currentUrl = externalPopup.location.href;
                if (!currentUrl) {
                    return;
                }
                const searchParams = new URL(currentUrl).searchParams;
                const code = searchParams.get("code");
                if (code) {
                    externalPopup.close();
                    const r = await api.processAuthCode({
                        authCode: code,
                        platform: "google",
                        jwt: jwt,
                    });

                    if (r.error) {
                        showSnackbar({ message: r.error.toString(), severity: "error" });
                        log({
                            eventName: "processAuthCode error",
                            eventParams: {
                                userUid: user ? user._id : undefined,
                                error: r.error,
                            },
                        });
                        setIsLoading(false);
                    } else {
                        log({
                            eventName: "processAuthCode",
                            eventParams: {
                                userUid: user ? user._id : undefined,
                            },
                        });
                        showSnackbar({
                            message: "Successfully Synced Email!",
                            severity: "success",
                        });
                        setUser(r.user);
                        setEmail(r.user.email);
                    }
                    setExternalPopup(null);
                    setIsLoading(false);
                    timer && clearInterval(timer);
                }
            } catch (e) { }
        }, 500);
    }, [externalPopup]);

    return <>
        <BrowserView>
            <WidthContainer width="60%" topPadding={32}>
                {!user && <Row>
                    <Center>
                        <TitleText>
                            Please Login
                        </TitleText></Center></Row>}
                {user && <Row>
                    <OnboardingViews
                        index={index}
                        setIndex={setIndex}
                        username={username}
                        setUsername={setUsername}
                        displayName={displayName}
                        setDisplayName={setDisplayName}
                        bio={bio}
                        setBio={setBio}
                        selectedImage={selectedImage}
                        setImage={setImage}
                        showEmailModal={showEmailModal}
                        setShowEmailModal={setShowEmailModal}
                        connectGoogle={connectGoogle}
                        disconnectAuth={disconnectAuth}
                        email={email}
                        setEmail={setEmail}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        addBond={addBond}
                        removeBond={removeBond}
                        saveBond={saveBond}
                        revertBond={revertBond}
                        addRule={addRule}
                        bonds={localBonds}
                        editingBond={editingBond}
                        setRule={setRule}
                        selectBond={selectBond}
                        removeRule={removeRule}
                        nfts={ownedNfts}
                        ownedNftContractData={ownedNftContractData}
                    />
                </Row>}
                <SizedBox height={"20px"} />
                <BottomControls index={index} changeIndex={changeIndex} isLoading={isLoading} updateUserProfile={updateUserProfile} email={email} username={username} saveBond={saveBond} editingBond={editingBond} />
                <SizedBox height={"32px"} />
            </WidthContainer>
        </BrowserView>
        <MobileView>
            <WidthContainer width="90%" topPadding={32}>
                {!user && <Row>
                    <Center>
                        <TitleText>
                            Please Login
                        </TitleText></Center></Row>}
                {user && <Row>
                    <OnboardingViews
                        index={index}
                        setIndex={setIndex}
                        username={username}
                        setUsername={setUsername}
                        displayName={displayName}
                        setDisplayName={setDisplayName}
                        bio={bio}
                        setBio={setBio}
                        selectedImage={selectedImage}
                        setImage={setImage}
                        showEmailModal={showEmailModal}
                        setShowEmailModal={setShowEmailModal}
                        connectGoogle={connectGoogle}
                        disconnectAuth={disconnectAuth}
                        email={email}
                        setEmail={setEmail}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        addBond={addBond}
                        removeBond={removeBond}
                        saveBond={saveBond}
                        revertBond={revertBond}
                        addRule={addRule}
                        bonds={localBonds}
                        editingBond={editingBond}
                        setRule={setRule}
                        selectBond={selectBond}
                        removeRule={removeRule}
                        nfts={ownedNfts}
                        ownedNftContractData={ownedNftContractData}
                    />
                </Row>}
                <SizedBox height={"20px"} />
                <BottomControls index={index} changeIndex={changeIndex} isLoading={isLoading} updateUserProfile={updateUserProfile} email={email} username={username} saveBond={saveBond} editingBond={editingBond} />
                <SizedBox height={"32px"} />
            </WidthContainer>
        </MobileView>
        <EmailModal
            show={showEmailModal}
            setShow={setShowEmailModal}
            connectGoogle={connectGoogle}
            disconnectAuth={disconnectAuth}
            authUrl={authUrl}
        />
    </>
}