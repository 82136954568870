import { useContext, useEffect } from "react";
import { Route, useNavigate } from "react-router-dom";
import { Web3Context } from "../contexts/Web3Context";

export default function AuthenticatedRoute({ children }) {
  const navigate = useNavigate();
  const { user, networkId, showSnackbar } = useContext(Web3Context);
  useEffect(() => {
    if (!user || (networkId !== 1 && networkId !== 5)) {
      if (networkId !== 1 && networkId !== 5) {
        showSnackbar({
          message: "Please switch to the Ethereum Network",
          severity: "error",
        });
      }
      navigate("/");
    }
  }, [user]);
  return <>{children}</>;
}
