import styled from "styled-components";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { StandardShadow } from "./StandardShadow";
import { SelectedCircle } from "./SelectedCircle";
import Moment from "react-moment";
import { useAppColors } from "../utils/useAppColors";
import { SubtitleText } from "./SubtitleText";
import { BrowserView, MobileView } from "react-device-detect";

const TimeCardWrapper = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  justify-content: space-between;
  border-top: 2px solid ${(props) => props.borderColor};
  border-left: 2px solid ${(props) => props.borderColor};
  border-bottom: 2px solid ${(props) => props.borderColor};
  border-right: 2px solid ${(props) => props.borderColor};
  border-radius: 24px;
  padding: 20px;
  cursor: pointer;
  background-color: ${(props) => props.backgroundColor};
  &:hover {
    background-color: ${(props) => props.hoverBackgroundColor};
    color: black;
  }
`;
const TimeCardWrapperMobile = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  justify-content: space-between;
  border-top: 2px solid ${(props) => props.borderColor};
  border-left: 2px solid ${(props) => props.borderColor};
  border-bottom: 2px solid ${(props) => props.borderColor};
  border-right: 2px solid ${(props) => props.borderColor};
  border-radius: 24px;
  padding: 20px;
  cursor: pointer;
  background-color: ${(props) => props.backgroundColor};
  &:hover {
    background-color: ${(props) => props.hoverBackgroundColor};
    color: black;
  }
`;

export default function TimeCard({ selectTime, time, selectedTime }) {
  const colors = useAppColors();
  const bondClass =
    selectedTime && selectedTime.start === time.start
      ? "active-gradient-selector"
      : "";

  return (
    <>
      <BrowserView>
        <StandardShadow borderRadius={"24px"}>
          <TimeCardWrapper
            className={bondClass}
            borderColor={
              selectedTime && selectedTime.start === time.start
                ? colors.successGreen
                : "black"
            }
            selected={selectedTime && selectedTime.start === time.start}
            onClick={() => {
              selectTime(time);
            }}
            hoverBackgroundColor={colors.textFieldColor}
            backgroundColor={colors.listItemColor}
          >
            <SubtitleText
              color={
                selectedTime && selectedTime.start === time.start
                  ? colors.titleColor
                  : colors.textColor
              }
              fontSize={16}
            >
              <Moment format="h:mm a">{time.start}</Moment>
            </SubtitleText>
            {selectedTime && selectedTime.start === time.start ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <SelectedCircle>
                  <BsFillCheckCircleFill
                    style={{ zIndex: 5 }}
                    color={colors.primaryColor}
                    size={20}
                  />
                </SelectedCircle>
              </div>
            ) : null}
          </TimeCardWrapper>
        </StandardShadow>
      </BrowserView>
      <MobileView>
        <StandardShadow borderRadius={"24px"}>
          <TimeCardWrapperMobile
            className={bondClass}
            borderColor={
              selectedTime && selectedTime.start === time.start
                ? colors.successGreen
                : "black"
            }
            selected={selectedTime && selectedTime.start === time.start}
            onClick={() => {
              selectTime(time);
            }}
            hoverBackgroundColor={colors.textFieldColor}
            backgroundColor={colors.listItemColor}
          >
            <SubtitleText
              color={
                selectedTime && selectedTime.start === time.start
                  ? colors.titleColor
                  : colors.textColor
              }
              fontSize={16}
            >
              <Moment format="h:mm a">{time.start}</Moment>
            </SubtitleText>
            {selectedTime && selectedTime.start === time.start ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <SelectedCircle>
                  <BsFillCheckCircleFill
                    style={{ zIndex: 5 }}
                    color={colors.primaryColor}
                    size={20}
                  />
                </SelectedCircle>
              </div>
            ) : null}
          </TimeCardWrapperMobile>
        </StandardShadow>
      </MobileView>
    </>
  );
}
