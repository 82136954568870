import React from "react";
import { BsTwitter } from "react-icons/bs";
import styled from "styled-components";
import { PrimaryButton } from "./PrimaryButton";
import { StandardShadow } from "./StandardShadow";

const VerifyTwitterButtonWrapper = styled.button`
  width: 100%;
  background-color: #1da1f2;
  color: white;
`;

export function VerifyTwitterButton({ text, onClick, isLoading }) {
  return (
    <PrimaryButton
      color={"white"}
      onClick={onClick}
      backgroundColor={"#1da1f2"}
      text={text}
      isLoading={isLoading}
      Icon={BsTwitter}
    />
  );
}
