import React from "react";
import styled from "styled-components";
import { useAppColors } from "../utils/useAppColors";
import { StandardShadow } from "./StandardShadow";

const InfoCardWrapper = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border-radius: 20px;
  background-color: ${(props) => props.backgroundColor};
  padding: ${(props) => (props.padding ? props.padding : "0px")};
  border: ${(props => props.border)};
`;

export default function InfoCard({ children, width, height, padding, backgroundColor,border }) {
  const colors = useAppColors();
  return (
    <InfoCardWrapper
      backgroundColor={backgroundColor ? backgroundColor : colors.cardBackgroundColor}
      width={width}
      height={height}
      padding={padding}
      border={border ? border : "none"}
    >
      {children}
    </InfoCardWrapper>
  );
}
