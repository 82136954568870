import { BrowserView, MobileView } from "react-device-detect";
import { Col, Modal, Row } from "react-bootstrap";
import { Center } from "./Center";
import { SizedBox } from "./SizedBox";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import { CustomTextField } from "./CustomTextInput";
import { PrimaryButton } from "./PrimaryButton";
import { useContext, useState } from "react";
import { Web3Context } from "../contexts/Web3Context";
import { TitleText } from "./TitleText";
import api from "../utils/api";
import { FlatButton } from "./FlatButton";


function SendCodeInput({ phoneNumber, setPhoneNumber, addPhoneNumber, isLoading }) {
    return <>
        <Col>
            <CustomTextField
                name={"Phone Number"}
                placeholder={"ex. 123-456-7890"}
                value={phoneNumber}
                setValue={setPhoneNumber}
            />
            <PrimaryButton text="Submit" disabled={phoneNumber.length === 0} isLoading={isLoading} onClick={addPhoneNumber} />
        </Col>
    </>
}

function VerifyCodeInput({ code, setCode, addPhoneNumber, isLoading }) {
    return <>
        <Col>
            <CustomTextField
                name={"Verification Code"}
                placeholder={"ex. 123456"}
                value={code}
                setValue={setCode}
            />
            <PrimaryButton text="Submit" disabled={code.length !== 6} isLoading={isLoading} onClick={addPhoneNumber} />
            <SizedBox height="12px" />
            <Center>
            <FlatButton onClick={() => {
                addPhoneNumber()
            }}>
                Didn't receive code? Resend code.
            </FlatButton>
            </Center>
        </Col>
    </>
}

function SyncComplete() {
    
}

export default function AddPhoneModal({ show, setShow }) {
    const { user, setUser, jwt,  showSnackbar, log } = useContext(Web3Context)
    const [now, setNow] = useState(new Date())
    const [phoneNumber, setPhoneNumber] = useState(user.phoneNumber ? user.phoneNumber : "")
    const [code, setCode] = useState("")
    const [isLoading, setIsLoading] = useState()
    const [changingPhoneNumber, setChangingPhoneNumber] = useState(true)
    const [codeGenerated, setCodeGenerated] = useState(false)
    async function addPhoneNumber() {
        try {
            setIsLoading(true)
            const response = await api.addPhoneNumber({ phoneNumber: phoneNumber, code: code, jwt:jwt })
            if (response.error) {
                throw Error(response.error)
            }

            if( response.message === "code sent" ){
                console.log("USER: ", response.user )
                setUser(response.user)
                setCodeGenerated(true)
                showSnackbar({ message: "Please check your phone for the authorization code", severity: "success" })
                setIsLoading(false)
                return
            }

            setUser(response.user)
            setCode("")
            setShow(false)
            showSnackbar({message:"Phone number verified", severity: "success"})
            setIsLoading(false)
            return
        } catch (e) {
            showSnackbar({ message: e.message, severity: "error" })
            log({
                eventName: "addPhoneNumber error",
                eventParams: {
                    userUid: user ? user._id : undefined,
                    error: e.message,
                },
            })
            setIsLoading(false)
            return
        }
    }

    return <>
        <BrowserView>
            <Modal
                dialogClassName="modal-60w"
                show={show}
                onHide={() => {
                    setShow(false);
                }}
            >
                <Modal.Body>
                    <Center>
                        <div style={{ width: "90%" }}>
                            <SizedBox height={8} />
                            <TitleText>
                                Add Phone Number
                            </TitleText>
                            {(!codeGenerated && (changingPhoneNumber || !phoneNumber || phoneNumber.length === 0)) &&
                                <>
                                    <SendCodeInput phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} addPhoneNumber={addPhoneNumber} isLoading={isLoading} />
                                </>
                            }
                            {codeGenerated && user && user.phoneNumberCode && new Date(user.phoneNumberCode.expires) > now &&
                                <>
                                    <VerifyCodeInput code={code} setCode={setCode} addPhoneNumber={addPhoneNumber}  isLoading={isLoading} />
                                </>
                            }
                        </div>
                    </Center>
                </Modal.Body>
            </Modal>
        </BrowserView>
        <MobileView>
            <BottomSheet
                open={show}
                onDismiss={() => {
                    setShow(false);
                }}
            >
                {!phoneNumber &&
                    <>
                        <SendCodeInput phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} />
                    </>
                }
                {user && user.phoneNumberCode && user.phoneNumberCode < now &&
                    <>
                        <VerifyCodeInput code={code} setCode={setCode} />
                    </>
                }


            </BottomSheet>
        </MobileView>
    </>
} 