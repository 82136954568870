import React from "react";
import styled from "styled-components";
import { useAppColors } from "../utils/useAppColors";
import { StandardShadow } from "./StandardShadow";

const CustomTextFieldWrapper = styled.input`
  width: 100%;
  border-radius: 24px;
  background-color: ${(props) => props.backgroundColor};
  border-left: 2px solid ${(props) => props.borderColor};
  border-top: 2px solid ${(props) => props.borderColor};
  border-right: 2px solid ${(props) => props.borderColor};
  border-bottom: 2px solid ${(props) => props.borderColor};
  color: ${(props) => props.color};
  font-size: 18px;
  font-weight: bold;
  padding: 20px 40px 20px 40px;
  outline: none;
  disabled: ${(props) => (props.disabled ? props.disabled : false)};
`;

const InputName = styled.div`
  color: ${(props) => props.color};
  font-size: 14px;
  margin-bottom: 12px;
  font-weight: bold;
  text-align: start;
  outline: none;
`;

const InputNameWrapper = styled.div`
  background-color: ${(props) => props.backgroundColor};
  position: relative;
  top: 24px;
  left: 24px;
  padding-right: 4px;
  padding-left: 4px;
  outline: none;
`;

const CustomTextAreaWrapper = styled.textarea`
  width: 100%;
  border-radius: 24px;
  resize: none;
  background-color: ${(props) => props.backgroundColor};
  border: none;
  color: ${(props) => props.color};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "18px")};
  font-weight: bold;
  padding: 20px 40px 20px 40px;
  outline: none;
  disabled: ${(props) => (props.disabled ? props.disabled : false)};
`;

export const CustomTextField = ({
  name,
  placeholder,
  value,
  setValue,
  type,
  rows,
  disabled,
  fontSize,
}) => {
  const colors = useAppColors();
  if (type === "textarea") {
    return (
      <div>
        {name ? (
          <div className="d-flex">
            <InputNameWrapper backgroundColor={colors.inputNameBackgroundColor}>
              <InputName color={"black"}>{name}</InputName>
            </InputNameWrapper>
          </div>
        ) : null}
        <StandardShadow
          borderRadius={"24px"}
          style={{ borderRadius: "24px", border: "2px solid black" }}
        >
          <CustomTextAreaWrapper
            rows={rows}
            name={name}
            placeholder={placeholder}
            value={value}
            fontSize={fontSize}
            // borderColor={colors.textFieldBorderColor}
            borderColor={"black"}
            backgroundColor={colors.activeTextFieldColor}
            // color={colors.textFieldTextColor}
            color={"black"}
            onChange={(e) => {
              if (setValue) {
                setValue(e.target.value);
              }
            }}
          />
        </StandardShadow>
      </div>
    );
  }
  return (
    <div>
      {name ? (
        <div className="d-flex">
          <InputNameWrapper backgroundColor={colors.inputNameBackgroundColor}>
            <InputName color={"black"}>{name}</InputName>
          </InputNameWrapper>
        </div>
      ) : null}
      <StandardShadow borderRadius={"24px"}>
        <CustomTextFieldWrapper
          type={type ?? "text"}
          name={name}
          placeholder={placeholder}
          value={value}
          // borderColor={colors.textFieldBorderColor}
          borderColor={"black"}
          backgroundColor={colors.activeTextFieldColor}
          // color={colors.textFieldTextColor}
          color={"black"}
          onChange={(e) => {
            if (setValue) {
              setValue(e.target.value);
            }
          }}
          disabled={disabled}
        />
      </StandardShadow>
    </div>
  );
};
