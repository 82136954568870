import { useContext, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { BrowserView, MobileView } from "react-device-detect";
import { Center } from "../components/Center";
import { CustomInputWithSideSelector } from "../components/CustomInputWithSideSelector";
import { CustomTextField } from "../components/CustomTextInput";
import { DescriptionText } from "../components/DescriptionText";
import { DynamicImage } from "../components/DynamicImage";
import InfoCard from "../components/InfoCard";
import PayBottomSheet from "../components/PayBottomSheet";
import { PrimaryButton } from "../components/PrimaryButton";
import ProfileCard from "../components/ProfileCard";
import { SizedBox } from "../components/SizedBox";
import { StandardShadow } from "../components/StandardShadow";
import { SubtitleText } from "../components/SubtitleText";
import { TitleText } from "../components/TitleText";
import { WidthContainer } from "../components/WidthContainer";
import { Web3Context } from "../contexts/Web3Context";
import api from "../utils/api";
import { useAppColors } from "../utils/useAppColors";

function TwitterProfileCard({ twitterUserData, horizontal }) {
    const colors = useAppColors()
    return <>
        <Center>
            <StandardShadow borderRadius="20px">
                <InfoCard backgroundColor={"white"} border={"2px solid black"} padding="24px">
                    {horizontal ?
                        <Col>
                            <Row>
                                <Col xs={3}>
                                    <Center>
                                        <DynamicImage borderRadius={"5000px"} height="80px" hasShadow={true} imageUrl={twitterUserData.profile_image_url} />
                                    </Center>

                                </Col>
                                <Col>
                                    <SubtitleText fontSize={"18px"}>
                                        {twitterUserData.name}
                                    </SubtitleText>
                                    <SubtitleText fontSize={"16px"} color={colors.textColor}>
                                        @{twitterUserData.username}
                                    </SubtitleText>
                                </Col>
                            </Row>
                        </Col>
                        :
                        <Col>
                            <Row>
                                <Col>
                                    <Center>
                                        <DynamicImage borderRadius={"5000px"} height="80px" hasShadow={true} imageUrl={twitterUserData.profile_image_url} />
                                    </Center>
                                    <SizedBox height="16px" />
                                    <SubtitleText>
                                        {twitterUserData.name}
                                    </SubtitleText>
                                    <SubtitleText fontSize={"20px"} color={colors.textColor}>
                                        @{twitterUserData.username}
                                    </SubtitleText>
                                    {/* <DescriptionText>
                                    {twitterUserData.description}
                                </DescriptionText> */}
                                </Col>
                            </Row>
                        </Col>}
                </InfoCard>
            </StandardShadow>
        </Center>
    </>
}

function ExistingProfileCard({ u, horizontal }) {
    const colors = useAppColors()
    return <>
        <Center>
            <StandardShadow borderRadius="20px">
                <InfoCard backgroundColor={"white"} border={"2px solid black"} padding="24px">
                    {horizontal ? <Col>
                        <Row>
                            <Col xs={3}>
                                <Center>
                                    <DynamicImage borderRadius={"5000px"} height="80px" hasShadow={true} imageUrl={u.avatarUrl} />
                                </Center>

                            </Col>
                            <Col>
                                <SubtitleText fontSize={"18px"}>
                                    {u.displayName}
                                </SubtitleText>
                                <SubtitleText fontSize={"16px"} color={colors.textColor}>
                                    @{u.username}
                                </SubtitleText>
                            </Col>
                        </Row>
                    </Col> : <Col>
                        <Row>
                            <Col>
                                <Center>
                                    <DynamicImage borderRadius={"5000px"} height="80px" hasShadow={true} imageUrl={u.avatarUrl} />
                                </Center>
                                <SizedBox height="16px" />
                                <SubtitleText>
                                    {u.displayName}
                                </SubtitleText>
                                <SubtitleText fontSize={"20px"} color={colors.textColor}>
                                    @{u.username}
                                </SubtitleText>
                                {/* <DescriptionText>
                                    {u.bio}
                                </DescriptionText> */}
                            </Col>
                        </Row>
                    </Col>}
                </InfoCard>
            </StandardShadow>
        </Center>
    </>
}

export default function Pay() {
    const { log, jwt, showSnackbar, user } = useContext(Web3Context)
    const [isLoading, setIsLoading] = useState(false)
    const [twitterSearch, setTwitterSearch] = useState("")
    const [twitterUserData, setTwitterUserData] = useState(undefined)
    const [message, setMessage] = useState("")
    const [existingUser, setExistingUser] = useState(undefined)
    const [payOpen, setPayOpen] = useState(false)
    const [pendingRule, setPendingRule] = useState(undefined)

    async function createPendingRule() {
        try {
            setIsLoading(true)
            const response = await api.createMessagePendingRule({ message: message, jwt: jwt })
            if (response.error) {
                throw Error(message)
            }

            setPendingRule(response.pendingRule)
            setPayOpen(true)
            return { pendingRule: response.pendingRule }
        } catch (e) {
            showSnackbar({ message: e.message, severity: "error" });
            log({
                eventName: "general error",
                eventParams: {
                    userUid: user ? user._id : undefined,
                    error: e,
                },
            });
            setIsLoading(false);
            return;
        }
    }

    async function getTwitterProfile() {
        try {
            setIsLoading(true)
            setTwitterUserData(undefined)
            setExistingUser(undefined)
            if (!twitterSearch || twitterSearch.length === 0) {
                throw Error("Please input a link")
            }
            const resp = await api.getTwitterProfile({ search: twitterSearch, jwt: jwt })
            console.log("resp: ", resp)
            if (resp.error) {
                throw Error(resp.error)
            }
            setExistingUser(resp.existingUser)
            setTwitterUserData(resp.twitterUser.data)
            console.log("twitteruserdataresp: ", resp.twitterUser.data)
        } catch (e) {
            showSnackbar({ message: e.message, severity: "error" });
            log({
                eventName: "general error",
                eventParams: {
                    userUid: user ? user._id : undefined,
                    error: e,
                },
            });
            setIsLoading(false);
            return;
        }
    }


    return <>
        <WidthContainer topPadding={36}>
            <BrowserView>
                <Col>
                    <Row>
                        <Col>
                            <InfoCard padding="24px">
                                <SubtitleText>
                                    Pay a Twitter Account
                                </SubtitleText>
                                <Row>
                                    <Col>
                                        <DescriptionText>
                                            You can pay a person via their twitter account. Paste a link to their profile or search their username
                                        </DescriptionText>
                                    </Col>
                                </Row>
                                <SizedBox height={"16px"} />
                                {twitterUserData && existingUser && <>
                                    <DescriptionText>
                                        {`${twitterUserData.username} has already linked their account!`}
                                    </DescriptionText>
                                    <SizedBox height={"16px"} />

                                </>}
                                <Row>
                                    {twitterUserData && <Col>
                                        <TwitterProfileCard twitterUserData={twitterUserData} />
                                    </Col>
                                    }
                                    {twitterUserData && existingUser && <Col>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
                                            <StandardShadow borderRadius={"5000px"}>
                                                <div style={{ fontSize: "30px", padding: "8px", borderRadius: "5000px", backgroundColor: "white", border: "2px solid black" }}>
                                                    🔗
                                                </div>
                                            </StandardShadow>
                                        </div></Col>}
                                    {existingUser && <Col>
                                        <ExistingProfileCard u={existingUser} />
                                    </Col>}
                                </Row>
                                <SizedBox height="16px" />
                                <CustomInputWithSideSelector
                                    name="Twitter Handle"
                                    selectorText="Search"
                                    placeholder="Search a username or paste a link"
                                    onSelectorClick={() => {
                                        getTwitterProfile()
                                    }}
                                    value={twitterSearch}
                                    setValue={setTwitterSearch}
                                />

                                <SizedBox height={"24px"} />
                                {twitterUserData && <CustomTextField
                                    name="Message"
                                    placeholder="Type a short message they'll be able to see"
                                    onSelectorClick={() => {
                                        getTwitterProfile()
                                    }}
                                    value={message}
                                    setValue={setMessage}
                                />}
                                <SizedBox height={"8px"} />

                                {twitterUserData && <DescriptionText>If its too long it will get cut off</DescriptionText>}
                                <SizedBox height={"24px"} />
                                {twitterUserData && <PrimaryButton requireUser={true} text={`Pay ${twitterUserData.username}`} onClick={() => {
                                    createPendingRule()
                                }} />}
                                <SizedBox height={"32px"} />

                            </InfoCard>
                        </Col>
                    </Row>
                </Col>
            </BrowserView>
            <MobileView>
                <Col>
                    <Row>
                        <Col>
                            <InfoCard padding="24px">
                                <SubtitleText>
                                    Pay a Twitter Account
                                </SubtitleText>
                                <Row>
                                    <Col>
                                        <DescriptionText>
                                            You can pay a person via their twitter account. Paste a link to their profile or search their username
                                        </DescriptionText>
                                    </Col>
                                </Row>
                                <SizedBox height={"16px"} />
                                {twitterUserData && existingUser && <>
                                    <DescriptionText>
                                        {`${twitterUserData.username} has already linked their account!`}
                                    </DescriptionText>
                                    <SizedBox height={"16px"} />

                                </>}
                                <Row>
                                    <Col>
                                        {twitterUserData && <Row>
                                            <TwitterProfileCard twitterUserData={twitterUserData} horizontal={true} />
                                        </Row>
                                        }
                                        {twitterUserData && existingUser && <Col>
                                            <SizedBox height={"12px"} />
                                            <Row>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
                                                    <StandardShadow borderRadius={"5000px"}>
                                                        <div style={{ fontSize: "20px", padding: "8px", borderRadius: "5000px", backgroundColor: "white", border: "2px solid black", }}>
                                                            🔗
                                                        </div>
                                                    </StandardShadow>
                                                </div></Row>
                                            <SizedBox height={"12px"} />

                                        </Col>}
                                        {existingUser && <Row>
                                            <ExistingProfileCard u={existingUser} horizontal={true} />
                                        </Row>}
                                    </Col>
                                </Row>
                                <SizedBox height="16px" />
                                <CustomInputWithSideSelector
                                    name="Twitter Handle"
                                    selectorText="Search"
                                    placeholder="Search username"
                                    onSelectorClick={() => {
                                        getTwitterProfile()
                                    }}
                                    value={twitterSearch}
                                    setValue={setTwitterSearch}
                                />
                                <SizedBox height={"8px"} />
                                {twitterUserData && <CustomTextField
                                    name="Message"
                                    placeholder="Type a short message they'll be able to see"
                                    onSelectorClick={() => {
                                        getTwitterProfile()
                                    }}
                                    value={message}
                                    setValue={setMessage}
                                />}
                                <SizedBox height={"24px"} />

                                {twitterUserData && <PrimaryButton text={`Pay ${twitterUserData.username}`} onClick={() => { createPendingRule() }} />}
                                <SizedBox height={"32px"} />

                            </InfoCard>
                        </Col>
                    </Row>
                </Col>
            </MobileView>
            <PayBottomSheet open={payOpen} setOpen={setPayOpen} avatarUrl={twitterUserData ? twitterUserData.profile_image_url : undefined} username={twitterUserData ? twitterUserData.username : undefined} serviceId={"twitter"} userId={twitterUserData ? twitterUserData.id : undefined} pendingRule={pendingRule} />
        </WidthContainer>
    </>
}